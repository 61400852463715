import React, { memo, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { devices } from '@common/constants/shared';
import { WebView } from 'react-native-webview';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { dimensionSelector } from '@common/redux/selectors/page';
import { ceil, isEmpty, isNil } from 'lodash';

type Props = {
  appId: string;
  device: any;
  width: number;
  height: number;
  hasBorder?: boolean;
  handleIframeWidth?: (width: number) => void;
};

const IFrameShare = ({
  appId,
  device,
  handleIframeWidth,
  hasBorder = true,
}: Props) => {
  const iframeRef: any = useRef();

  const calcScale = (parent: any, children: any) => {
    if (isEmpty(parent) || isEmpty(children)) return 1;
    const scaleX = parent.width / children.width;
    const scaleY = parent.height / children.height;
    const scale = Math.min(scaleX, scaleY);
    return ceil(scale, 6);
  };

  useEffect(() => {
    if (!isNil(iframeRef)) {
      const currenFrame = iframeRef.current.frameRef;

      currenFrame.addEventListener('load', () => {
        setInterval(() => {
          const oldTitle = document?.title;
          const newTitle = currenFrame.contentWindow
            ? currenFrame?.contentWindow?.document?.title
            : currenFrame?.contentDocument?.title;
          if (oldTitle !== newTitle) {
            document.title = newTitle;
          }
        }, 500);
      });
    }
  }, [iframeRef]);

  const deviceInfo = devices[device];

  const dimension = useSelector(dimensionSelector);

  const wrapperStyleEl = useMemo(() => {
    const height = (dimension.height * 90) / 100;
    const width = height / deviceInfo.ratio;
    if (handleIframeWidth) {
      handleIframeWidth(width);
    }
    return { width, height };
  }, [deviceInfo, dimension]);

  const scalePage = useMemo(() => {
    const { clientWidth, clientHeight } = deviceInfo;
    const { width, height } = wrapperStyleEl;

    const scale = calcScale(
      { width, height },
      { width: clientWidth, height: clientHeight }
    );

    return scale;
  }, [deviceInfo, wrapperStyleEl]);

  let newUri = `/preview/${appId}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has('locale')) {
    const locale = urlSearchParams.get('locale');
    newUri = `/preview/${appId}?locale=${locale}`;
  }

  return (
    <View
      style={{
        width: hasBorder ? wrapperStyleEl.width + 130 : wrapperStyleEl.width,
        height: hasBorder ? wrapperStyleEl.height + 150 : wrapperStyleEl.height,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: hasBorder ? 30 : 0,
        backgroundColor: hasBorder ? '#E9EFF2' : 'transparent',
        borderWidth: hasBorder ? 30 : 0,
        borderColor: '#E9EFF2',
      }}
    >
      <View>
        <WebView
          source={{ uri: newUri }}
          style={{
            width: hasBorder
              ? wrapperStyleEl.width + 100
              : wrapperStyleEl.width,
            height: hasBorder
              ? wrapperStyleEl.height + 120
              : wrapperStyleEl.height,
            borderRadius: hasBorder ? 20 : 0,
            borderWidth: 0,
            borderColor: 'transparent',
          }}
          ref={iframeRef}
          scalesPageToFit={true}
          javaScriptEnabled={true}
          domStorageEnabled={true}
        />
      </View>
    </View>
  );
};

IFrameShare.propTypes = {
  appId: PropTypes.string.isRequired,
  device: PropTypes.string,
};

IFrameShare.defaultProps = {
  width: 812,
  height: 375,
};

export default memo(IFrameShare);
