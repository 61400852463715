import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  selectWrapper: {
    width: 200,
    height: '100vh',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    paddingVertical: 30,
  },
  buttonBackDashboard: {
    fontWeight: '600',
    borderRadius: 8,
    color: 'rgb(255, 255, 255)',
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  child: {
    flex: 1,
    padding: 20,
    paddingRight: 30,
  },
  overflow: {
    overflow: 'scroll',
  },
  space: {
    margin: 20,
  },
  rightChild: {
    flex: 1.2,
    padding: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F5F7',
  },
  floatingBar: {
    width: 56,
    height: 56,
    gap: 10,
    paddingTop: 8,
    paddingRight: 16,
    paddingBottom: 8,
    paddingLeft: 16,
    backgroundColor: '##0175FF',
    elevation: 5,
  },
});

export default styles;
