import { StyleSheet } from 'react-native';
import { ChatComponentProps } from './type';

export const chatComponentStyles = (props: ChatComponentProps) =>
  StyleSheet.create({
    container: {
      width: props.width,
      height: props.height,
      backgroundColor: 'white',
    },
    pcContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: props.height,
    },
  });
