import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface CloseCircleIconProps {
  size?: number;
  color?: string;
}

const CloseCircleIcon: React.FC<CloseCircleIconProps> = ({
  size = 24,
  color = '#1A2737',
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <Path
        d="M8.00016 1.33203C11.6868 1.33203 14.6668 4.31203 14.6668 7.9987C14.6668 11.6854 11.6868 14.6654 8.00016 14.6654C4.3135 14.6654 1.3335 11.6854 1.3335 7.9987C1.3335 4.31203 4.3135 1.33203 8.00016 1.33203ZM10.3935 4.66536L8.00016 7.0587L5.60683 4.66536L4.66683 5.60536L7.06016 7.9987L4.66683 10.392L5.60683 11.332L8.00016 8.9387L10.3935 11.332L11.3335 10.392L8.94016 7.9987L11.3335 5.60536L10.3935 4.66536Z"
        fill={color}
      />
    </Svg>
  );
};

export default CloseCircleIcon;
