import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  FlatList,
  Image,
  ImageStyle,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import axiosInstance from '@common/services/axiosIntance/axiosService';
import createStyle from './style';
import { IStampHistory } from '@common/types';
import { get } from 'lodash';

type StampMaster = {
  id: string | number;
  background_image: { url: string };
  display_name: string;
  is_in_valid: boolean;
  pushed_image: string;
  stamp_id: string;
  createdAt: string;
  updatedAt: string;
  isStamped?: boolean;
  timestamp?: string;
};

type StampHistory = {
  id: string | number;
  stamp_master_id: string;
  user_id: string;
  createdAt: string;
  updatedAt: string;
};

type StampHistoryResponse = {
  stampMasters: StampMaster[];
  histories: StampHistory[];
};

const ShopStampItem = ({
  item,
  index,
  style,
  textStyle,
  imprintStyle,
}: {
  item: StampMaster;
  index: number;
  style: ViewStyle;
  textStyle?: TextStyle;
  imprintStyle?: ImageStyle;
}) => {
  return (
    <View style={style}>
      <TouchableOpacity>
        {item.isStamped ? (
          <Image
            style={imprintStyle}
            resizeMode="contain"
            source={{ uri: item.background_image?.url }}
          />
        ) : (
          <Text style={textStyle}>{index + 1}</Text>
        )}
      </TouchableOpacity>
    </View>
  );
};

const StampRallyItem = ({
  item,
  index,
  style,
  textStyle,
  imprintStyle,
}: {
  item: StampMaster;
  index: number;
  style: ViewStyle;
  textStyle?: TextStyle;
  imprintStyle?: ImageStyle;
}) => {
  return (
    <TouchableOpacity>
      <View style={style}>
        {item.isStamped ? (
          <Image
            style={imprintStyle}
            resizeMode="contain"
            source={{ uri: item.background_image?.url }}
          />
        ) : null}
      </View>
      <Text style={textStyle}>{item.display_name}</Text>
    </TouchableOpacity>
  );
};

const StampHistory = (attrs: IStampHistory) => {
  const [stampMasters, setStampMasters] = useState<StampMaster[]>();
  const pattern = get(
    attrs,
    'dependencies.appInfor.metadata.digishot.pattern',
    'shop-stamp'
  );
  const styles = createStyle(attrs);

  useEffect(() => {
    if (attrs.dependencies?.appInfor?.appId) {
      const fetchStampHistory = async () => {
        const response = (await axiosInstance({
          method: 'GET',
          url: '/digishot/stamp/history',
          params: {
            appId: attrs.dependencies?.appInfor.appId,
            stampType: pattern === 'shop-stamp' ? 'shopStamp' : null,
          },
        })) as { data: StampHistoryResponse };

        const stampResult = [] as StampMaster[];
        if (pattern === 'stamp-rally') {
          response.data?.stampMasters?.forEach((master) => {
            const stamped = response.data.histories.find(
              (history) => history.stamp_master_id === master.id
            );

            if (stamped) {
              stampResult.push({
                ...master,
                isStamped: true,
                timestamp: master?.createdAt,
              });
            } else {
              stampResult.push({
                ...master,
                isStamped: false,
                timestamp: master?.createdAt,
              });
            }
          });
        } else if (pattern === 'shop-stamp') {
          response.data?.histories.forEach((history) => {
            const stamp = response.data.stampMasters.find(
              (master) => master.id === history.stamp_master_id
            );

            if (stamp) {
              stampResult.push({
                ...stamp,
                isStamped: true,
                timestamp: history.createdAt,
              });
            }
          });
        }
        setStampMasters(
          stampResult.sort(
            (a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix()
          )
        );
      };
      fetchStampHistory();
    }
  }, [attrs.dependencies]);

  return (
    <View style={styles.container}>
      {stampMasters && pattern === 'shop-stamp' ? (
        <>
          <FlatList
            horizontal={false}
            numColumns={attrs.numColumns || 5}
            data={[
              ...stampMasters,
              ...Array.from(
                {
                  length:
                    attrs.maxStampCount >= stampMasters.length
                      ? attrs.maxStampCount - stampMasters.length
                      : 0,
                },
                (_, index) => ({
                  id: index,
                  background_image: { url: '' },
                  is_in_valid: false,
                  pushed_image: '',
                  display_name: '',
                  stamp_id: '',
                  createdAt: '',
                  updatedAt: '',
                  isStamped: false,
                })
              ),
            ]}
            columnWrapperStyle={
              attrs.numColumns > 1
                ? {
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }
                : undefined
            }
            renderItem={({ item, index }) => (
              <ShopStampItem
                item={item}
                index={index}
                style={styles.shopStampItem}
                textStyle={styles.shopStampText}
                imprintStyle={styles.shopStampImprint}
              />
            )}
          />
        </>
      ) : null}

      {stampMasters && pattern === 'stamp-rally' ? (
        <FlatList
          horizontal={false}
          numColumns={attrs.numColumns || 3}
          data={stampMasters}
          columnWrapperStyle={
            attrs.numColumns > 1
              ? {
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }
              : undefined
          }
          ItemSeparatorComponent={() => <View style={{ height: attrs.gap }} />}
          renderItem={({ item, index }) => (
            <StampRallyItem
              item={item}
              index={index}
              style={styles.stampRallyItem}
              textStyle={styles.stampRallyText}
              imprintStyle={styles.stampRallyImprint}
            />
          )}
        />
      ) : null}
    </View>
  );
};

export default StampHistory;
