import { first, get, isEmpty } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useRef } from 'react';
import { FlatList, Text, TouchableOpacity, View } from 'react-native';
import { Checkbox, RadioButton } from 'react-native-paper';

import EmptyList from '../EmptyList';
import { checkFont } from '../func';
import Loading from '../Loading';
import { getValueBinding } from '../shared';
import { useSelectList, SELECT_LIST } from './hook';

const SelectList: React.FC<any> = (props) => {
  const ref = useRef(false);
  const {
    dataBinding = [],
    width,
    height,
    attributes = {},
    setDefaultValue,
    initializeList,
    id,
  } = props;

  const {
    opacity,
    // TODO remove support for backgroundColor once all apps have moved to new design - renewal 2025
    backgroundColor,
    color,
    borderRadius,
    fontSize,
    fontFamily,
    subTitle,
    selectDatabase,
    showHeader,
    useMultipleSelection,
  } = attributes;

  const { isItemSelected, handlePress, isUseDatabase, listData } =
    useSelectList(props);

  const listOptions = getValueBinding('', first(dataBinding), props);
  const title =
    get(listOptions, 'title.text') || get(first(dataBinding), 'title.text');
  const subTitleText =
    get(listOptions, 'subTitle.text') ||
    get(first(dataBinding), 'subTitle.text');

  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;
  const tableId = get(props, 'attributes.items.source.tableId');
  const isCanvas = !target;

  useEffect(() => {
    const path = `${selectDatabase ? 'defaultData' : 'defaultSeparate'}`;
    const defaultVal = get(listOptions, path);
    const itemInList = isUseDatabase
      ? listData.find((e) => e.id === defaultVal)
      : listData.find((e) => e.value === defaultVal);
    if (!isEmpty(itemInList) && !ref.current) {
      setDefaultValue &&
        setDefaultValue({
          componentName: SELECT_LIST,
          selectedItems: [itemInList],
          useMultipleSelection,
          isUseDatabase,
        });
      ref.current = true;
    }
  }, [listOptions, ref.current, id]);

  if (!isCanvas && !tableId && !props?.loading && selectDatabase)
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          zIndex: props.zIndex,
        }}
      >
        <EmptyList attributes={props} />
      </View>
    );

  if (initializeList) {
    return <Loading />;
  }

  return (
    <View
      style={{
        width,
        height,
        opacity,
        padding: 16,
      }}
    >
      {showHeader !== false ? (
        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: 4,
            backgroundColor:
              attributes?.header?.backgroundColor || backgroundColor,
            paddingHorizontal: 16,
            paddingVertical: 8,
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              paddingHorizontal: 25,
              fontFamily: checkFont(
                attributes.title?.fontFamily || fontFamily,
                undefined,
                target
              ),
              fontSize: attributes.title?.fontSize || fontSize,
              fontWeight: 'bold',
              color: attributes.title?.color,
            }}
          >
            {title}
          </Text>
          {subTitle.enabled ? (
            <Text
              style={{
                fontFamily: checkFont(
                  attributes.subTitle?.fontFamily || fontFamily,
                  undefined,
                  target
                ),
                fontSize: attributes.subTitle?.fontSize || 13,
                color: attributes.subTitle?.color,
                textAlign: 'center',
              }}
            >
              {subTitleText}
            </Text>
          ) : null}
        </View>
      ) : null}

      <FlatList
        showsVerticalScrollIndicator={false}
        nestedScrollEnabled={true}
        maxToRenderPerBatch={4}
        scrollEnabled={true}
        data={Array.isArray(listData) ? listData : []}
        renderItem={({ item }) => {
          const isActive = isItemSelected(item);

          return (
            <TouchableOpacity
              style={{
                paddingLeft: 8,
                paddingRight: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                marginTop: 8,
                backgroundColor:
                  attributes.option?.backgroundColor || backgroundColor,
                borderRadius: 4,
              }}
              onPress={() => handlePress(item)}
            >
              {useMultipleSelection ? (
                <Checkbox
                  status={isActive ? 'checked' : 'unchecked'}
                  color={color}
                />
              ) : (
                <RadioButton
                  value={'SelectedItem'}
                  status={isActive ? 'checked' : 'unchecked'}
                  color={color}
                  onPress={() => handlePress(item)}
                />
              )}
              <Text
                style={{
                  fontFamily: checkFont(
                    attributes.option?.fontFamily || fontFamily,
                    undefined,
                    target
                  ),
                  fontSize: attributes.option?.fontSize || 13,
                  color: attributes.option?.color,
                }}
                ellipsizeMode="tail"
              >
                {item.value}
              </Text>
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};

export default SelectList;
