import React from 'react';
import { IconButton } from 'react-native-paper';
import { View, Text, Linking } from 'react-native';

import createStyles from './style';
import { IMessage } from '@common/hooks/useGptConversation';
import { useGptConversationContext } from '../context';

const Message = ({
  message,
  senderType,
  hideAvatar,
}: {
  message: IMessage;
  senderType: IMessage['senderType'];
  hideAvatar?: boolean;
}) => {
  const { props, getSenderName, downloadFile } = useGptConversationContext();
  const styles = createStyles({ ...props, senderType });
  const { attributes } = props;

  return (
    <View
      style={[
        styles.container,
        hideAvatar ? { marginBottom: 16 } : { marginBottom: 8 },
      ]}
    >
      {hideAvatar ? (
        <View style={styles.avatarBox}></View>
      ) : (
        <IconButton
          icon={
            senderType === 'user'
              ? attributes.user?.icon
              : attributes.chatbot?.icon
          }
          color={
            senderType === 'user'
              ? attributes.user?.iconColor
              : attributes.chatbot?.iconColor
          }
          size={20}
          style={[styles.chatIcon, styles.avatarBox]}
        />
      )}
      <View
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          marginHorizontal: 8,
        }}
      >
        <Text
          style={[styles.senderName, hideAvatar ? { display: 'none' } : {}]}
        >
          {getSenderName(message.senderType)}
        </Text>
        <View style={styles.textWrapper}>
          <Text
            key={message.id}
            style={[
              styles.text,
              message.messageType === 'url' ? styles.url : {},
              message.messageType === 'file' ? styles.file : {},
            ]}
            onPress={() => {
              if (message.messageType === 'url' && message.attachments.url) {
                Linking.openURL(message.attachments.url);
                return;
              }
              if (
                message.messageType === 'file' &&
                message.attachments.fileId
              ) {
                downloadFile(message.attachments.fileId);
                return;
              }
            }}
          >
            {message.message}
          </Text>
        </View>
      </View>
      <View style={{ width: 36 }}></View>
    </View>
  );
};

export default Message;
