import { API_BASE_URL, API_ENDPOINTS } from '@common/constants/apiEndpoint';
import BaseComponent from '@nocode/types/base.type';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { getValueBinding } from '../shared';
import EventSource from 'react-native-sse';
import {
  DEFAULT_OPEN_AI_AI_URL,
  GptConversationServiceTypeEnum,
} from '@common/constants/gpt-conversation.constant';
import { TextAlign } from '@nocode/types/common.type';
import {
  GptConversationActionType,
  IMessage,
} from '@common/hooks/useGptConversation';
import { uniqBy } from 'lodash';
import { FileType } from '@common/hooks/useUploadFile';

const INIT_MESSAGE_ID = 'init';
type GptConfiguration = {
  apiUrl?: string;
  openAiModel?: string;
  serviceType: keyof typeof GptConversationServiceTypeEnum;
  apiKey: string;
  assistantId?: string;
  threadId?: string;
};
type TextStyle = {
  fontSize: number;
  color: string;
  fontFamily: string;
  backgroundColor: string;
  lineHeight: number;
};
export type IGptConversation = BaseComponent & {
  attributes: {
    backgroundColor: string;
    fontFamily: string;
    fontSize: number;
    fontWeight: string;
    lineHeight: number;
    opacity: number;
    textAlignment: TextAlign;
    borderRadius: number;
    sendButton: {
      icon: string;
      iconEnableOutline: boolean;
    } & TextStyle;
    // TODO deprecate
    advanceSetting: {
      aiName: string | any;
      username: string | any;
      aiColor: string;
      userColor: string;
    };
    chatbot: {
      color: string;
      backgroundColor: string;
      icon: string;
      iconColor: string;
      iconBackgroundColor: string;
    };
    user: {
      color: string;
      backgroundColor: string;
      icon: string;
      iconColor: string;
      iconBackgroundColor: string;
    };
  };
  data: Record<string, any>;
  appId: string;
} & { gptActions?: GptConversationActionType };
export type GptConversationBindingValue = GptConfiguration & {
  placeholder: string;
  initPrompt: string;
  supportUploadFile: boolean;
  'advanceSetting.aiName': string;
  'advanceSetting.username': string;
  'sendButton.text': string;
  initMessage: string;
};
type MessageQuery = { role: 'user' | 'assistant' | 'system'; content: string };
type FileResponse = { filename: string; fileId: string };
type CreateChatMessageCallBacks = {
  onResource?: (_: { url: string }) => void;
  onAttachment?: (_: {
    senderType: 'ai' | 'user';
    answer?: string;
    fileId: string;
  }) => void;
  onAnswer?: (formattedAnswer: string) => void;
  onDone?: (data: {
    answer: string;
    error: string;
    files: FileResponse[];
  }) => void;
};
type CreateChatMessageRequest = {
  query?: string;
  messages?: MessageQuery[];
  metadata?: {
    fileUrl?: string;
  };
  appId: string;
  username: string;
  conversationId?: string | null;
  threadId?: string | null;
};

type BaseResponseMessage = {
  senderType?: 'ai' | 'user';
  answer?: string;
  isEnd?: boolean;
  threadId?: string;
  error?: string;
  fileId?: string;
  conversationId?: string | null;
  resources?: { url: string }[];
};
type ResponseMessageText = BaseResponseMessage & {};
type ResponseMessageFile = BaseResponseMessage & {
  fileId: string;
};
type ResponseMessage = ResponseMessageText | ResponseMessageFile;

export const useGptConversation = (props: IGptConversation) => {
  const [historyInitialized, setHistoryInitialized] = useState(false);
  const [getHistoryLoading, setGetHistoryLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState<FileType | null>(null);
  const { data, appId, changeInput } = props;
  const actions = props.gptActions;
  const bindingValue = getValueBinding(
    props.id,
    data,
    props
  ) as GptConversationBindingValue;

  const username =
    get(bindingValue, 'advanceSetting.username')?.toString() ||
    get(bindingValue, 'user.name') ||
    'あなた';
  const aiName =
    get(bindingValue, 'advanceSetting.aiName')?.toString() ||
    get(bindingValue, 'chatbot.name') ||
    'AIチャットボット名';
  const submitButtonText =
    get(bindingValue, 'sendButton.text')?.toString() || '質問する';
  const supportUploadFile = !!get(bindingValue, 'supportUploadFile', false);
  const placeholderText = get(bindingValue, 'placeholder')?.toString();
  const getSenderName = (senderType: IMessage['senderType']) => {
    switch (senderType) {
      case 'user':
        return `${username}`;
      case 'ai':
      case 'error':
        return `${aiName}`;
    }
  };
  const initPrompt = get(bindingValue, 'initPrompt', '')?.toString();
  const initMessage = get(bindingValue, 'initMessage', '')?.toString();
  const serviceType = get(
    bindingValue,
    'serviceType',
    GptConversationServiceTypeEnum.openAi
  ) as keyof typeof GptConversationServiceTypeEnum;
  const initThreadId = get(bindingValue, 'threadId', null);
  const assistantId = get(bindingValue, 'assistantId', '');
  const apiKey = get(bindingValue, 'apiKey', '');
  const gptConfig: GptConfiguration = {
    apiKey,
    apiUrl:
      serviceType === GptConversationServiceTypeEnum.openAi
        ? DEFAULT_OPEN_AI_AI_URL
        : get(bindingValue, 'apiUrl', DEFAULT_OPEN_AI_AI_URL),
    openAiModel: get(bindingValue, 'openAiModel'),
    serviceType,
    assistantId,
  };
  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;

  const [query, setQuery] = useState('');
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [aiRespondingText, setAiRespondingText] = useState<string | null>(null);
  const [aiResponding, setAiResponding] = useState(false);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [threadId, setThreadId] = useState<string | null>(initThreadId);
  const [initializing, setInitializing] = useState(true);
  const [messagesQuery, setMessagesQuery] = useState<MessageQuery[]>([]);

  const messageRef = useRef<any>();

  const scrollToBottom = () => {
    messageRef?.current?.scrollToEnd({
      animated: true,
    });
  };

  const addMessage = (
    message: Pick<
      IMessage,
      'senderType' | 'message' | 'messageType' | 'attachments'
    >
  ) => {
    setMessages((old) => [
      ...old,
      {
        id: Date.now().toString(),
        createdAt: Math.round(Date.now() / 1000),
        ...message,
      } as IMessage,
    ]);
  };
  const addInitMessage = (message: string) => {
    setMessages([
      {
        message,
        id: INIT_MESSAGE_ID,
        messageType: 'text',
        senderType: 'ai',
        createdAt: 0,
      },
    ]);
  };

  const addMessageQuery = (data: MessageQuery) => {
    if (gptConfig?.serviceType === GptConversationServiceTypeEnum.openAi) {
      messagesQuery.push(data);
      setMessagesQuery(messagesQuery);
    }
    return messagesQuery;
  };
  const handleChunkData = (
    chunk: string,
    callbacks?: CreateChatMessageCallBacks
  ) => {
    let aiRespondingTempt = '';
    let errorRespondingTempt = '';
    try {
      const jsonData = JSON.parse(chunk) as ResponseMessage;
      if (jsonData.answer && !jsonData.fileId) {
        const formattedAnswer = jsonData.answer.replace(/<br>/g, '\n');
        callbacks?.onAnswer?.(formattedAnswer);
        aiRespondingTempt = formattedAnswer;
      }
      if (jsonData.fileId) {
        callbacks?.onAttachment?.({
          fileId: jsonData.fileId,
          answer: jsonData.answer || jsonData.fileId,
          senderType: jsonData.senderType || 'ai',
        });
      }
      if (jsonData.error) {
        const formattedError = jsonData.error.replace(/<br>/g, '\n');
        errorRespondingTempt = formattedError;
      }
      if (jsonData.conversationId) {
        setConversationId(jsonData.conversationId);
      }
      if (jsonData.resources && jsonData.resources.length > 0) {
        jsonData.resources.forEach((resource: { url: string }) => {
          callbacks?.onResource?.({ url: resource.url || '' });
        });
      }
      return { aiRespondingTempt, errorRespondingTempt, jsonData };
    } catch (e) {
      console.error('JSON の解析エラー:', e);
    }
    return null;
  };

  const handleAiResponseMobile = async (
    body: CreateChatMessageRequest,
    callbacks?: CreateChatMessageCallBacks
  ) => {
    let aiRespondingTempt = '';
    let errorRespondingTempt = '';
    const files: FileResponse[] = [];
    setAiResponding(true);
    const updatedGptConfig = { ...gptConfig };
    if (
      gptConfig.serviceType === GptConversationServiceTypeEnum.openAiAssistant
    ) {
      delete updatedGptConfig.apiUrl;
      delete updatedGptConfig.openAiModel;
    }
    const es = new EventSource(
      `${API_BASE_URL}/${API_ENDPOINTS.GPT_CONVERSATION_CHAT_MESSAGE}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        debug: true,
        body: JSON.stringify({
          ...body,
          gptConfig,
        }),
        timeoutBeforeConnection: 100,
        pollingInterval: 0, // Time (ms) between re-connections. If set to 0, re-connections will be disabled. Default: 5000
      }
    );
    const onDone = () => {
      callbacks?.onDone?.({
        answer: aiRespondingTempt,
        error: errorRespondingTempt,
        files,
      });
      setAiResponding(false);
      es.removeAllEventListeners();
      es.close();
    };
    es.addEventListener('open', (event) => {
      // console.log('===open event', event);
    });

    es.addEventListener('message', (event) => {
      const data = event.data;
      if (!data) {
        return;
      }
      const chunkResponse = handleChunkData(data, {
        ...callbacks,
        onAttachment: (attachment) => {
          if (attachment.senderType === 'ai') {
            files.push({
              filename: attachment.answer || '',
              fileId: attachment.fileId,
            });
          } else {
            callbacks?.onAttachment?.(attachment);
          }
        },
      });
      if (chunkResponse) {
        aiRespondingTempt += chunkResponse.aiRespondingTempt;
        errorRespondingTempt += chunkResponse.errorRespondingTempt;
        if (chunkResponse.jsonData.isEnd) {
          if (chunkResponse.jsonData.threadId) {
            setThreadId(chunkResponse.jsonData.threadId);
            changeInput(chunkResponse.jsonData.threadId);
          }
          onDone();
        }
        return;
      }
      onDone();
    });

    es.addEventListener('error', (event) => {
      if (event.type === 'error' || event.type === 'exception') {
        errorRespondingTempt += event.message;
      }
      onDone();
    });

    es.addEventListener('close', (event) => {
      console.log('===Close SSE connection.');
      onDone();
    });
  };

  const getAIResponse = async (callbacks?: CreateChatMessageCallBacks) => {
    try {
      const messages = addMessageQuery({
        content: query,
        role: 'user',
      });
      if (!messages?.length && !query) {
        return;
      }
      const body: CreateChatMessageRequest = {
        appId,
        query,
        username,
        conversationId,
        messages,
        threadId,
        ...(fileSelected
          ? {
              metadata: {
                fileUrl: fileSelected?.url,
              },
            }
          : {}),
      };
      handleAiResponseMobile(body, callbacks);
      return;
    } catch (error) {
      setAiResponding(false);
      throw error;
    }
  };

  const onSubmit = async () => {
    // ユーザーの質問を表示
    addMessage({
      senderType: 'user',
      message: query,
      messageType: 'text',
    });
    // 入力フィールドをクリア
    setQuery('');
    setTimeout(() => {
      scrollToBottom();
    }, 300);
    try {
      await getAIResponse({
        onDone: ({ answer, error, files }) => {
          setAiRespondingText(null);
          if (error) {
            addMessage({
              senderType: 'error',
              message: error,
              messageType: 'text',
            });
            return;
          }
          addMessage({
            senderType: 'ai',
            message: answer,
            messageType: 'text',
          });
          addMessageQuery({
            content: answer,
            role: 'assistant',
          });
          // ai file response
          if (files) {
            files.forEach((file) => {
              addMessage({
                senderType: 'ai',
                message: file.filename,
                messageType: 'file',
                attachments: { fileId: file.fileId },
              });
            });
          }
          setFileSelected(null);
        },
        onResource: ({ url }) => {
          addMessage({
            senderType: 'ai',
            message: url,
            messageType: 'url',
            attachments: { url },
          });
        },
        onAttachment: ({ senderType, answer, fileId }) => {
          addMessage({
            senderType,
            message: answer || fileId,
            messageType: 'file',
            attachments: { fileId },
          });
        },
        onAnswer: (answer) => {
          setAiRespondingText((old) => `${old || ''}${answer}`);
          // スクロールを下に
          scrollToBottom();
        },
      });
    } catch (error: any) {
      console.error('===error', error);
      addMessage({
        senderType: 'error',
        message: error.message || '',
        messageType: 'text',
      });
      // スクロールを下に
      scrollToBottom();
    }
  };

  const handleInit = async () => {
    if (
      initPrompt &&
      gptConfig?.serviceType === GptConversationServiceTypeEnum.openAi
    ) {
      addMessageQuery({
        content: initPrompt,
        role: 'system',
      });
    }
  };

  /**
   * get message history for openAiAssistant if initThreadId is existed
   */
  const getMessageHistories = async () => {
    if (!initThreadId || !gptConfig.apiKey || !gptConfig.assistantId) {
      return;
    }
    if (serviceType !== GptConversationServiceTypeEnum.openAiAssistant) {
      return;
    }
    if (actions?.getMessageHistory) {
      setGetHistoryLoading(true);
      const messageHistory = await actions?.getMessageHistory({
        apiKey: gptConfig.apiKey,
        assistantId: gptConfig.assistantId,
        threadId: initThreadId,
      });
      setGetHistoryLoading(false);
      setMessages((old) => {
        return uniqBy([...old, ...messageHistory], (e) => e.id);
      });
    }
    setHistoryInitialized(true);
    scrollToBottom();
  };

  const downloadFile = async (fileId: string) => {
    await actions?.onDownloadFile({
      fileId,
      apiKey: gptConfig.apiKey,
    });
  };

  useEffect(() => {
    if (!isCanvas) {
      handleInit().finally(() => {
        setInitializing(false);
      });
    }
  }, []);

  useEffect(() => {
    if (!isCanvas && initThreadId && !historyInitialized) {
      setThreadId(initThreadId);
      changeInput(initThreadId);
      getMessageHistories();
    }
  }, [assistantId, initThreadId, historyInitialized, apiKey]);

  useEffect(() => {
    if (initMessage) {
      addInitMessage(initMessage);
    }
  }, [initMessage]);

  return {
    ...props,
    username,
    aiName,
    submitButtonText,
    placeholderText,
    getSenderName,
    serviceType,
    bindingValue,
    onSubmit,
    setQuery,
    query,
    messages,
    aiRespondingText,
    aiResponding,
    messageRef,
    initializing,
    fileSelected,
    setFileSelected,
    downloadFile,
    getHistoryLoading,
    supportUploadFile,
    isCanvas,
  };
};

export type UseGptConversation = ReturnType<typeof useGptConversation>;
