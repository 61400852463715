export default {
  name: 'StackCarousel',
  displayName: {
    en: 'Stack Carousel',
    ja: 'スタックカルーセル',
  },
  defaultWidth: 375,
  defaultHeight: 667,
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
    },
    {
      name: 'sortList',
      displayName: { en: 'Sorting', ja: '並び替え' },
      type: 'sort',
    },
    {
      name: 'maximumItems',
      displayName: { en: 'Maximum number of items', ja: '最大アイテム数' },
      type: 'maximumItems',
    },
    {
      name: 'buttonColor',
      displayName: {
        en: 'Button Color',
        ja: 'ボタンの色',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'buttonIconAndTextColor',
      displayName: {
        en: 'Button Icon & Text Color',
        ja: 'アイコンの色',
      },
      type: 'color',
      default: '@contrast:primaryColor',
    },
    {
      name: 'titleColor',
      displayName: {
        en: 'Title Color',
        ja: 'タイトルの色',
      },
      type: 'color',
      default: '#000000',
    },
    {
      name: 'descriptionColor',
      displayName: {
        en: 'Description Color',
        ja: '説明の色',
      },
      type: 'color',
      default: '#000000',
    },
  ],
  childComponents: [
    {
      name: 'title',
      displayName: { en: 'Title', ja: 'タイトル' },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: { en: 'Title', ja: 'タイトル' },
          type: 'text',
          default: 'Title',
        },
      ],
    },
    {
      name: 'description',
      displayName: { en: 'Description', ja: '説明' },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'text',
          displayName: { en: 'Description', ja: '説明' },
          type: 'text',
          default: 'Description',
        },
      ],
    },
    {
      name: 'images',
      displayName: { en: 'Images', ja: '画像' },
      role: 'listItem',
      reference: 'items',
      props: [
        {
          name: 'image1',
          displayName: { en: 'Image 1', ja: '画像1' },
          type: 'image',
          imageResize: 'cover',
        },
        {
          name: 'image2',
          displayName: { en: 'Image 2', ja: '画像2' },
          type: 'image',
          imageResize: 'cover',
        },
        {
          name: 'image3',
          displayName: { en: 'Image 3', ja: '画像3' },
          type: 'image',
        },
        {
          name: 'image4',
          displayName: { en: 'Image 4', ja: '画像4' },
          type: 'image',
          imageResize: 'cover',
        },
        {
          name: 'image5',
          displayName: { en: 'Image 5', ja: '画像5' },
          type: 'image',
          imageResize: 'cover',
        },
      ],
    },
    {
      name: 'autoAnimateCropImage',
      displayName: {
        en: 'Auto Animate Crop Images',
        ja: '画像アニメーションの有効化',
      },
      role: 'listItem',
      reference: 'items',
      props: [{ name: 'enabled', type: 'boolean', default: false }],
    },
    {
      name: 'autoScrollMain',
      displayName: {
        en: 'Auto Scroll Main Slider',
        ja: '縦スライダーの自動スクロール',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        { name: 'enabled', type: 'boolean', default: false },
        {
          name: 'autoScrollSpeed',
          displayName: {
            en: 'Autoscroll Speed (seconds)',
            ja: 'オートスクロール速度（秒）',
          },
          type: 'number',
          default: 4,
          control: { type: 'slider', max: 20, min: 1 },
        },
      ],
    },
    {
      name: 'autoScrollInner',
      displayName: {
        en: 'Auto Scroll Inner Slider',
        ja: '横スライダーの自動スクロール',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        { name: 'enabled', type: 'boolean', default: true },
        {
          name: 'autoScrollSpeed',
          displayName: {
            en: 'Autoscroll Speed (seconds)',
            ja: 'オートスクロール速度（秒）',
          },
          type: 'number',
          default: 4,
          control: { type: 'slider', max: 20, min: 1 },
        },
      ],
    },
    {
      name: 'button',
      width: 155,
      height: 44,
      displayName: { en: 'Button', ja: 'ボタン' },
      role: 'listItem',
      reference: 'items',
      props: [
        { name: 'enabled', type: 'boolean', default: true },
        {
          name: 'text',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'text',
          default: 'Click',
        },
        {
          name: 'type',
          displayName: { en: 'Type', ja: 'タイプ' },
          type: 'text',
          default: 'contained',
          control: {
            type: 'menu',
            options: [
              { label: 'Text', value: 'text' },
              { label: 'Outlined', value: 'outlined' },
              { label: 'Contained', value: 'contained' },
            ],
          },
        },
        {
          name: 'onPress',
          type: 'action',
          displayName: { en: 'Button', ja: 'ボタン' },
        },
        {
          name: 'icon',
          displayName: { en: 'Icon', ja: 'アイコン' },
          type: 'icon',
          default: 'plus',
        },
      ],
    },
    {
      name: 'footer',
      displayName: {
        en: 'Footer',
        ja: 'フッター',
      },
      role: 'listItem',
      reference: 'items',
      props: [
        { name: 'enabled', type: 'boolean', default: true },
      ],
    },
  ],
};
