import { ActionStatus } from '@common/constants/shared';
import { setResetPassword } from '@common/redux/slice/action';
import { loginSuccess } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { MetadataAction } from '@common/types/action';
import { get } from 'lodash';
import { ActionResponse } from '../excuteAction';
import { registerFCMUser, unregisterFCMUser } from './FCMregister';
import { appInfoSelector } from '@common/redux/selectors/app';

export const signIn: (
  action: Record<string, any>,
  metadata: MetadataAction,
  ignoreError?: boolean
) => Promise<ActionResponse> = async (
  action: Record<string, any>,
  metadata: MetadataAction,
  ignoreError = false
) => {
  const dispatch = store.dispatch;
  const { messages } = metadata;

  if (action.status === ActionStatus.ERROR) {
    if (ignoreError) {
      console.log('===Signin error', get(action, 'error'));
      return {
        status: ActionStatus.SUCCESS,
      };
    }
    const errorMessage = get(action, 'error.message');
    return {
      status: ActionStatus.FAILED,
      message: messages[errorMessage] || errorMessage,
    };
  }
  const response = get(action, 'response.userPreview');
  await unregisterFCMUser(); //remove old token
  const appInfo = appInfoSelector(store.getState())!;
  if (appInfo) {
    await registerFCMUser(response, appInfo);
  }
  dispatch(loginSuccess({ user: response }));
  if (response?.password_expired) {
    dispatch(setResetPassword({ state: true, animation: 'fadeInUpBig' }));
  }

  return {
    status: ActionStatus.SUCCESS,
    message: messages['auth_loginSuccess'],
    accessToken: action?.response?.accessToken,
    profile: response,
  };
};
