import React, { Fragment, useState } from 'react';
import { View, Text, Image } from 'react-native';
import Loading from '@common/components/Loading';
import { IAppInfo } from '@common/types/element';
import InfoDetails from './InfoDetails';
import Embed from './Embed';
import { ChatSettings } from '@common/screens/ShareScreenModal';

type Props = {
  appId: string;
  data?: IAppInfo;
  isLoading: boolean;
  currentStep: number;
  onNext: () => void;
  onBack: () => void;
  chatSettings: ChatSettings;
  updateChatSetting: (key: keyof ChatSettings, value: any) => void;
  locale: string;
};

const RenderShareAppDetail = ({
  data,
  isLoading,
  currentStep,
  onNext,
  onBack,
  updateChatSetting,
  chatSettings,
  locale,
}: Props) => {
  const renderScreen = () => {
    switch (currentStep) {
      case 1:
        return (
          <InfoDetails
            onClose={function (): void {
              throw new Error('Function not implemented.');
            }}
            appData={data}
            locale={locale}
            onNext={onNext}
          ></InfoDetails>
        );
      case 2:
        return (
          <Embed
            appData={data}
            onNext={onNext}
            onBack={onBack}
            chatSettings={chatSettings}
            updateChatSetting={updateChatSetting}
            locale={locale}
          ></Embed>
        );
      default:
        return (
          <InfoDetails
            onClose={function (): void {
              throw new Error('Function not implemented.');
            }}
            appData={data}
            onNext={onNext}
          ></InfoDetails>
        );
    }
  };

  return (
    <Fragment>{isLoading ? <Loading size="large" /> : renderScreen()}</Fragment>
  );
};

export default RenderShareAppDetail;
