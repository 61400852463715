import { StyleSheet, Dimensions } from 'react-native';

const createStyles = (attributes: any) => {
  const {
    width,
    height,
    opacity,
    borderColor,
    borderStyle,
    borderWidth,
    borderRadius,
  } = attributes;

  return StyleSheet.create({
    background: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: Dimensions.get('window').height,
    },
    fullWidth: {
      width: '100%',
    },
    content: {
      position: 'relative',
      zIndex: 1,
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
      color: 'black',
      width: '100%',
    },
    fixedBox: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },
    positionedBottom: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      zIndex: 1000,
      width: '100%',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      padding: 20,
    },
    containerEmpty: {
      width: '100%',
      backgroundColor: '#f2f2f2',
      height: '100%',
      flex: 1,
    },
    flexContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    flexItem: {
      flex: 1,
      marginHorizontal: 10,
      marginBottom: 20,
      marginTop: 20,
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      opacity,
      borderColor,
      ...(borderStyle !== 'none' ? { borderStyle: borderStyle } : undefined),
      borderWidth,
      borderRadius,
      width,
    },
    dots: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignContent: 'center',
      height: 20,
      maxWidth: width,
    },
    insideDots: {
      position: 'absolute',
      bottom: 0,
    },
    dot: {
      width: 10,
      height: 10,
      backgroundColor: '#595959',
      marginHorizontal: 4,
      borderRadius: 20,
    },
    arrow: {
      display: 'flex',
      justifyContent: 'center',
      marginHorizontal: 8,
      top: 0,
      borderRadius: 50,
      backgroundColor: '#bbb',
    },
    arrowWrapper: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
    },
    container: {
      alignItems: 'center',
      flex: 1,
      backgroundColor: '#000000',
    },
    keyImage: {
      justifyContent: 'center',
      flex: 1,
      paddingRight: 8,
    },
    containerImage: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'black',
    },
    animatedContainer: {
      width: width + width,
      height: '100%',
    },
    imageBackground: {
      flex: 1,
      width: undefined,
      height: undefined,
    },
  });
};

export default createStyles;
