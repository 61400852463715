import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import moment from 'moment';
import React, { Fragment } from 'react';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { getDateTimeRelative } from '../../../utils/datetime';
import { ONE_DAY } from '../../shared';
import { useChatComponentContext } from '../context';
import {
  getNumberOverflow,
  isNumberOfUserDisplayed,
  useChatComponent,
} from '../hook';
import { SpHeader } from './SpHeader';

const GroupItem = ({
  groupMessage,
  unreadCount,
  onPress,
}: {
  groupMessage: ChatComponentType.GroupMessageTypeWithMessageJson;
  unreadCount?: number;
  onPress: VoidFunction;
}) => {
  const { groupName, numberOfUsers, groupImage, latestMessage } =
    groupMessage || {};
  const { createdAt, message } = latestMessage || {};
  const unreadCountStr = unreadCount ? getNumberOverflow(unreadCount) : null;
  const title = `${groupName || ''}`;
  const imageUrl = groupImage?.url || '';
  const timestamp = createdAt
    ? getDateTimeRelative(
        {
          offset: moment(createdAt).diff(moment(), 'milliseconds') / ONE_DAY,
        },
        'ja'
      )
    : '';
  const numberOfUsersStr = getNumberOverflow(numberOfUsers);
  return (
    <TouchableOpacity style={styles.card} onPress={onPress}>
      <Image style={styles.avatar} source={{ uri: imageUrl }} />
      <View style={styles.content}>
        <View style={styles.commentHeader}>
          <Text style={styles.username} numberOfLines={1}>
            {title}
          </Text>
          {isNumberOfUserDisplayed({ numberOfUsers }) ? (
            <Text style={styles.username}>{`(${numberOfUsersStr})`}</Text>
          ) : (
            <Fragment />
          )}
          <View style={styles.timeContainer}>
            <Text style={styles.time}>{timestamp}</Text>
          </View>
        </View>
        <View style={styles.messageContainer}>
          <Text style={styles.message} numberOfLines={1}>
            {message?.text || ''}
          </Text>
          {unreadCountStr ? (
            <View style={styles.unReadContainer}>
              <Text style={styles.unReadText}>{unreadCountStr}</Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const GroupMessage = () => {
  const { groupMessages, unreadMessageStatus, props } =
    useChatComponentContext();
  const { onSelectGroup } = useChatComponent(props);
  return (
    <View
      style={{
        borderRadius: 4,
        backgroundColor: 'white',
        width: '100%',
      }}
      nativeID="GroupMessage"
    >
      <SpHeader text={'チャット一覧'} />
      <ScrollView>
        {groupMessages.map((message, index) => (
          <GroupItem
            key={index}
            groupMessage={message}
            onPress={() => {
              onSelectGroup?.(message.id);
            }}
            unreadCount={unreadMessageStatus[message.id]}
          />
        ))}
      </ScrollView>
    </View>
  );
};
const styles = StyleSheet.create({
  card: {
    flexDirection: 'row',
    padding: 16,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#E9EFF2',
    alignItems: 'center',
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#F2F5F7',
  },
  content: {
    flex: 1,
    marginLeft: 16,
  },
  commentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  username: {
    fontSize: 13,
    fontWeight: '700',
    color: '#1A2737',
    marginRight: 4,
  },
  timeContainer: {
    flex: 1,
    textAlign: 'right',
    minWidth: 68,
  },
  time: {
    fontSize: 11,
    fontWeight: '400',
    color: '#ADB6B9',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 4,
  },
  message: {
    flex: 1,

    fontSize: 12,
    fontWeight: '400',
    color: '#6C777C',
    marginTop: 4,
    overflow: 'hidden',
  },
  unReadContainer: {
    marginHorizontal: 4,
    paddingHorizontal: 6,
    minWidth: 22,
    backgroundColor: '#0175FF',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  unReadText: {
    color: '#fff',
  },
});
