import JA_LOCALE from './ja.json';
import EN_LOCALE from './en.json';

const LOCALE = {
  EN: 'en',
  JA: 'ja',
};

export const APP_LOCALE = {
  [LOCALE.EN]: EN_LOCALE as Record<string, string>,
  [LOCALE.JA]: JA_LOCALE as Record<string, string>,
};

type MessageProps = {
  id: string;
  locale?: string;
};

export const getMessage = (
  { id, locale = 'ja' }: MessageProps,
  values?: Record<string, string>
) => {
  if (!Object.values(LOCALE).includes(locale) || !id) {
    return id;
  }
  let message = APP_LOCALE[locale][id];
  if (values && message) {
    const keys = Object.keys(values);
    keys.forEach((key) => {
      const stringRegex = `{${key}}`.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      const regex = new RegExp(stringRegex, 'g');
      message = message.replace(regex, values[key]);
    });
  }

  return message || id;
};
