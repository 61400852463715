export default {
  name: 'InAppPurchaseSubscription',
  displayName: {
    en: 'InAppPurchaseSubscription',
    ja: 'サブスクリプション',
  },
  icon: './icon.png',
  defaultWidth: 155,
  defaultHeight: 44,
  resizeX: true,
  props: [
    {
      name: 'type',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'contained',
      control: {
        type: 'menu',
        options: [
          {
            label: 'canvas.menuRight.elementTab.button.type.text',
            value: 'text',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.outline',
            value: 'outlined',
          },
          {
            label: 'canvas.menuRight.elementTab.button.text.button',
            value: 'contained',
          },
        ],
      },
    },
    {
      name: 'text',
      displayName: {
        en: 'Text',
        ja: 'テキスト',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'androidProductId',
      displayName: {
        en: 'Android Product ID',
        ja: 'Android Product ID',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'iosProductId',
      displayName: {
        en: 'IOS Product ID',
        ja: 'IOS Product ID',
      },
      type: 'text',
      default: 'Button',
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'appleSecretId',
      displayName: {
        en: 'Apple shared secret',
        ja: 'Apple shared secret',
      },
      default: '',
      type: 'text',
      disableDynamicValues: true,
      styles: {
        fontFamily: '@body',
        fontWeight: '600',
      },
    },
    {
      name: 'currentSubscriptionId',
      displayName: {
        en: 'Current Subscription ID',
        ja: '現在のサブスクリプションID',
      },
      type: 'text',
      default: '',
    },
    {
      name: 'icon',
      displayName: {
        en: 'Icon',
        ja: 'アイコン',
      },
      type: 'icon',
      default: 'add',
    },
    {
      name: 'primaryColor',
      displayName: {
        en: 'Button Color',
        ja: 'Button Color',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'contrastColor',
      displayName: {
        en: 'Icon & Text Color',
        ja: 'アイコンの色',
      },
      type: 'color',
      default: '@contrast:primaryColor',
      enabled: {
        type: 'contained',
      },
    },
    {
      name: 'upperCase',
      displayName: {
        en: 'Uppercase',
        ja: '大文字',
      },
      type: 'boolean',
      default: true,
    },
    {
      name: 'successActions',
      displayName: {
        en: 'Actions when Subscription is paid successful',
        ja: 'サブスクリプション決済成功時のアクション',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'Actions when Subscription is paid failed',
        ja: 'サブスクリプション決済失敗時のアクション',
      },
      type: 'action',
    },
    {
      name: 'subscriptionCancelledActions',
      displayName: {
        en: 'Actions when Subscription is cancelled',
        ja: 'サブスクリプションがキャンセルされた時のアクション',
      },
      type: 'action',
    },
  ],
};
