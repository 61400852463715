import SendIcon from '@common/components/Icons/SendIcon';
import React, { Fragment, useMemo } from 'react';
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import { useChatComponentContext } from '../context';
import { FILE_UPLOADING, MAX_INPUT_LENGTH, useChatComponent } from '../hook';
import { CusImagePicker } from './CusImagePicker';
import { FileList } from './FileList';

export const InputMessage = () => {
  const {
    filesValue,
    inputValue,
    setInputValue,
    messageHistoryLoading,
    messageSending,
    fileUploading,
    props,
  } = useChatComponentContext();
  const { sendMessage } = useChatComponent(props);

  const files = useMemo(() => {
    if (fileUploading) {
      return [...(filesValue || []), FILE_UPLOADING];
    }
    return filesValue;
  }, [fileUploading, filesValue]);

  return (
    <View style={styles.container}>
      {files?.length ? (
        <View style={styles.fileListContainer}>
          <FileList files={files} showRemoveFile />
        </View>
      ) : (
        <Fragment />
      )}
      <View style={styles.inputContainer}>
        <TextInput
          style={styles.textInputContainer}
          placeholder="メッセージを入力"
          onChangeText={(e) => setInputValue?.(e)}
          value={inputValue}
          multiline
          maxLength={MAX_INPUT_LENGTH}
        />
        <View style={styles.buttonGroup}>
          <CusImagePicker />
          <TouchableOpacity
            style={styles.sendButton}
            onPress={sendMessage}
            disabled={messageHistoryLoading || messageSending}
          >
            <SendIcon />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    minHeight: 57,
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: 'white',
    borderTopWidth: 1,
    borderTopColor: '#F0F0F0',
    alignItems: 'flex-start',
    shadowColor: '#164F78',
    shadowOffset: { width: 0, height: -34 },
    shadowOpacity: 0.03,
    shadowRadius: 30,
  },
  fileListContainer: {
    minHeight: 70,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    marginTop: 4,
  },
  textInputContainer: {
    flex: 1,
    paddingHorizontal: 14,
    paddingVertical: 8,
    backgroundColor: 'white',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E9EFF2',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 25.2,
    maxHeight: 120,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 12,
  },
  sendButton: {
    marginLeft: 8,
  },
});
