import Icons from 'react-native-vector-icons/MaterialIcons';
import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import React, { useEffect, useState } from 'react';
import {
  Image,
  Linking,
  Share,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useChatComponentContext } from '../../context';

const LinkPreviewItem: React.FC<
  ChatComponentType.LinkPreviewResponse & { isOwn: boolean }
> = ({ description, hostname, image, siteName, title, isOwn }) => {
  return (
    <View style={styles.previewContainer}>
      {image && <Image source={{ uri: image }} style={styles.previewImage} />}
      <View style={styles.previewTextContainer}>
        <Text
          style={[styles.previewSiteName, isOwn && styles.ownPreviewText]}
          numberOfLines={1}
        >
          {siteName || hostname}
        </Text>
        <Text
          style={[styles.previewTitle, isOwn && styles.ownPreviewText]}
          numberOfLines={2}
        >
          {title}
        </Text>
        <Text
          style={[styles.previewDescription, isOwn && styles.ownPreviewText]}
          numberOfLines={3}
        >
          {description}
        </Text>
      </View>
    </View>
  );
};

export const LinkPreview: React.FC<{ isOwn: boolean; url: string }> = ({
  isOwn,
  url,
}) => {
  const [linkPreview, setLinkPreview] =
    useState<ChatComponentType.LinkPreviewResponse | null>(null);
  const { fetchLinkPreview } = useChatComponentContext();

  const handleShare = async () => {
    try {
      await Share.share({
        message: linkPreview?.title || url, // This works on mobile
        url: url, // Required for iOS
        title: linkPreview?.title || 'Share',
      });
    } catch (error) {
      console.log('===Error sharing:', error);
    }
  };

  useEffect(() => {
    if (url) {
      fetchLinkPreview({ url })
        .then((r) => setLinkPreview(r))
        .catch(() => {});
    }
  }, [url]);

  return linkPreview ? (
    <View style={styles.wrapper}>
      {/* Share Icon moved completely outside */}
      {isOwn ? (
        <TouchableOpacity
          style={styles.shareIconWrapper}
          onPress={handleShare}
          activeOpacity={0.8}
        >
          <Icons name="ios-share" size={16} color="#6C777C" />
        </TouchableOpacity>
      ) : (
        <React.Fragment />
      )}

      {/* Link Preview Box */}
      <TouchableOpacity
        style={[
          styles.container,
          isOwn ? styles.ownContainer : styles.receivedContainer,
        ]}
        onPress={() => Linking.openURL(url)}
        activeOpacity={0.8}
      >
        <LinkPreviewItem {...linkPreview} isOwn={isOwn} />
      </TouchableOpacity>

      {/* Share Icon moved completely outside */}
      {!isOwn ? (
        <TouchableOpacity
          style={styles.shareIconWrapper}
          onPress={handleShare}
          activeOpacity={0.8}
        >
          <Icons name="ios-share" size={16} color="#6C777C" />
        </TouchableOpacity>
      ) : (
        <React.Fragment />
      )}
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    minWidth: '75%',
  },
  shareIconWrapper: {
    marginRight: 6,
    padding: 6,
  },
  container: {
    flex: 1, // Takes up remaining space
    borderRadius: 8,
    borderWidth: 1,
    borderColor: '#E9EFF2',
    overflow: 'hidden',
    padding: 8,
  },
  ownContainer: {
    backgroundColor: '#2674E9',
    borderColor: '#1E5BB2',
  },
  receivedContainer: {
    backgroundColor: '#F7F9FB',
  },
  previewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  previewImage: {
    width: 50,
    height: 50,
    borderRadius: 6,
    marginRight: 8,
    backgroundColor: '#DDD',
  },
  previewTextContainer: {
    flex: 1,
  },
  previewSiteName: {
    fontSize: 12,
    color: '#6C777C',
  },
  previewTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#1A2737',
    marginTop: 2,
  },
  previewDescription: {
    fontSize: 12,
    color: '#6C777C',
    marginTop: 2,
  },
  ownPreviewText: {
    color: 'white',
  },
});
