export default {
  name: 'SelectList',
  icon: 'example-thumbnail.png',
  displayName: {
    en: 'SelectList',
    ja: 'アンケート',
  },
  defaultWidth: 155,
  defaultHeight: 44,
  minWidth: 100,
  resizeX: 'true',
  resizeY: 'true',
  props: [
    {
      name: 'value',
      type: 'string',
      role: 'formValue',
      enabled: {
        selectDatabase: false,
      },
    },
    {
      name: 'value',
      type: 'string',
      role: 'formValue',
      enabled: {
        selectDatabase: true,
        useMultipleSelection: true,
      },
      reference: 'items',
    },
    {
      name: 'value',
      type: 'object',
      role: 'formValue',
      enabled: {
        selectDatabase: true,
        useMultipleSelection: false,
      },
      reference: 'items',
    },
    {
      name: 'showHeader',
      displayName: {
        ja: 'ヘッダーを表示',
        en: 'Show header',
      },
      type: 'switch',
      default: true,
    },
    {
      name: 'selectDatabase',
      displayName: {
        ja: 'データベースの選択',
        en: 'Select Database',
      },
      type: 'switch',
      default: false,
    },
    {
      name: 'itemsSeparate',
      displayName: {
        ja: '項目のリスト',
        en: 'List of Items',
      },
      placeholder: {
        ja: '改行ごとに選択項目が追加されます',
        en: 'Enter items separated by a breaking line',
      },
      disableDynamicValues: true,
      area: true, // use text-area
      type: 'text',
      enabled: {
        selectDatabase: false,
      },
    },
    {
      name: 'items',
      displayName: {
        en: 'What is this a list of?',
        ja: 'データベースの選択',
      },
      type: 'list',
      enabled: {
        selectDatabase: true,
      },
    },
    {
      name: 'useMultipleSelection',
      displayName: {
        ja: '複数選択',
        en: 'Multiple selection',
      },
      type: 'switch',
      default: true,
    },
    {
      name: 'defaultData',
      displayName: { en: 'Default value', ja: '初期値' },
      reference: 'items',
      enabled: {
        selectDatabase: true,
      },
      type: 'object',
    },
    {
      name: 'defaultSeparate',
      displayName: { en: 'Default value', ja: '初期値' },
      enabled: {
        selectDatabase: false,
      },
      type: 'text',
    },
    {
      name: 'content',
      displayName: { en: 'Option Content', ja: '選択項目' },
      role: 'listItem',
      reference: 'items',
      enabled: {
        selectDatabase: true,
      },
      type: 'text',
    },
    {
      name: 'borderColor',
      displayName: {
        en: 'Border Color',
        ja: 'Border Color',
      },
      type: 'color',
      default: 'transparent',
      enabled: {
        border: 'true',
      },
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'Button',
        ja: 'Button',
      },
    },
  ],
  childComponents: [
    {
      name: 'header',
      displayName: { en: 'Header', ja: 'ヘッダ' },
      props: [
        {
          name: 'backgroundColor',
          type: 'color',
          displayName: {
            en: 'Background color',
            ja: '塗りつぶし',
          },
          default: '#fff',
        },
      ],
    },
    {
      name: 'title',
      displayName: { en: 'Title', ja: 'タイトル' },
      props: [
        {
          name: 'text',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'text',
          default: 'Title',
        },
        {
          name: 'fontFamily',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'fontSize',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'fontSize',
          default: 14,
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '塗り',
          },
          type: 'color',
          default: 'black',
        },
      ],
    },
    {
      name: 'subTitle',
      displayName: { en: 'Subtitle', ja: 'サブタイトル' },
      props: [
        {
          name: 'text',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'text',
          default: 'Subtitle',
        },
        {
          name: 'enabled',
          type: 'boolean',
          default: 'false',
        },
        {
          name: 'fontFamily',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'fontSize',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'fontSize',
          default: 14,
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '塗り',
          },
          type: 'color',
          default: 'black',
        },
      ],
    },
    {
      name: 'option',
      displayName: { en: 'Option', ja: 'オプション' },
      props: [
        {
          name: 'fontFamily',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'fontFamily',
          default: 'Noto Sans JP, sans-serif',
        },
        {
          name: 'fontSize',
          displayName: { en: 'Text', ja: 'テキスト' },
          type: 'fontSize',
          default: 14,
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '塗り',
          },
          type: 'color',
          default: 'black',
        },
        {
          name: 'backgroundColor',
          type: 'color',
          displayName: {
            en: 'Background color',
            ja: '塗りつぶし',
          },
          default: '#fff',
        },
      ],
    },
  ],
};
