import FileAttachIcon from '@common/components/Icons/FileAttachIcon';
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { FILE_UPLOADING_TYPE, MAX_FILES_COUNT } from '../../hook';
import { CusImagePickerProps } from './type';
import { useChatComponentContext } from '../../context';

export const CusImagePicker: React.FC<CusImagePickerProps> = () => {
  const {
    filesValue,
    setFilesValue,
    selectFile,
    messageHistoryLoading,
    messageSending,
  } = useChatComponentContext();

  const chooseFile = async () => {
    if (setFilesValue && selectFile) {
      const file = await selectFile({ imageOnly: false });
      if (file) {
        setFilesValue((old) =>
          [...old, file].filter((f) => f.type !== FILE_UPLOADING_TYPE)
        );
        return;
      }
      console.error('===error when select file', 'file is empty');
    }
    alert('Something went wrong!');
  };
  return (
    <TouchableOpacity
      style={{
        marginLeft: 8,
      }}
      onPress={chooseFile}
      disabled={
        messageSending ||
        messageHistoryLoading ||
        (filesValue?.length || 0) >= MAX_FILES_COUNT
      }
    >
      <FileAttachIcon />
    </TouchableOpacity>
  );
};
