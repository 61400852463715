import api from '@common/configs/api';
import { IAppInfo } from '@common/types';
import { initializeApp } from 'firebase/app';
import {
  deleteToken,
  getToken,
  isSupported,
  getMessaging,
} from 'firebase/messaging';
import { Platform } from 'react-native';

const isBrowserSupported = () =>
  typeof window !== 'undefined' &&
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

const FCM_VAPI_KEY =
  'BLi3OoGgMWSTBGRbvvwrklpkMPJmIs1pdHOKnwfyFL_AAbgp-fLNWyPPC07XorzP2shq7BhSB9zo8Gjd5p_pqdg';
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyC4UpS0WJTfOt9bBL1Mjayk2n_2hBEI8C8',
  authDomain: 'mikoseatest.firebaseapp.com',
  databaseURL: 'https://mikoseatest-default-rtdb.firebaseio.com',
  projectId: 'mikoseatest',
  storageBucket: 'mikoseatest.firebasestorage.app',
  messagingSenderId: '910420135035',
  appId: '1:910420135035:web:e3a0a2db6538730e7d26de',
  measurementId: 'G-QE8KCQJR0C',
};

const FCM_TOKEN_STORAGE_KEY = 'FCM_TOKEN';

async function requestPermission() {
  try {
    if (Notification.permission === 'granted') {
      return true;
    }
    console.log('===Permission requesting...');
    const permission = await Notification.requestPermission();
    console.log('===permission', permission);
    if (permission === 'granted') {
      return true;
    }
    return false;
  } catch (error) {
    console.error('===Error when request permission', error);
    return false;
  }
}

const _registerFCMUser = async (
  user: any,
  { appId }: Pick<IAppInfo, 'appId'>
) => {
  try {
    if (!isBrowserSupported()) {
      console.error('===Browser is not supported to push notification');
    }
    const permission = await requestPermission();
    if (!permission) {
      return;
    }
    const isSupport = isSupported();
    if (!isSupport) {
      console.error('===Browser is not supported to use FCM');
      return;
    }
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    const token = await getToken(messaging, {
      vapidKey: FCM_VAPI_KEY,
    });
    if (!token) {
      console.error('===FCM token failure');
      return;
    }
    if (token) {
      console.log('===FCM token', token);
      localStorage.setItem(FCM_TOKEN_STORAGE_KEY, token);
    }

    // add user token in user register notify
    api({
      method: 'post',
      url: `/v2/user-register-notify`,
      data: {
        appId,
        userId: user.id,
        token: token,
        record: '',
        platform: Platform.OS,
      },
    });

    // TODO: support subscribe to topic
  } catch (error) {
    console.error('===Error when registerFCMUser', error);
  }
};
export async function registerFCMUser(
  user: any,
  appInfo: Pick<IAppInfo, 'appId' | 'metadata'>
) {
  if (!appInfo.metadata?.pushNotification?.web?.enabled) {
    return;
  }
  _registerFCMUser(user, appInfo);
}

const _unregisterFCMUser = async () => {
  try {
    // Get the token
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    await deleteToken(messaging).catch((error) => {
      console.error('===FCM error when delete token', error);
    });
    const token = localStorage.getItem(FCM_TOKEN_STORAGE_KEY);

    if (!token) {
      return;
    }

    // remove user token in user register notify
    api({
      method: 'delete',
      url: `/v2/user-register-notify`,
      headers: {
        token: token,
      },
    });
  } catch (error) {
    console.error('===Error when unregisterFCMUser', error);
  }
};
export async function unregisterFCMUser() {
  _unregisterFCMUser();
}
