import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  },
  popupContainer: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: 10,
    elevation: 5,
    padding: 10,
    width: 250,
    maxHeight: 300,
    shadowColor: '#172F79',
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.03,
    shadowRadius: 30,
    borderColor: '#e9eff3',
    borderWidth: 1,
  },
  searchInput: {
    marginBottom: 10,
    backgroundColor: '#E4EAEE',
    paddingHorizontal: 16,
    paddingVertical: 8,
    fontSize: 14,
  },
  iconGrid: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  iconButton: {
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginHorizontal: 3,
  },
  svgIcon: {
    position: 'absolute',
    right: 20,
    width: 25,
    top: 13,
    color: '#6C777C',
  },
});

export default styles;
