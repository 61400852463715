import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({

  wrapperDivider: {
    borderColor: '#E9EFF2',
    borderBottomWidth: 1,
    paddingBottom: 16,
    paddingTop: 16,
  },
  titleText: {
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 43.2,
    letterSpacing: 0,
  },
  labelFieldLayout: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  label: {
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 25.2,
    letterSpacing: 0,
    color: '#6C777C',
    maxWidth: '120px',
    minWidth: '120px'
  },
  optionList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flex: 1,
  },
  optionTouch: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  optionItem: {
    width: 80,
    textAlign: 'center',
    overflow: 'hidden',
    color: 'black',
    // cursor: 'pointer',
  },
  optionIcon: {
    width: '100%',
    // borderRadius: 50,
    height: 80,
    padding: 7,
  },
  optionName: {
    // fontSize: 13,
    fontWeight: '500',
  },
  optionCopyTitle: {
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 8,
  },
  optionCopyLink: {
    width: '100%', // Makes the width full screen
    height: 41,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 16,
    backgroundColor: '#E4EAEE',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  flexFluid: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  optionButtonName: {
    fontSize: 14,
    color: '#066af7',
    textTransform: 'capitalize',
    fontWeight: '400',
    lineHeight: 1.5715,
    maxWidth: 20
  },
});

export default styles;
