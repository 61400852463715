import { StyleSheet } from 'react-native';
import { white } from 'react-native-paper/lib/typescript/styles/colors';

const styles = StyleSheet.create({
  selectWrapper: {
    width: 200,
    height: '100vh',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-end',
    paddingVertical: 30,
  },
  buttonBackDashboard: {
    fontWeight: '600',
    borderRadius: 8,
    color: 'rgb(255, 255, 255)',
    paddingVertical: 5,
    paddingHorizontal: 20,
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
    flexDirection: 'row',
  },
  containerCol: {
    alignItems: 'stretch',
    flexDirection: 'column',
    height: '100%',
  },
  navBarWrapper: {
    width: '100%',
  },
  navBar: {
    height: 50,
    backgroundColor: '#FFFFFF',
    padding: 10,
    alignSelf: 'center',
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center',
  },
  childBar: {
    paddingHorizontal: 20,
  },
  child: {
    flex: 1,
  },
  alignCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F2F5F7',
  },
  overflow: {
    overflow: 'scroll',
  },
  button: {
    height: 43,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: '#EFF3F6',
    backgroundColor: 'white',
    minWidth: 79,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    gap: 8, // This won't work directly in RN, so we handle spacing manually
  },
  label: {
    fontSize: 12,
    lineHeight: 18,
    letterSpacing: 0.36, // 3% of 12px font-size
    color: '#222',
  },
  iconContainer: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#357AFF',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
  },
});

export default styles;
