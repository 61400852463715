import React, { memo, useCallback, useState, useRef, useEffect } from 'react';
import {
  ImageBackground,
  Text,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { View } from 'react-native-animatable';
import Toast from '@common/components/Toast';
import styles from './style';
import ChatThumbnail from '@common/assets/images/chat-thumbnail.png';
import IFrameThumbnail from '@common/assets/images/iframe-thumbnail.png';
import { ChatSettings } from '@common/screens/ShareScreenModal';
import ArrowBackIcon from '@common/components/Icons/ArrowBackIcon';
import { copyToClipboard } from '../hook';
import CopyPasteIcon from '@common/components/Icons/CopyPasteIcon';
import {
  showIconPicker,
  IconPicker,
} from '@common/components/IconPicker/IconPicker';
import { iconList } from '@common/constants/icon';
import { Switch } from 'react-native-paper';
import { getMessage } from '@common/constants/locale';

interface Props {
  appData?: any;
  chatSettings: any;
  onNext?: () => void;
  onBack?: () => void;
  updateChatSetting: (key: keyof ChatSettings, value: any) => void;
  locale?: any;
}
const Embed: React.FC<Props> = ({
  appData,
  onNext,
  onBack,
  chatSettings,
  updateChatSetting,
  locale,
}) => {
  const [selectedEmbedValue, setSelectedEmbedValue] = useState('iFrame');
  const [selectedChatValue, setSelectedChatValue] = useState('icon');
  const [scriptTagUrl, setScriptTagUrl] = useState('');
  const [svgPath, setSvgPath] = useState<string | any>('');
  const options = [
    {
      label: getMessage({ id: 'share.modal.embed.option.iframe', locale }),
      value: 'iFrame',
    },
    {
      label: getMessage({ id: 'share.modal.embed.option.chat', locale }),
      value: 'chat',
    },
  ];

  const chatOptions = [
    {
      label: getMessage({ id: 'share.modal.embed.chatOption.icon', locale }),
      value: 'icon',
    },
    {
      label: getMessage({ id: 'share.modal.embed.chatOption.image', locale }),
      value: 'image',
    },
  ];

  const getTargetUrl = window.location.href;
  const iframeValue = `<iframe src="${getTargetUrl}" allowpaymentrequest="true" allow="camera; microphone;" allowusermedia frameborder="0" style="width: 100%; height: 100dvh; border: none;" title="${
    appData?.name || ''
  }"></iframe>`;

  useEffect(() => {
    const [searchIcon] = iconList.filter(
      (icon) => icon.name == chatSettings.emoticon
    );
    setSvgPath(searchIcon.path);
    const currentFrameValue =
      chatSettings && chatSettings.enabled ? 'chat' : 'iFrame';
    const iconParams = chatSettings.emoticon
      ? `&media=${chatSettings.emoticon}-icon`
      : '';
    const text = chatSettings.textDisplay
      ? `&text=${encodeURI(chatSettings.textDisplay)}`
      : '';
    const scriptTagUrl = `<script src=${window.location.origin}/chatbot.js?paramID=${appData.appId}${iconParams}${text}></script>`;
    setSelectedEmbedValue(currentFrameValue);
    setScriptTagUrl(scriptTagUrl);
  }, [chatSettings]);

  const RadioButtonImageOrElement = ({
    label,
    selected,
    onPress,
    value,
    source,
    children,
  }: any) => {
    return (
      <TouchableOpacity onPress={() => onPress(value)}>
        <View style={styles.radioContainer}>
          <View
            style={[styles.radioOuterCircle, selected && styles.radioSelected]}
          >
            {selected && <View style={styles.radioInnerCircle} />}
          </View>
          <Text style={styles.radioLabel}>{label}</Text>
        </View>
        <View style={styles.break} />
        <View
          style={{
            borderWidth: 2,
            borderRadius: 5,
            borderColor: selected && !children ? '#0175FF' : 'transparent',
          }}
        >
          {!children && (
            <ImageBackground
              source={source}
              resizeMode="cover"
              style={{
                width: '216px',
                height: '144.35px',
              }}
            />
          )}
          {children}
        </View>
        <View style={styles.break} />
      </TouchableOpacity>
    );
  };

  return (
    <TouchableOpacity>
      <View>
        <TouchableOpacity onPress={onBack}>
          <View style={{ position: 'relative', top: 0, left: 0 }}>
            <ArrowBackIcon />
          </View>
        </TouchableOpacity>
        <View>
          <Text style={styles.titleText}>
            {getMessage({ id: 'share.modal.embed.title', locale })}
          </Text>
          <Text style={styles.paragraph}>
            {getMessage({ id: 'share.modal.embed.description', locale })}
          </Text>
        </View>
        <View
          style={[
            styles.row,
            styles.justifyContent,
            styles.break,
            styles.wrapperDivider,
          ]}
        >
          <View style={{ justifyContent: 'center' }}>
            <RadioButtonImageOrElement
              label={options[0].label}
              selected={selectedEmbedValue === options[0].value}
              value={options[0].value}
              onPress={() => {
                setSelectedEmbedValue(options[0].value);
                updateChatSetting('enabled', false);
              }}
              source={{
                uri: IFrameThumbnail,
              }}
            />
          </View>
          <View>
            <RadioButtonImageOrElement
              label={options[1].label}
              selected={selectedEmbedValue === options[1].value}
              value={options[1].value}
              onPress={() => {
                setSelectedEmbedValue(options[1].value);
                updateChatSetting('enabled', true);
              }}
              source={{
                uri: ChatThumbnail,
              }}
            />
          </View>
        </View>
        <Toast />
        {selectedEmbedValue == 'iFrame' && (
          <>
            <View
              style={{
                borderColor: '#E9EFF2',
                borderWidth: 1,
                borderRadius: 8,
                overflow: 'scroll',
              }}
            >
              <View
                style={{
                  borderColor: '#E9EFF2',
                  borderBottomWidth: 1,
                  padding: 16,
                  paddingRight: 24,
                  paddingLeft: 24,
                  display: 'flex',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ color: '#6C777C' }}>
                  {getMessage({ id: 'share.modal.embed.copy', locale })}
                </Text>
                <TouchableOpacity
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                  onPress={(event) => {
                    copyToClipboard(iframeValue, event);
                  }}
                >
                  <CopyPasteIcon />
                </TouchableOpacity>
              </View>
              <View style={{ padding: 24, backgroundColor: '#E4EAEE' }}>
                <Text>{iframeValue}</Text>
              </View>
            </View>
          </>
        )}

        {selectedEmbedValue == 'chat' && (
          <>
            <Text style={styles.subtitle}>
              {getMessage({ id: 'share.modal.embed.buttonDesign', locale })}
            </Text>
            <View
              style={[
                styles.row,
                styles.justifyContent,
                styles.break,
                styles.wrapperDivider,
              ]}
            >
              <View>
                <View style={{ justifyContent: 'flex-start' }}>
                  <RadioButtonImageOrElement
                    label={chatOptions[0].label}
                    selected={selectedChatValue === chatOptions[0].value}
                    value={options[0].value}
                    onPress={() => {
                      setSelectedChatValue(chatOptions[0].value);
                      updateChatSetting('iconType', 'icon');
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#262A2B4D',
                        padding: 10,
                        width: '216px',
                        height: '135px',
                      }}
                      onPress={(event) => {
                        showIconPicker(event, (icon) => {
                          updateChatSetting('emoticon', icon);
                        });
                      }}
                    >
                      <svg viewBox="0 0 24 26" width="30" height="30">
                        <path d={svgPath} fill={'white'} />
                      </svg>
                    </TouchableOpacity>
                  </RadioButtonImageOrElement>
                </View>
              </View>
              <View>
                <View style={{ justifyContent: 'flex-start' }}>
                  <View
                    style={[
                      styles.radioContainer,
                      {
                        width: '216px',
                        height: '144.35px',
                      },
                    ]}
                  ></View>
                </View>
              </View>
            </View>
            <View style={styles.wrapperDivider}>
              <View style={styles.containerSwitch}>
                <Switch
                  value={chatSettings.enabledTextDisplay}
                  onValueChange={(value) =>
                    updateChatSetting('enabledTextDisplay', value)
                  }
                />
                <Text style={styles.labelSwitch}>
                  {getMessage({ id: 'share.modal.embed.text', locale })}
                </Text>
                <TextInput
                  style={[
                    styles.input,
                    !chatSettings.enabledTextDisplay && styles.disabledInput,
                  ]}
                  value={chatSettings.textDisplay}
                  onChangeText={(value) => {
                    updateChatSetting('textDisplay', value);
                  }}
                  placeholder={getMessage({
                    id: 'share.modal.embed.inputPlaceholder',
                    locale,
                  })}
                  editable={chatSettings.enabledTextDisplay}
                />
              </View>
            </View>
            <View style={styles.break} />
            <View
              style={{
                borderColor: '#E9EFF2',
                borderWidth: 1,
                borderRadius: 8,
              }}
            >
              <View
                style={{
                  borderColor: '#E9EFF2',
                  borderBottomWidth: 1,
                  padding: 16,
                  paddingRight: 24,
                  paddingLeft: 24,
                  display: 'flex',
                  position: 'relative',
                  flexDirection: 'row',
                }}
              >
                <Text style={{ color: '#6C777C', flex: 1 }}>
                  {getMessage({ id: 'share.modal.embed.copy', locale })}
                </Text>
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                  onPress={(event) => {
                    copyToClipboard(scriptTagUrl, event);
                  }}
                >
                  <CopyPasteIcon />
                </TouchableOpacity>
              </View>

              <View style={{ padding: 24, backgroundColor: '#E4EAEE' }}>
                <Text selectable={true}>{scriptTagUrl}</Text>
              </View>
            </View>
          </>
        )}
      </View>
      <Toast />
      <IconPicker />
    </TouchableOpacity>
  );
};

export default memo(Embed);
