import {
  ActionStatus,
  TABLE_DELETE,
  TOKEN_KEY,
} from '@common/constants/shared';
import { setCurrentRecordIds } from '@common/redux/slice/database';
import { resetValue, resetValueForm } from '@common/redux/slice/formInputs';
import store from '@common/redux/store';
import { fetchScreenAction } from '@common/services';
import { IAction, IObj } from '@common/types';
import { MetadataAction } from '@common/types/action';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { ActionType } from 'click-types';
import { find, get, isEmpty, some } from 'lodash';
import * as actions from './ActionItem';
import { getInputFromField } from './func/helps';
import { showAlert } from '@common/utils/ShowAlert';
import AppLocale from '@common/configs/translation';

export type ActionResponse = {
  status: string;
  message?: string;
  accessToken?: string;
  currentRecordIds?: IObj;
};

const notApiActions = [
  ActionType.NAVIGATE,
  ActionType.LOGOUT,
  ActionType.FORGOT_PASSWORD,
  ActionType.FOCUS_ON_INPUT,
];

const isOAuthAction = (actionType: ActionType) => {
  return [
    ActionType.LINE_LOGIN,
    ActionType.DOCOMO_LOGIN,
    ActionType.DID_LOGIN,
    ActionType.TRUSTDOCK_LOGIN,
    ActionType.APPLE_LOGIN,
    ActionType.SIGNUP,
    ActionType.SIGNIN,
    ActionType.EXTERNAL_USER_LOGIN,
  ].includes(actionType);
};

const allowAPI = (actions: IAction[]) =>
  some(actions, (action) => {
    const conditionals = action.options?.conditionals || [];
    return (
      conditionals.length > 0 || !notApiActions.includes(action.actionType)
    );
  });

export const actionPromise = async ({
  objectId,
  actionId,
  clientValues,
  screenUuid,
  currentRecordIds,
  messages,
  data,
  screenAction,
  arrayAction,
  scannerId,
  formId,
}: {
  objectId?: string;
  actionId: string;
  clientValues: IObj;
  screenUuid: string;
  currentRecordIds?: IObj;
  messages: IObj;
  data?: IObj;
  screenAction?: boolean;
  arrayAction: any[];
  scannerId?: string;
  formId?: string;
}): Promise<any> => {
  try {
    //debugger;
    const allowApi = allowAPI(arrayAction);
    let response: any;

    if (allowApi || actionId === TABLE_DELETE) {
      response = await fetchScreenAction({
        objectId,
        actionId,
        clientValues: { ...clientValues, scannerId },
        screenUuid,
        data,
        screenAction,
      });
    } else {
      response = arrayAction;
    }

    const hasLinkAction = some(arrayAction || [], {
      actionType: ActionType.Navigate,
    });

    const dispatch = store.dispatch;
    const result: any[] = [];
    for (let i = 0; i < response.length; i++) {
      const currentAction = response[i];
      const isActionAvailable = JSON.stringify(
        get(currentAction, 'visibility', true)
      );

      if (isActionAvailable === 'false') continue;

      const isAuthAction = isOAuthAction(currentAction.actionType);

      const res: ActionResponse = await runAction(currentAction, {
        currentRecordIds,
        messages,
        record: data,
        ...(arrayAction &&
          arrayAction.length > 0 && { actionItem: arrayAction[i] }),
      });

      if (res.status === ActionStatus.SUCCESS) {
        const { accessToken = null, currentRecordIds = null } = res;
        if (isAuthAction && accessToken) {
          AsyncStorage.setItem(TOKEN_KEY, accessToken);
        }

        if (hasLinkAction && currentRecordIds) {
          dispatch(setCurrentRecordIds(currentRecordIds));
        }

        const metadataAction = find(arrayAction, { id: currentAction.id });

        const { inputs, formControl }: Record<string, any> =
          getInputFromField(metadataAction, { record: data, formId }) || {};

        if (
          currentAction.status === ActionStatus.SUCCESS &&
          [
            ActionType.CREATE_OBJECT,
            ActionType.SIGNUP,
            ActionType.UPDATE_OBJECT,
          ].includes(currentAction?.actionType)
        ) {
          !isEmpty(formControl) && dispatch(resetValueForm(formControl));
          !isEmpty(inputs) && dispatch(resetValue(inputs));
        }
      } else {
        if (isAuthAction) {
          const message = res.message?.toString();
          if (message) {
            showAlert(message);
          }
          return res;
        }
      }

      result.push({ ...res, actionId: currentAction.id });
    }

    return result;
  } catch (error) {
    console.log('actionPromise', error);
  }
};

export const onPressAction = async (response: any[]): Promise<any> => {
  try {
    const dispatch = store.dispatch;
    const appLocale = store.getState().language.locale;
    const result: any[] = [];
    for (let i = 0; i < response.length; i++) {
      const currentAction = response[i];
      const isActionAvailable = JSON.stringify(
        get(currentAction, 'visibility', true)
      );

      if (isActionAvailable === 'false') continue;

      const isAuthAction = isOAuthAction(currentAction.actionType);

      const res: ActionResponse = await runAction(currentAction, {
        currentRecordIds: {},
        messages: AppLocale[appLocale].messages,
      });

      if (res.status === ActionStatus.SUCCESS) {
        if (isAuthAction && res.accessToken) {
          localStorage.setItem(TOKEN_KEY, res.accessToken);
        }

        if (res?.currentRecordIds) {
          dispatch(setCurrentRecordIds(res?.currentRecordIds));
        }
      } else if (isAuthAction) {
        const message = res.message?.toString();
        if (message) {
          showAlert(message);
        }
        return res;
      }

      result.push(res);
    }

    return result;
  } catch (error) {
    console.log('actionPromise', error);
  }
};

function runAction(action: IAction, metadata: MetadataAction) {
  const { actionType } = action;

  switch (actionType) {
    // signin user action___________
    case ActionType.SIGNIN:
      return actions.signIn(action, metadata);
    case ActionType.EXTERNAL_USER_LOGIN:
      return actions.externalUserLogin(action, metadata);
    case ActionType.LINE_LOGIN:
      return actions.signIn(action, metadata, true);
    // //signup user action___________
    case ActionType.SIGNUP:
      return actions.signUp(action, metadata);

    // //Logout user
    case ActionType.LOGOUT:
      return actions.logOut(action);

    //forgot password
    case ActionType.FORGOT_PASSWORD:
      return actions.forgotPassword(action, metadata);

    //create user
    case ActionType.FIND_ONE_RECORD:
      return actions.findOneRecord(action, metadata);

    //create user
    case ActionType.CREATE_OBJECT:
      return actions.createObject(action, metadata);

    //update user
    case ActionType.UPDATE_OBJECT:
      return actions.updateObject(action, metadata);

    // //delete user
    case ActionType.DELETE_OBJECT:
      return actions.deleteObject(action, metadata);

    // return actions.deleteObject(action, metadata);
    case ActionType.UPDATE_MULTIPLE_OBJECT:
      return actions.updateMultipleObject(action, metadata);

    //set Input value
    case ActionType.SET_INPUT_VALUE:
      return actions.setInputValue(action, metadata);

    // TODO
    case ActionType.FOCUS_ON_INPUT:
      return actions.focusOnInput(action);

    // send email
    case ActionType.SEND_EMAIL:
      return actions.sendEmail(action, metadata);

    //push notification
    case ActionType.PUSH_NOTIFICATION:
      return actions.pushNotification(action, metadata);

    //external link
    case ActionType.EXTERNAL_LINK:
      return actions.externalLink(action, metadata);

    // //customAction
    case ActionType.CUSTOM:
      return actions.customAction(action, metadata);

    case ActionType.TRACKING_ANALYSTIC:
      return actions.sendEventAnalytics(action);

    case ActionType.QR_CODE_SCANNER:
      return action;

    case ActionType.DOWNLOAD_FILE:
      return actions.downloadFile(action, metadata);

    case ActionType.CREATE_MESSAGE_HISTORY:
      return action;

    // navigate action________________
    default:
      return actions.executeNavigate(action, metadata);
  }
}
