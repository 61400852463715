import React from 'react';
import Svg, { Path, Defs, ClipPath, Rect, G } from 'react-native-svg';

const ArrowForwardIcon = () => {
  return (
    <Svg width="8" height="7" viewBox="0 0 8 7" fill="none">
      <G clipPath="url(#clip0)">
        <Path
          d="M1.24609 3.5L7.24609 3.5"
          stroke="white"
          strokeWidth="0.611111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M4.69134 6.05481L7.24609 3.50006L4.69134 0.945313"
          stroke="white"
          strokeWidth="0.611111"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect
            width="7"
            height="8"
            fill="white"
            transform="matrix(0 1 -1 0 8 0)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default ArrowForwardIcon;
