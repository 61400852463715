import { BookingCalendarHolidayResponse } from '@nocode/types/bookingCalendar.type';

export const getJpHoliday =
  async (): Promise<BookingCalendarHolidayResponse> => {
    const url = 'https://holidays-jp.github.io/api/v1/date.json';
    return fetch(url).then((r) => {
      if (r.ok) {
        return r.json();
      }
      return {};
    });
  };
