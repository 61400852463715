export default {
  name: 'Fincode',
  displayName: {
    en: 'Click Pay',
    ja: 'Click Pay',
  },
  icon: './icon.png',
  defaultWidth: 335,
  defaultHeight: 280,
  props: [
    {
      namespace: 'fincode',
      name: 'settings',
      displayName: {
        en: 'Settings',
        ja: 'Settings',
      },
      type: 'payment',
    },
    // uncomment these lines to use before actions
    // {
    //   name: 'beforeActions',
    //   displayName: {
    //     en: 'Actions before Payment',
    //     ja: '支払い前のアクション',
    //   },
    //   type: 'action',
    // },
    {
      name: 'fincodeOrderId',
      displayName: 'ClickPay ID',
      type: 'string',
      role: 'formValue',
    },
    {
      name: 'successActions',
      displayName: {
        en: 'Actions when Fincode Payment is paid successful',
        ja: '決済成功時のアクション',
      },
      type: 'action',
    },
    {
      name: 'failedActions',
      displayName: {
        en: 'Actions when Fincode Payment is paid failed',
        ja: '決済失敗時のアクション',
      },
      type: 'action',
    },
    {
      name: 'activeColor',
      displayName: {
        en: 'Active Color',
        ja: 'アクティブ時の色',
      },
      type: 'color',
      default: '@primary',
    },
    {
      name: 'inactiveColor',
      displayName: {
        en: 'Inactive Color',
        ja: '非アクティブ時の色',
      },
      type: 'color',
      default: '#bbb',
    },
    {
      name: 'backgroundColor',
      displayName: {
        en: 'Background Color',
        ja: '背景色',
      },
      type: 'color',
      default: 'transparent',
    },
    {
      name: 'testModeEnabled',
      displayName: {
        en: 'Test Mode',
        ja: 'テストモード',
      },
      type: 'boolean',
      default: false,
    },
    {
      name: 'submitType',
      displayName: {
        en: 'Type',
        ja: 'タイプ',
      },
      type: 'text',
      default: 'payment',
      control: {
        type: 'menu',
        options: [
          {
            label: '支払い',
            value: 'payment',
          },
          {
            label: 'カード情報登録',
            value: 'saveCard',
          },
          {
            label: 'canvas.admin.dashboard.clickPay.Subscription',
            value: 'subscription',
          },
        ],
      },
    },
  ],
  childComponents: [
    {
      name: 'subscriptionConfig',
      displayName: {
        en: 'Subscription',
        ja: 'サブスクリプション',
      },
      enabled: {
        submitType: 'subscription',
      },
      props: [
        {
          name: 'planId',
          displayName: {
            en: 'Plan',
            ja: 'プラン',
          },
          type: 'text',
          default: '',
        },
        {
          name: 'billingTiming',
          displayName: {
            en: 'Billing timing',
            ja: '課金タイミング',
          },
          type: 'text',
          default: 'designatedDate',
          control: {
            type: 'menu',
            options: [
              {
                label:
                  'marketplace.fincode.subscriptions.billingTiming.designatedDate',
                value: 'designatedDate',
              },
              {
                label:
                  'marketplace.fincode.subscriptions.billingTiming.lastDayOfTheMonth',
                value: 'lastDayOfTheMonth',
              },
            ],
          },
        },
        {
          name: 'startDate',
          displayName: {
            en: 'Billing start date',
            ja: '課金開始日',
          },
          enabled: {
            billingTiming: ['designatedDate'],
          },
          type: 'text',
          placeholder: 'YYYY-MM-DD',
        },
        // {
        //   name: 'stopDate',
        //   displayName: {
        //     en: 'Billing stop date',
        //     ja: '課金停止日',
        //   },
        //   type: 'text',
        //   default: '2019-01-01',
        //   placeholder: 'YYYY-MM-DD',
        // },
        {
          name: 'useInitAmount',
          displayName: {
            en: 'Use Init amount',
            ja: '初回利用金額',
          },
          type: 'boolean',
          default: 'false',
          control: {
            type: 'menu',
            options: [
              {
                label: 'marketplace.fincode.subscriptions.useInitAmount.true',
                value: true,
              },
              {
                label: 'marketplace.fincode.subscriptions.useInitAmount.false',
                value: false,
              },
            ],
          },
        },
        {
          name: 'initAmount',
          displayName: {
            en: 'First time usage fee',
            ja: '初回利用金額',
          },
          enabled: {
            useInitAmount: [true],
          },
          type: 'number',
          conditions: {
            min: {
              value: 1,
              error:
                '初回利用金額は１円以上で設定を行なってください。設定しないを選択した場合は、通常のサブスクリプションの金額が適用されます。',
            },
          },
        },
      ],
    },
    {
      name: 'paymentOptions',
      displayName: {
        en: 'Payment Amount',
        ja: '支払い金額',
      },
      enabled: {
        submitType: 'payment',
      },
      props: [
        {
          name: 'paymentAmount',
          displayName: {
            en: 'Payment Amount',
            ja: '支払い金額',
          },
          type: 'number',
          default: 0,
        },
        {
          name: 'typeCurrency',
          displayName: {
            en: 'Currency Type',
            ja: '通貨',
          },
          type: 'text',
          default: 'jpy',
          control: {
            type: 'menu',
            options: [
              {
                label: 'JPY',
                value: 'jpy',
              },
            ],
          },
        },
      ],
    },
    {
      name: 'label',
      displayName: {
        en: 'Label',
        ja: 'ラベル',
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: true,
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#000000',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: 'transparent',
        },
        {
          name: 'cardholderNameLabel',
          displayName: {
            en: 'Cardholder Name',
            ja: 'カード名義人',
          },
          type: 'text',
          default: 'カード名義人',
        },
        {
          name: 'cardNumberLabel',
          displayName: {
            en: 'Card Number',
            ja: 'カード番号',
          },
          type: 'text',
          default: 'カード番号',
        },
        {
          name: 'dateofExpiryLabel',
          displayName: {
            en: 'Date of Expiry',
            ja: '有効期限',
          },
          type: 'text',
          default: '有効期限',
        },
        {
          name: 'securityCodeLabel',
          displayName: {
            en: 'Security code',
            ja: 'セキュリティコード',
          },
          type: 'text',
          default: 'セキュリティコード',
        },
      ],
    },
    {
      name: 'submitButton',
      displayName: {
        en: 'Submit Button',
        ja: '送信ボタン',
      },
      props: [
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: '支払い',
        },
        {
          name: 'removeCardText',
          displayName: {
            en: 'Remove card Text',
            ja: 'Remove テキスト',
          },
          type: 'text',
          default: 'Remove',
        },
        {
          name: 'fontSize',
        },
        {
          name: 'color',
          displayName: {
            en: 'Color',
            ja: '色',
          },
          type: 'color',
          default: '#fff',
        },
        {
          name: 'backgroundColor',
          displayName: {
            en: 'Background Color',
            ja: '背景色',
          },
          type: 'color',
          default: '#34A0DD',
        },
      ],
    },
    {
      name: 'rememberCheckbox',
      displayName: {
        en: 'Remember Checkbox',
        ja: '次回以降のカード入力省略',
      },
      enabled: {
        submitType: ['payment'],
      },
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'text',
          displayName: {
            en: 'Text',
            ja: 'テキスト',
          },
          type: 'text',
          default: '次回以降の入力を省略する',
        },
        {
          name: 'icon',
          displayName: {
            en: 'Icon',
            ja: 'アイコン',
          },
          type: 'icon',
          default: 'checkbox-blank-circle',
        },
      ],
    },
    // {
    //   name: 'paymentResponse',
    //   displayName: {
    //     en: 'Payment response',
    //     ja: 'レスポンス表示内容',
    //   },
    //   props: [
    //     {
    //       name: 'payment',
    //       displayName: {
    //         en: 'Payment success text',
    //         ja: 'Payment success text',
    //       },
    //       type: 'text',
    //     },
    //     {
    //       name: 'saveCard',
    //       displayName: {
    //         en: 'Save card success text',
    //         ja: 'Save card success text',
    //       },
    //       type: 'text',
    //     },
    //   ],
    // },
  ],
};
