import { HOME_URL, SubDomainBlockList } from '@common/constants/shared';
import { useParams } from '@common/routes/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  Platform,
  NativeModules,
  Image,
} from 'react-native';
import IFrameShare from '@common/components/iframeshare';
import styles from './style';
import { WebView } from 'react-native-webview';
import useAppDetail from '@common/hooks/app';
import { useSelector } from 'react-redux';
import { dimensionSelector } from '@common/redux/selectors/page';
import { checkMobileLayout } from '@common/utils/screen';
import useSocketConnection from '@common/hooks/useSocketAuthentication';
import Loading from '@common/components/Loading';
import ShareCustomDomain from '../ShareScreen/ShareScreenCustomDomain';
import clickLogo from '@common/assets/images/click-logo.png';
import { Button as RNButton } from 'react-native-paper';
import ArrowForwardIcon from '@common/components/Icons/ArrowForwardIcon';
import { getMessage } from '@common/constants/locale';
import { setupManifest } from '@common/utils/setupManifest';

const ShareScreenView = ({ appId }: any) => {
  const { isLoading, data } = useAppDetail({
    appId: appId,
    isShareScreen: true,
  });
  const device = 'iphonePro';
  const dimension = useSelector(dimensionSelector);
  const isMobile = checkMobileLayout(dimension);
  const [locale, setLocale] = useState('ja');
  let showBanner = true;
  if (data?.metadata && 'showBanner' in data?.metadata) {
    showBanner = data?.metadata.showBanner as any;
  }

  if (data) {
    let link: any = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = data.icon;
  }

  const handleClickCreate = useCallback(() => {
    window.open(HOME_URL, '_blank');
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('locale')) {
      const locale = urlSearchParams.get('locale') ?? 'ja';
      setLocale(locale);
    }
  }, []);

  if (isMobile) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let newUri = `/preview/${appId}?hiddenStatusBar=true`;
    if (urlSearchParams.has('locale')) {
      const locale = urlSearchParams.get('locale');
      newUri = newUri + `&locale=${locale}` + `&pwa=true`;
    }
    if (urlSearchParams.has('code') && urlSearchParams.has('type')) {
      const code = urlSearchParams.get('code');
      const type = urlSearchParams.get('type');
      newUri = newUri + `&code=${code}&type=${type}`;
    }
    if (urlSearchParams.has('masterId')) {
      const masterId = urlSearchParams.get('masterId');
      newUri = newUri + `&masterId=${masterId}`;
    }
    if (urlSearchParams.has('login_url')) {
      const login_url = urlSearchParams.get('login_url') ?? '';
      newUri = newUri + `&login_url=${encodeURIComponent(login_url)}`;
    }

    return <WebView source={{ uri: newUri }} />;
  }
  const scale = (dimension.height - 2 * 30) / (812 + 2 * 20);

  let newUri = `/preview/${appId}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isTutorial = urlSearchParams.get('tutorial');

  if (urlSearchParams.has('locale')) {
    const locale = urlSearchParams.get('locale');
    newUri = `/preview/${appId}?locale=${locale}`;
  }

  useEffect(() => {
    setupManifest(appId, data);
  }, [appId, data]);

  return !data ? null : data.platform === 'web' ? (
    <View>
      <WebView
        source={{ uri: newUri }}
        style={{
          width: '100vw',
          height: '100vh',
        }}
        scalesPageToFit={true}
      />
    </View>
  ) : (
    <SafeAreaView style={styles.containerCol}>
      {!isTutorial ? (
        <>
          {showBanner && (
            <View style={styles.navBarWrapper}>
              <View style={[styles.navBar]}>
                <View style={styles.childBar}>
                  {getMessage({ id: 'share.modal.link.productIntro', locale })}
                </View>
                <View style={styles.childBar}>
                  <Image
                    source={{ uri: clickLogo }}
                    style={{
                      width: 80,
                      height: 24,
                    }}
                  />
                </View>
                <View style={styles.childBar}>
                  {getMessage({ id: 'share.modal.link.developedBy', locale })}
                </View>
                <View>
                  <RNButton
                    mode="outlined"
                    onPress={() => handleClickCreate()}
                    contentStyle={styles.content}
                    labelStyle={styles.label}
                    style={styles.button}
                  >
                    {getMessage({ id: 'share.modal.link.startNow', locale })}
                    <View style={styles.iconContainer}>
                      <ArrowForwardIcon />
                    </View>
                  </RNButton>
                </View>
              </View>
            </View>
          )}
          <View style={[styles.child, styles.overflow, styles.alignCenter]}>
            <IFrameShare
              device={device}
              appId={appId}
              handleIframeWidth={() => {}}
              hasBorder={false}
            />
          </View>
        </>
      ) : (
        <View></View>
      )}
    </SafeAreaView>
  );
};

const ShareScreenUrl = () => {
  const currentHostName = window.location.hostname;
  const regexHost = /(\.click\.dev)$/;
  let subDomain =
    regexHost.test(currentHostName) && currentHostName.replace(regexHost, '');

  const isMobile = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };
  const params = useParams();

  // authentication SocketIO (forced to run first !)
  if (params.appId) {
    const { loading: reAuthLoading } = useSocketConnection();
    if (reAuthLoading) {
      return <Loading />;
    }
  }

  if (Platform.OS == 'web') {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const urlScheme = urlSearchParams.get('app');

    if (urlScheme) {
      // redirect to app for share SNS function
      const iosAppId = urlSearchParams.get('iosAppId');
      const iosAppName = urlSearchParams.get('iosAppName');
      const searchParams = window.location.search;

      const locale =
        NativeModules?.SettingsManager?.settings?.AppleLocale ||
        NativeModules?.SettingsManager?.settings?.AppleLanguages?.[0];

      const localeArr = typeof locale === 'string' ? locale.split('_') : [];

      const country = localeArr.length
        ? localeArr[localeArr.length - 1].toLowerCase()
        : null;

      var tryClose = function () {
        location.replace('about:blank');
      };
      var start = Date.now();
      var fallbackToStore = function () {
        var now = Date.now();
        if (now - start > 3000) {
          tryClose();
        } else {
          if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            window.location.replace(`market://details?id=${urlScheme}`);
          }
          if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
            if (iosAppId && iosAppName) {
              const urlAppStore = `https://apps.apple.com/${
                country ? country : `jp`
              }/app/${iosAppName}/${iosAppId}`;

              window.location.replace(urlAppStore);
            }
          }
          setTimeout(tryClose, 5000);
        }
      };

      var openApp = function () {
        window.location.replace(`${urlScheme}://${searchParams}`);
      };
      var triggerAppOpen = function () {
        openApp();
        setTimeout(fallbackToStore, 1000);
      };

      triggerAppOpen();
      return null;
    }

    const customDomain = urlSearchParams.get('domain');

    const isState = urlSearchParams.has('state');
    const state = urlSearchParams.get('state');
    if (isState) {
      const codeAuth = urlSearchParams.get('code');
      const isLineBrowser = navigator.userAgent.indexOf('Line/') !== -1;
      // line browser
      if (state && isLineBrowser) {
        let path: any = state;
        if (state.includes('/preview')) {
          path = path.substring(8) + `?code=${codeAuth}&type=line`;
        }
        window.location.href = window.location.origin + path;
        return null;
      }

      // web browser on native-app
      if (isMobile()) {
        const launchApp = () => {
          // redirect to app
          window.location.replace(`${state}://?code=${codeAuth}`);
        };
        if (state && codeAuth) launchApp();
        return null;
      }
    }

    if (!params?.appId) {
      if (customDomain) {
        return <ShareCustomDomain domain={customDomain} />;
      }
      if (subDomain && !SubDomainBlockList.includes(subDomain)) {
        return <ShareCustomDomain domain={subDomain} />;
      }
      return (
        <View>
          <Text>Router not found</Text>
        </View>
      );
    }
    return <ShareScreenView appId={params.appId} />;
  } else {
    return (
      <View>
        <Text>Cannot support for OS version</Text>
      </View>
    );
  }
};

export default ShareScreenUrl;
