import { StyleSheet, Platform } from 'react-native';
import { checkStyles } from '../func';
import { IGptConversation } from './hook';

const createStyles = ({
  attributes,
  width,
  height,
}: Pick<IGptConversation, 'attributes' | 'width' | 'height'>) => {
  const { opacity, sendButton, borderRadius, backgroundColor } = attributes;

  return StyleSheet.create({
    container: {
      width,
      height,
      opacity,
      backgroundColor,
      paddingBottom: 8,
    },

    scrollContainer: {
      height: height - 60,
      paddingTop: 24,
      borderRadius: 8,
      paddingHorizontal: 20,
    },

    buttonInputContainer: {
      marginTop: 4,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingHorizontal: 20,
      paddingVertical: 8,
    },

    input: {
      fontSize: 14,
      ...(Platform.OS === 'android' && { paddingVertical: 0 }),
      paddingHorizontal: 8,
      backgroundColor: '#fff',
      flex: 1,
      marginRight: 8,
      borderRadius,
      height: 38,
    },

    btn: {
      justifyContent: 'center',
      lineHeight: sendButton.lineHeight,
      ...checkStyles({ ...sendButton, borderRadius }),
      maxWidth: '50%',
      fontWeight: '700',
      height: 38,
    },
    btnIcon: {
      ...checkStyles({ ...sendButton, borderRadius }),
      backgroundColor: '#fff',
      height: 38,
      opacity: 1,
      margin: 0,
      marginRight: 8,
    },
    btnText: {
      lineHeight: sendButton.lineHeight,
      ...checkStyles(sendButton),
    },
  });
};

export default createStyles;
