import React, { Fragment } from 'react';
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import CloseCircleIcon from '@common/components/Icons/CloseCircleIcon';
import { useGptConversationContext } from './context';
import { FileType } from '@common/hooks/useUploadFile';

export const FILE_BACKGROUND_URL =
  'https://click-assets.s3.ap-northeast-1.amazonaws.com/file_placeholder.png';
const getFilenameDisplayed = (file: { filename: string; url: string }) => {
  try {
    if (file.filename) {
      return file.filename;
    }
    const url = new URL(file.url);
    return url.pathname.split('/').pop();
  } catch (e) {
    return 'OO';
  }
};
const FileItem = ({
  file,
  onRemoveFile,
}: {
  file: FileType;
  onRemoveFile: (() => void) | undefined;
}) => {
  const filename = getFilenameDisplayed(file);
  return (
    <View style={styles.fileItemContainer}>
      {onRemoveFile ? (
        <TouchableOpacity
          style={styles.closeIcon}
          onPress={() => {
            onRemoveFile();
          }}
        >
          <CloseCircleIcon size={20} />
        </TouchableOpacity>
      ) : (
        <Fragment />
      )}
      <View style={styles.fileGroupItem}>
        <Image
          source={{ uri: FILE_BACKGROUND_URL }}
          style={styles.fileItemImage}
        />
        <Text style={styles.fileName} numberOfLines={2}>
          {filename}
        </Text>
      </View>
    </View>
  );
};
export const FileList = ({
  files,
  showRemoveFile,
}: {
  files: FileType[];
  showRemoveFile?: boolean;
}) => {
  const { setFileSelected } = useGptConversationContext();
  return (
    <FlatList
      nativeID="files-flash-list"
      horizontal
      showsVerticalScrollIndicator={false}
      data={files}
      style={styles.fileGroupContainer}
      renderItem={({ item: file }) => {
        return (
          <FileItem
            key={file.url}
            file={file}
            onRemoveFile={
              showRemoveFile
                ? () => {
                    setFileSelected(null);
                  }
                : undefined
            }
          />
        );
      }}
    />
  );
};
const styles = StyleSheet.create({
  fileGroupContainer: {
    display: 'flex',
  },
  fileItemContainer: { position: 'relative', marginTop: 2 },
  fileGroupItem: {
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: 6,
    marginTop: 4,
    marginRight: 16,
    borderWidth: 1,
    borderColor: '#E9EFF2',
    backgroundColor: '#F2F5F7',
  },
  fileItemImage: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginHorizontal: 'auto',
    marginTop: 4,
  },
  fileName: {
    marginTop: 3,
    fontSize: 10,
    fontStyle: 'italic',
    paddingHorizontal: 2,
    color: '#242424a7',
  },
  closeIcon: { position: 'absolute', top: -2, right: 10, zIndex: 1 },
});
