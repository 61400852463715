import ChevronLeftIcon from '@common/components/Icons/ChevronLeftIcon';
import moment from 'moment';
import React from 'react';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useChatComponentContext } from '../context';
import { InputMessage } from './InputMessage';
import { MessageItem } from './MessageItem';
import { SpHeader } from './SpHeader';
import { useChatComponent } from '../hook';

export const MessageHistory = ({
  showBackButton,
}: {
  showBackButton?: boolean;
}) => {
  const {
    listRef,
    filesValue,
    unreadMessageStatus,
    selectedGroup,
    messageHistories,
    props,
    isOwnMessage,
  } = useChatComponentContext();
  const { onSelectGroup, fetchMessageHistoryNextPage } =
    useChatComponent(props);

  const groupName = selectedGroup?.groupName || '';
  const numberOfUsers = selectedGroup?.numberOfUsers || 100;
  return (
    <View
      style={[
        styles.container,
        {
          paddingBottom: filesValue?.length ? 132 : 60,
        },
      ]}
      nativeID="MessageHistory"
    >
      <View style={styles.headerContainer}>
        <TouchableOpacity
          onPress={() => {
            onSelectGroup?.(null);
          }}
          style={[
            styles.backButtonContainer,
            {
              display: showBackButton ? 'flex' : 'none',
            },
          ]}
        >
          <ChevronLeftIcon />
        </TouchableOpacity>
        <SpHeader text={groupName} numberOfUsers={numberOfUsers} />
      </View>
      <FlatList
        ref={listRef}
        style={styles.messageHistoryContainer}
        nativeID="list-message"
        onScroll={(e) => {
          const isScrollToTop = e.nativeEvent.contentOffset.y === 0;
          if (isScrollToTop) {
            fetchMessageHistoryNextPage?.();
          }
        }}
        data={messageHistories.sort((a, b) =>
          moment(a.createdAt).diff(moment(b.createdAt))
        )}
        nestedScrollEnabled
        renderItem={({ item: message, index }) => {
          const ownMessage = isOwnMessage?.({
            senderId: message.senderId,
          });
          if (ownMessage) {
            return (
              <MessageItem.OwnMessage
                message={message}
                key={message.id}
                isLastMessage={messageHistories.length === index + 1}
              />
            );
          }
          return (
            <MessageItem.ReceivedMessage message={message} key={message.id} />
          );
        }}
      />
      <InputMessage />
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    paddingHorizontalHorizontal: 4,
    borderRadius: 4,
    backgroundColor: '#F2F5F7',
    position: 'relative',
    height: '100%',
  },
  headerContainer: {
    position: 'relative',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 4,
  },
  backButtonContainer: {
    position: 'absolute',
    top: 17,
    left: 17,
    width: 24,
    height: 24,
    borderRadius: 24,
  },
  messageHistoryContainer: {
    paddingHorizontal: 4,
    paddingTop: 20,
    paddingBottom: 45,
    marginBottom: 4,
  },
});
