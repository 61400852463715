import { StyleSheet } from 'react-native';
import { checkStyles } from '../../func';
import { IGptConversation } from '../hook';
import { IMessage } from '@common/hooks/useGptConversation';

const createStyles = ({
  attributes,
  senderType,
}: Pick<IGptConversation, 'attributes' | 'width' | 'height'> & {
  senderType: IMessage['senderType'];
}) => {
  return StyleSheet.create({
    avatarBox: { width: 36, height: 36 },
    chatIcon: {
      backgroundColor:
        (senderType === 'user'
          ? attributes.user?.iconBackgroundColor
          : attributes.chatbot?.iconBackgroundColor) || '#fff',
      borderRadius: 18,
      margin: 0,
    },
    senderName: {
      fontSize: 12,
      color: '#6C777C',
      marginBottom: 4,
      ...(senderType === 'user'
        ? {
            textAlign: 'right',
          }
        : {}),
    },
    container: {
      display: 'flex',
      marginBottom: 16,
      ...(senderType === 'user'
        ? { flexDirection: 'row-reverse' }
        : { flexDirection: 'row' }),
    },
    textWrapper: {
      borderRadius: 4,
      paddingHorizontal: 12,
      paddingVertical: 8,
      backgroundColor:
        (senderType === 'user'
          ? attributes.user?.backgroundColor
          : attributes.chatbot?.backgroundColor) || '#fff',
    },
    text: {
      ...checkStyles({
        ...attributes,
        color:
          senderType === 'user'
            ? attributes.user?.color
            : attributes.chatbot?.color,
        backgroundColor: undefined,
      }),
      fontSize: 13,
      width: 'auto',
      flexWrap: 'wrap',
      lineHeight: attributes.lineHeight,
    },
    url: {
      textDecorationLine: 'underline',
    },
    file: {
      textDecorationLine: 'underline',
    },
  });
};

export default createStyles;
