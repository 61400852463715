import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import { TOKEN_KEY } from '@common/constants/shared';
import { appInfoSelector } from '@common/redux/selectors/app';
import { setError, setNotification } from '@common/redux/slice/action';
import { loginSuccess } from '@common/redux/slice/auth';
import axiosInstance from '@common/services/axiosIntance/axiosService';
import { registerFCMUser } from '@common/utils/handleActions/ActionItem/FCMregister';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type CommonScope = {
  type: string;
  uid: string;
};
export type BloctoAuthnScope = CommonScope & {
  uid: 'blocto#authn';
  scoped: {
    email: string;
  };
};
export type AccountProofScope = CommonScope & {
  type: 'account-proof';
  data: {
    address: string;
    nonce: string;
    signatures: any[];
  };
};
export type CurrentUserObject = {
  addr: string | null;
  cid: string | null;
  loggedIn: boolean | null;
  expiresAt?: number | null;
  services: Array<CommonScope>;
};
export type BloctoLoginData = {
  appId: string;
  expiresAt: CurrentUserObject['expiresAt'];
  accountProofData: AccountProofScope['data'];
  email: string;
};
type BloctoLoginResponse = {
  accessToken: string;
  userPreview: any;
};
export type AccountProofData = {
  // e.g. "Awesome App (v0.0)" - A human readable string to identify your application during signing
  appIdentifier: string;

  // e.g. "75f8587e5bd5f9dcc9909d0dae1f0ac5814458b2ae129620502cb936fde7120a" - minimum 32-byte random nonce as hex string
  nonce: string;
};

export type AccountProofDataResolver = (
  appId: string
) => Promise<AccountProofData | null>;

const accountProofDataResolver: AccountProofDataResolver = async (
  appId: string
) => {
  const resolver = await axiosInstance({
    method: 'GET',
    url: 'v3/database/authmanagements/blocto',
    params: {
      appId,
    },
  })
    .then((r) => r.data as AccountProofData)
    .catch((e) => {
      console.error(e.response);
      return null;
    });
  return resolver;
};
export const useBloctoAction = () => {
  const dispatch = useDispatch();
  const appInfo = useSelector(appInfoSelector);

  const login = useCallback(async (data: BloctoLoginData) => {
    try {
      const { accountProofData, appId, email, expiresAt } = data;
      const loginData = await axiosInstance({
        url: API_ENDPOINTS.BLOCTO_SIGNIN,
        method: 'POST',
        data: {
          accountProofData,
          expiresAt,
          email,
        },
        params: {
          appId,
        },
      }).then((r) => r.data as BloctoLoginResponse);
      await registerFCMUser(loginData.userPreview, {
        appId: data.appId,
        metadata: appInfo?.metadata,
      });
      localStorage.setItem(TOKEN_KEY, loginData.accessToken);
      dispatch(loginSuccess({ user: loginData.userPreview }));
      dispatch(setNotification('Login Success !'));
      return data;
    } catch (e: any) {
      console.error('==error login blocto', e.response.data);
      dispatch(
        setError(`Login Error!, ${e.response?.data?.message || e.message}`)
      );
      return false;
    }
  }, []);

  const getAccountResolve = accountProofDataResolver;

  return {
    login,
    getAccountResolve,
  };
};
export type BloctoActionType = ReturnType<typeof useBloctoAction>;
