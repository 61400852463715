import React from 'react';

const CopyPasteIcon = ({ className }: any) => {
  return (
    <svg
      className={className}
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.32127 5.17924V2.39344C9.32127 1.88767 8.89845 1.46484 8.39267 1.46484H1.89247C1.38669 1.46484 0.963867 1.88767 0.963867 2.39344V8.89364C0.963867 9.39942 1.38669 9.82224 1.89247 9.82224H4.67827"
          stroke="#6C777C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.107 13.5371C12.6199 13.5371 13.0356 13.1214 13.0356 12.6085V6.10829C13.0356 5.60251 12.6128 5.17969 12.107 5.17969H5.60682C5.10104 5.17969 4.67822 5.60251 4.67822 6.10829V12.6085C4.67822 13.1143 5.10104 13.5371 5.60682 13.5371H12.107Z"
          stroke="#6C777C"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyPasteIcon;
