import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type typeProps = {
  color?: string;
  size?: number;
};
const QRCodeIcon: React.FC<typeProps> = ({ color }) => {
  return (
    <Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      preserveAspectRatio="xMidYMid meet"
      fill={color || '#ffffff'}
    >
      <G fillRule="evenodd" clipRule="evenodd" stroke="none">
        <Path d="M4.5 4H10.5V10H4.5V4ZM20.5 4V10H14.5V4H20.5ZM14.5 15H16.5V13H14.5V11H16.5V13H18.5V11H20.5V13H18.5V15H20.5V18H18.5V20H16.5V18H13.5V20H11.5V16H14.5V15ZM16.5 15V18H18.5V15H16.5ZM4.5 20V14H10.5V20H4.5ZM6.5 6V8H8.5V6H6.5ZM16.5 6V8H18.5V6H16.5ZM6.5 16V18H8.5V16H6.5ZM4.5 11H6.5V13H4.5V11ZM9.5 11H13.5V15H11.5V13H9.5V11ZM11.5 6H13.5V10H11.5V6ZM2.5 2V6H0.5V2C0.5 1.46957 0.710714 0.960859 1.08579 0.585786C1.46086 0.210714 1.96957 0 2.5 0L6.5 0V2H2.5ZM22.5 0C23.0304 0 23.5391 0.210714 23.9142 0.585786C24.2893 0.960859 24.5 1.46957 24.5 2V6H22.5V2H18.5V0H22.5ZM2.5 18V22H6.5V24H2.5C1.96957 24 1.46086 23.7893 1.08579 23.4142C0.710714 23.0391 0.5 22.5304 0.5 22V18H2.5ZM22.5 22V18H24.5V22C24.5 22.5304 24.2893 23.0391 23.9142 23.4142C23.5391 23.7893 23.0304 24 22.5 24H18.5V22H22.5Z" />
      </G>
    </Svg>
  );
};

export default QRCodeIcon;
