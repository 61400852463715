import { createManifest } from '@common/screens/ShareScreen/SetupShare';
import { createAppleTouchIcon } from '@common/screens/ShareScreen/SetupShare';
import { IAppInfo } from '@common/types/element';

export async function setupManifest(appId: string, data?: IAppInfo) {
  const manifestEl = document.querySelector("link[rel='manifest']");
  const appleTouchIconEl = document.querySelector(
    "link[rel='apple-touch-icon']"
  );

  const maniFestHref = createManifest(appId, data?.name, data?.icon);
  const appleTouchIcon = createAppleTouchIcon(data?.icon);
  manifestEl?.setAttribute('href', await maniFestHref);
  appleTouchIconEl?.setAttribute('href', appleTouchIcon);
  document.title = data?.name || '';
}
