import React, { memo, useCallback } from 'react';
import { Button as RNButton } from 'react-native-paper';
import { APPS_SHARE } from '@common/constants/shareApp.constant';
import { Alert, Image, Modal, Text, TouchableOpacity } from 'react-native';
import { View } from 'react-native-animatable';
import styles from './style';
import CopyPasteIcon from '@common/components/Icons/CopyPasteIcon';
import appConfig from '../../../../appConfig.json';
import Toast from '@common/components/Toast';
import { copyToClipboard } from '../hook';
import { getMessage } from '@common/constants/locale';

interface InfoDetailsProps {
  onClose: () => void;
  appData?: { appId?: string };
  onNext?: () => void;
  locale?: string;
}

const InfoDetails: React.FC<InfoDetailsProps> = ({
  onClose,
  appData,
  onNext,
  locale = 'ja',
}) => {
  const { appId } = appData ?? { appId: null };

  const handleClickShare = useCallback((shareLink: string) => {
    window.open(shareLink, '_blank');
  }, []);

  const handleCopy = useCallback((postUrl: string, event: any) => {
    copyToClipboard(postUrl, event);
  }, []);

  const renderOptionShare = useCallback(() => {
    return (
      <View style={styles.wrapperDivider}>
        <View style={styles.labelFieldLayout}>
          <Text style={styles.label}>SNSで紹介</Text>
          <View style={styles.optionList}>
            {APPS_SHARE.map(({ icon, name, getLink }, index: number) => (
              <TouchableOpacity
                key={index}
                style={styles.optionTouch}
                onPress={() => handleClickShare(getLink())}
              >
                <View>
                  <Image
                    style={{
                      resizeMode: 'contain',
                      width: '100%',
                      height: '32px',
                      paddingLeft: 32,
                    }}
                    source={{
                      uri: icon,
                    }}
                  />
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </View>
    );
  }, [handleClickShare]);

  const renderEmbeddedOptions = useCallback(() => {
    return (
      <View style={styles.wrapperDivider}>
        <View style={styles.labelFieldLayout}>
          <Text style={styles.label}>
            {getMessage({ id: 'share.modal.embed', locale })}
          </Text>
          <View style={[styles.flexFluid]}>
            <RNButton
              onPress={onNext}
              style={{
                borderRadius: 100,
                paddingVertical: 0,
                paddingHorizontal: 24,
                backgroundColor: '#ADB6B9',
                alignSelf: 'auto',
              }}
            >
              <Text
                style={{
                  color: '#1A2737',
                  fontSize: 16,
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {getMessage({ id: 'share.modal.generateEmbedCode', locale })}
              </Text>
            </RNButton>
          </View>
        </View>
      </View>
    );
  }, []);

  const getTargetUrl = window.location.href ? window.location.href.replace("share-modal", "share-app") : '';
  const cloneUrl = `${appConfig?.clientUrl || ''}/view/${appId}`;

  return (
    <TouchableOpacity>
      <View>
        <View style={styles.wrapperDivider}>
          <Text style={styles.titleText}>
            {getMessage({ id: 'share.modal.productShare', locale })}
          </Text>
        </View>
        <Toast />
        <View style={styles.wrapperDivider}>
          <View style={styles.labelFieldLayout}>
            <Text style={styles.label}>
              {getMessage({ id: 'share.modal.publicURL', locale })}
            </Text>
            <View style={[styles.optionCopyLink]}>
              <View style={{ width: '90%', padding: 4 }}>
                <Text numberOfLines={1}>{getTargetUrl}</Text>
              </View>
              <TouchableOpacity
                style={{ marginTop: 7, right: -10 }}
                onPress={(event) => handleCopy(getTargetUrl, event)}
              >
                <CopyPasteIcon />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styles.wrapperDivider}>
          <View style={styles.labelFieldLayout}>
            <Text style={styles.label}>
              {getMessage({ id: 'share.modal.cloneURL', locale })}
            </Text>
            <View style={[styles.optionCopyLink]}>
              <View style={{ width: '90%', padding: 4 }}>
                <Text numberOfLines={1}>{cloneUrl}</Text>
              </View>
              <TouchableOpacity
                style={{ marginTop: 7, right: -10 }}
                onPress={(event) => handleCopy(cloneUrl, event)}
              >
                <CopyPasteIcon />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        {renderOptionShare()}
        {renderEmbeddedOptions()}
      </View>
    </TouchableOpacity>
  );
};

export default memo(InfoDetails);
