import React from 'react';
import { View } from 'react-native';
import { GroupMessage } from '../components/GroupList';
import { MessageHistory } from '../components/MessageHistory';
import { CanvasChatComponentProvider } from '../context';
import { isPCView, PC_WIDTH_GROUP_MESSAGE_VIEW } from '../hook';
import { chatComponentStyles } from '../style';
import { ChatComponentProps } from '../type';

const CanvasViewContent = (props: ChatComponentProps) => {
  const styled = chatComponentStyles(props);
  if (isPCView(props.width)) {
    return (
      <View style={styled.pcContainer}>
        <View
          style={{
            width: PC_WIDTH_GROUP_MESSAGE_VIEW,
          }}
        >
          <GroupMessage />
        </View>
        <View
          style={{
            width: props.width - PC_WIDTH_GROUP_MESSAGE_VIEW - 8,
          }}
        >
          <MessageHistory />
        </View>
      </View>
    );
  }
  return <GroupMessage />;
};
export const CanvasView = (props: ChatComponentProps) => {
  const styled = chatComponentStyles(props);
  return (
    <CanvasChatComponentProvider {...props}>
      <View style={styled.container} nativeID="CanvasView">
        <CanvasViewContent {...props} />
      </View>
    </CanvasChatComponentProvider>
  );
};
