import { Attributes } from '@nocode/types/horizontalCardList.type';
import { StyleSheet, Platform } from 'react-native';
import { get } from 'lodash';
import {
  checkFont,
  checkFontWeight,
  getBorderStyle,
  getBorderWidth,
  getTextDecorationLine,
} from '../func';

const hex2rgba = (hex: any, alpha = 1) => {
  if (!hex) {
    return '';
  }
  const [r, g, b] = hex?.match(/\w\w/g).map((x: any) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const PADDING_BODY = 32;
const AVATAR_MARGIN_RIGHT = 20;

const createStyles = (
  attributes: Attributes,
  cardMargin: any,
  isLast: boolean,
  index: number
) => {
  const avatarSize = attributes?.avatarImage?.size || 64;
  const cardCoverWidth =
    attributes?.cardCoverStyle?.width ?? attributes?.cardCoverStyle?.size;

  const getTextStyle = (styles: any) => {
    const {
      textDecorationUnderline,
      textDecorationLineThrough,
      fontStyleItalic,
      fontWeightBold,
      font,
      size,
      color,
    } = styles || {};
    const textStyle = getTextDecorationLine({
      textDecoration: textDecorationUnderline,
      linethrough: textDecorationLineThrough,
    });

    return {
      textDecorationLine: textStyle,
      fontStyle: (fontStyleItalic ? 'italic' : 'normal') as 'normal' | 'italic',
      fontWeight: checkFontWeight(fontWeightBold, font),
      fontSize: size,
      color: color,
      lineHeight: size * 1.15,
      ...(!fontWeightBold &&
        !fontStyleItalic && { fontFamily: checkFont(font) }),
      marginVertical: 6,
    };
  };

  const borderWidth = getBorderWidth(
    attributes?.borderWidth,
    attributes?.borderStyle
  );

  return StyleSheet.create({
    cardWrapperContent: {
      shadowColor: attributes.shadow?.color,
      shadowOffset: {
        width: attributes.shadow?.x,
        height: attributes.shadow?.y,
      },
      shadowOpacity: attributes.shadow?.blur,
      shadowRadius: attributes.shadow?.size,
    },
    cardWrapper: {
      width: cardCoverWidth + borderWidth * 2,
      borderRadius: attributes?.borderRadius,
      borderColor: attributes?.borderColor,
      borderWidth,
      borderStyle: getBorderStyle(attributes?.borderStyle),
      // backgroundColor: attributes?.backgroundColor,
      opacity: attributes?.opacity,
      overflow: 'hidden',
      shadowColor: attributes.shadow?.color,
      shadowOffset: {
        width: attributes.shadow?.x,
        height: attributes.shadow?.y,
      },
      shadowOpacity: attributes.shadow?.blur,
      shadowRadius: attributes.shadow?.size,
      marginBottom: cardMargin?.marginBottom,
      marginTop: cardMargin?.marginTop,
      marginLeft: index === 0 ? cardMargin?.marginLeft : 0,
      marginRight: isLast ? cardMargin?.marginRight : 0,
      elevation: 5,
    },

    cardImage: {
      width: '100%',
      ...(attributes.design === 'v1' && {
        height: attributes?.cardCoverStyle?.height,
      }),
      ...(attributes.design === 'v2' && {
        height: 250,
      }),
      borderRadius: attributes?.cardCoverStyle?.rounding,
      borderTopLeftRadius: attributes?.cardCoverStyle?.rounding,
      borderTopRightRadius: attributes?.cardCoverStyle?.rounding,
    },

    cardTitle: {
      ...(attributes?.title?.titleLineNum === 2 && {
        flexGrow: 1,
      }),
      ...getTextStyle(attributes?.titleStyle),
      flex: 1,
      fontWeight: '700',
    },

    cardTitleWrapper: {
      marginLeft: attributes.title.icon ? -10 : 0,
      paddingTop: 10,
      ...(attributes?.avatarImage?.enabled && { minHeight: avatarSize / 2 }),
      maxWidth:
        cardCoverWidth -
        (attributes?.avatarImage?.enabled
          ? avatarSize + AVATAR_MARGIN_RIGHT + PADDING_BODY / 2
          : PADDING_BODY),
    },

    cardSubTitle: {
      ...(attributes?.subTitle?.subtitleLineNum === 2 && {
        flexGrow: 1,
        // heigh: 24,
      }),
      ...(attributes?.subTitle?.subtitleLineNum === 1 &&
        attributes.design === 'v2' && {
          maxWidth: cardCoverWidth - PADDING_BODY,
        }),
      ...getTextStyle(attributes?.subTitleStyle),
      ...(attributes.design === 'v2' && {
        flex: 1,
      }),
    },

    cardSubTitleWrapper: {
      marginLeft: attributes.subTitle?.icon ? -10 : 0,
      ...(attributes?.subTitle?.subtitleLineNum === 1 &&
        attributes.design === 'v2' && {
          maxWidth: cardCoverWidth - PADDING_BODY,
        }),
    },

    cardOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 100,
      backgroundColor: hex2rgba(attributes?.backgroundColor, 0.5),
    },

    cardWrapperSubTitle2: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 0,
      marginLeft: attributes.subTitle2?.icon ? -10 : 0,
    },

    cardSubTitle2: {
      ...getTextStyle(attributes?.subTitle2Style),
      flex: 1,
    },

    cardWrapperTag: {
      ...(attributes.design === 'v2'
        ? {
            minHeight: 37,
            position: 'absolute',
            left: 0,
            top: -50,
            paddingRight: Platform.OS === 'web' ? 20 : 50,
            paddingLeft: attributes.tag?.icon ? 0 : 2,
          }
        : {
            paddingHorizontal: 8,
            paddingLeft: attributes.tag?.icon ? 0 : 8,
          }),
      backgroundColor: attributes.tagStyle?.backgroundColor,
      borderBottomEndRadius: attributes.tagStyle?.borderRadius,
      borderTopEndRadius: attributes.tagStyle?.borderRadius,
      maxWidth: cardCoverWidth - avatarSize - AVATAR_MARGIN_RIGHT * 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },

    cardTag: {
      // width: attributes?.cardCoverStyle?.width,
      ...getTextStyle({ ...attributes?.tagStyle, isTag: true }),
    },

    cardDetail: {
      ...getTextStyle(attributes?.detailStyle),
    },

    cardFooterWrapper: {
      borderTopWidth: 1,
      borderColor: hex2rgba(
        attributes?.footerStyle?.borderTopColor,
        attributes?.footerStyle?.borderTopOpacity
      ),
      backgroundColor: attributes?.footerStyle?.backgroundColor,
      display: 'flex',
      paddingHorizontal: attributes?.borderRadius / 2,
      ...(attributes?.footer?.footerLineNum === 1 && {
        maxWidth: cardCoverWidth,
      }),
      paddingRight: avatarSize / 4,
    },

    cardFooter: {
      ...getTextStyle(attributes?.footerStyle),
      flex: 1,
    },

    avatarWrapper: {
      position: 'absolute',
      right: AVATAR_MARGIN_RIGHT,
      top: -(avatarSize / 2),
      backgroundColor: '#eeeeee',
      borderStyle: getBorderStyle(
        attributes.avatarStyle?.borderStyle ?? 'solid'
      ),
      borderWidth: getBorderWidth(
        attributes.avatarStyle?.borderWidth ?? 1,
        attributes.avatarStyle?.borderStyle ?? 'solid'
      ),
      borderColor: attributes.avatarStyle?.borderColor ?? 'white',
      height: avatarSize,
      width: avatarSize,
      borderRadius: attributes.avatarStyle?.borderRadius ?? avatarSize,
    },

    avatar: {
      height: '100%',
      width: '100%',
      borderRadius: attributes.avatarStyle?.borderRadius ?? avatarSize,
    },

    contentWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      borderRadius: 0,
      gap: 4,
    },

    emptyWrapper: {
      height: avatarSize / 2,
    },

    cardContentWrapper: {
      flex: 1,
      ...(attributes.design === 'v1' && {
        zIndex: 200,
        bottom: 0,
        position: 'absolute',
        width: '100%',
        paddingBottom: 16,
      }),
      ...(attributes.design === 'v2' && {
        backgroundColor: attributes.backgroundColor,
      }),
      ...(attributes?.avatarImage?.enabled && {
        minHeight: avatarSize / 2 + 1,
      }),
    },
  });
};

export default createStyles;
