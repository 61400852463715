import { isEmpty } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { Platform, ScrollView, TextInput, View } from 'react-native';
import { Button as RNButton } from 'react-native-paper';

import { GptConversationServiceTypeEnum } from '@common/constants/gpt-conversation.constant';
import {
  GptConversationCanvasProvider,
  GptConversationProvider,
  useGptConversationContext,
} from './context';
import FileButton from './FileButton';
import { FileList } from './FileList';
import { IGptConversation } from './hook';
import Message from './Message';
import createStyles from './style';

const GptConversation: React.FC = () => {
  const {
    attributes: {
      sendButton: { icon },
    },
    setQuery,
    query,
    messages,
    aiRespondingText,
    onSubmit,
    aiResponding,
    messageRef,
    initializing,
    props,
    submitButtonText,
    placeholderText,
    fileSelected,
    getHistoryLoading,
    supportUploadFile,
    isCanvas,
    serviceType,
  } = useGptConversationContext();
  const styles = createStyles(props);
  const isSupportUploadFile =
    supportUploadFile &&
    serviceType === GptConversationServiceTypeEnum.openAiAssistant;

  return (
    <View style={styles.container}>
      <ScrollView
        ref={(ref) => {
          messageRef.current = ref;
        }}
        style={[styles.scrollContainer]}
      >
        {messages.map((message, index) => {
          return (
            <Message
              key={message.id}
              message={message}
              senderType={message.senderType}
              hideAvatar={
                messages[index - 1] &&
                messages[index - 1].senderType === message.senderType
              }
            />
          );
        })}
        {aiRespondingText ? (
          <Message
            message={{
              id: '',
              message: aiRespondingText || '',
              messageType: 'text',
              senderType: 'ai',
              createdAt: Math.round(Date.now() / 1000),
            }}
            senderType="ai"
          />
        ) : (
          <></>
        )}
      </ScrollView>
      <View
        nativeID="file-list"
        style={[
          {
            marginHorizontal: 20,
          },
          fileSelected ? { display: 'flex' } : { display: 'none' },
        ]}
      >
        {fileSelected && !isCanvas ? (
          <FileList files={[fileSelected]} showRemoveFile />
        ) : (
          <></>
        )}
      </View>
      <View style={[styles.buttonInputContainer]}>
        <TextInput
          value={query}
          onChangeText={(text) => {
            setQuery(text);
          }}
          placeholder={placeholderText}
          placeholderTextColor={'rgb(189, 189, 189)'}
          style={styles.input}
          onSubmitEditing={() => {
            onSubmit();
          }}
        />
        {isSupportUploadFile ? (
          fileSelected ? (
            <React.Fragment />
          ) : (
            <FileButton />
          )
        ) : (
          <React.Fragment />
        )}
        <RNButton
          onPress={() => {
            onSubmit();
          }}
          icon={icon}
          disabled={!query || aiResponding || initializing}
          style={[styles.btn]}
          loading={aiResponding || getHistoryLoading}
          labelStyle={styles.btnText}
        >
          {submitButtonText}
        </RNButton>
      </View>
    </View>
  );
};

const GptConversationWrapper = (props: IGptConversation) => {
  const search = !isEmpty(window)
    ? queryString.parse(window?.location?.search)
    : {};
  const target = search?.target;
  const isCanvas = Platform.OS === 'web' && !target;
  if (isCanvas) {
    return (
      <GptConversationCanvasProvider {...props}>
        <GptConversation />
      </GptConversationCanvasProvider>
    );
  }

  return (
    <GptConversationProvider {...props}>
      <GptConversation />
    </GptConversationProvider>
  );
};

export default GptConversationWrapper;
