import React from 'react';
import { IconButton } from 'react-native-paper';
import { useGptConversationContext } from '../context';
import createStyles from '../style';

export default function FileButton() {
  const { props, aiResponding, setFileSelected } = useGptConversationContext();
  const { selectFile } = props?.gptActions || {};
  const styles = createStyles(props);

  const chooseFile = async () => {
    if (selectFile) {
      const file = await selectFile({ imageOnly: false });
      if (file) {
        setFileSelected(file);
        return;
      }
      console.error('===error when select file', 'file is empty');
    }
    alert('Something went wrong!');
  };

  return (
    <IconButton
      onPress={chooseFile}
      icon={'attachment'}
      disabled={aiResponding}
      style={[styles.btnIcon]}
    />
  );
}
