import { appInfoSelector } from '@common/redux/selectors/app';
import { setError, setNotification } from '@common/redux/slice/action';
import { loginSuccess } from '@common/redux/slice/auth';
import store from '@common/redux/store';
import { registerFCMUser } from '@common/utils/handleActions/ActionItem/FCMregister';
import { useSelector } from 'react-redux';
import { signinTorus } from '../services';

export type LoginSuccessWithTorus = {
  publicAddress?: string | { data: string; success: true };
  privKey: string;
  userInfo: {
    email: string;
    name: string;
    idToken: string;
    typeOfLogin: string;
    verifierId: string;
  };
};

export const useTorusLogin = () => {
  const dispatch = store.dispatch;
  const appInfo = useSelector(appInfoSelector);

  const loginSuccessWithTorus = async (
    loginInfo: LoginSuccessWithTorus
  ): Promise<any> => {
    const appId = appInfo!.id;

    try {
      const data = await signinTorus(
        {
          email: loginInfo.userInfo.email,
          fullName: loginInfo.userInfo.name,
          publicAddress: loginInfo.publicAddress,
          typeOfLogin: loginInfo.userInfo.typeOfLogin,
          privKey: loginInfo.privKey,
        },
        appId
      );
      await registerFCMUser(data, appInfo!);

      dispatch(
        loginSuccess({
          user: data,
        })
      );
      dispatch(setNotification('Login Success !'));
      return data;
    } catch (e: any) {
      console.error('==error login torus', e);

      dispatch(
        setError(`Login Error!, ${e.response?.data?.message || e.message}`)
      );
      return false;
    }
  };

  return {
    loginSuccessWithTorus,
  };
};
