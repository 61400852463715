import validateColor from 'validate-color';
import { App } from 'click-types';
import { get } from 'lodash';

const dynamicColorsArr = [
  '@primaryColor',
  '@secondaryColor',
  '@backgroundColor',
  '@textColor',
];
type AppInfoColor = Pick<
  App,
  'primaryColor' | 'secondaryColor' | 'backgroundColor' | 'textColor'
>;
export const getDynamicColor = (
  colorStr: string,
  appColors?: AppInfoColor | null
) => {
  if (!appColors) {
    return colorStr;
  }
  for (const dynamicColor of dynamicColorsArr) {
    // ignore `@` character
    const appDynamicColorName = dynamicColor.substring(1);
    const appDynamicColorValue = get(appColors, appDynamicColorName);
    if (colorStr === dynamicColor && validateColor(appDynamicColorValue)) {
      return appDynamicColorValue;
    }
  }
  return colorStr;
};

const COLOR_KEYS = [
  'color',
  'backgroundColor',
  'borderColor',
  'activeColor',
  'inactiveColor',
  'placeholderColor',
  'errorColor',
  'accentColor',
  'bgGradientFirst',
  'bgGradientSecond',
  'textColor',
  'disabledTextColor',
  'iconColor',
  'iconBackgroundColor',
  'selectedColor',
  'selectedFontColor',
];
export const mapPropColor = (
  props: Record<string, any>,
  appInfo?: AppInfoColor
): any => {
  if (!props) {
    return props;
  }
  if (typeof props !== 'object') {
    return props;
  }
  const newProps: Record<string, any> = {};
  for (const key in props) {
    if (typeof props[key] === 'object' && !Array.isArray(props[key])) {
      newProps[key] = mapPropColor(props[key], appInfo);
      continue;
    }
    if (COLOR_KEYS.includes(key)) {
      newProps[key] = getDynamicColor(props[key], appInfo);
      continue;
    }
    newProps[key] = props[key];
  }
  return newProps;
};
