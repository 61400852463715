import { setLoadingPage } from '@common/redux/slice/page';
import { actionPromise } from '@common/utils/handleActions/excuteAction';
import AsyncStorage from '@react-native-async-storage/async-storage';
import qs from 'query-string';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { EmitterSubscription, Linking, Platform } from 'react-native';
import { isCanvas } from '@common/utils/screen';

import { useDispatch } from 'react-redux';
let eventDeepLinkUrl: EmitterSubscription;

type Props = { dependencies: Record<string, any> };

function useLineAction({ dependencies }: Props) {
  const { messages } = useIntl();

  const dispatch = useDispatch();

  const _getCode = (url: any) => {
    if (Platform.OS === 'web') {
      const search = window.parent.location.search || window.location.search;
      const params = qs.parse(search);

      return params?.code;
    } else {
      if (url?.url) {
        const code = url?.url?.split('code=')[1] || null;

        return code;
      }
    }
  };

  const getCode = (url: any) => {
    try {
      return _getCode(url);
    } catch (error) {
      return null;
    }
  };

  const handleUrlDeepLink = async (url: any) => {
    const code = getCode(url);
    const pendingActions = await AsyncStorage.getItem('penddingActions');
    if (!pendingActions || !code) return;
    const actions = JSON.parse(pendingActions);
    dispatch(setLoadingPage(true));
    await actionPromise({
      objectId: actions?.objectId,
      actionId: actions?.actionId,
      clientValues: dependencies,
      screenUuid: actions?.screenUuid,
      screenAction: false,
      messages,
      arrayAction: actions?.arrayAction,
      data: { code: code },
    });
    await AsyncStorage.removeItem('penddingActions');
    dispatch(setLoadingPage(false));
  };

  // handle redirect from browser to native-app on mobile
  useEffect(() => {
    Linking.getInitialURL().then((url) => {
      return handleUrlDeepLink({
        url,
      });
    });

    eventDeepLinkUrl = Linking.addEventListener('url', (e) => {
      return handleUrlDeepLink(e);
    });
    return () => {
      if (eventDeepLinkUrl) {
        eventDeepLinkUrl.remove();
      }
    };
  }, []);

  // handle redirect from browser to preview on browser
  useEffect(() => {
    if (!isCanvas()) {
      handleUrlDeepLink(null);
    }
  }, []);
}

export default useLineAction;
