import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import moment from 'moment';
import React, { Fragment } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import { FileList } from '../FileList';
import { MessageText } from './MessageText';
import { getUrlRegex } from '../../hook';
import { LinkPreview } from './LinkPreview';

type Props = {
  message: ChatComponentType.ChatMessagesTypeWithMessageJson;
  isLastMessage?: boolean;
};

const OwnMessage: React.FC<Props> = (props) => {
  const {
    createdAt,
    message: { files, text },
    senderAvatar,
  } = props.message;
  const timestamp = moment(createdAt).format('YYYY-MM-DD HH:mm');
  const urlRegex = getUrlRegex();
  const urls = (text || '')
    .split(urlRegex)
    .filter((part) => getUrlRegex().test(part));

  return (
    <View
      style={[
        styles.messageContainer,
        styles.userMessage,
        ...(props.isLastMessage ? [{ marginBottom: 24 }] : []),
      ]}
    >
      <View style={[styles.messageWrapper]}>
        <View style={[styles.messageBox, styles.userBox]}>
          {/* Message content */}
          <MessageText isOwn text={text || ''} />

          {/* File & Image preview */}
          {files?.length ? <FileList files={files} /> : <Fragment />}

          {/* Timestamp */}
          <Text style={[styles.timestamp, styles.userTimestamp]}>
            {timestamp}
          </Text>
        </View>
        {urls.length ? (
          <View nativeID="link-preview-container">
            {urls.map((url, index) => (
              <LinkPreview isOwn url={url} key={index} />
            ))}
          </View>
        ) : (
          <Fragment />
        )}
      </View>
      <Image
        source={{ uri: senderAvatar?.url }}
        style={[styles.avatar, { marginLeft: 4 }]}
      />
    </View>
  );
};
const ReceivedMessage: React.FC<Props> = (props) => {
  const {
    createdAt,
    message: { files, text },
    senderAvatar,
    senderName,
  } = props.message;
  const timestamp = moment(createdAt).format('YYYY-MM-DD HH:mm');
  const urlRegex = getUrlRegex();
  const urls = (text || '')
    .split(urlRegex)
    .filter((part) => getUrlRegex().test(part));

  return (
    <View style={[styles.messageContainer, styles.otherMessage]}>
      <Image
        source={{ uri: senderAvatar?.url }}
        style={[styles.avatar, { marginRight: 4 }]}
      />
      <View style={[styles.messageWrapper, styles.otherMessageWrapper]}>
        {/* Sender Name (only for received messages) */}
        <Text style={styles.senderName} numberOfLines={1}>
          {senderName}
        </Text>
        <View style={[styles.messageBox, styles.otherBox]}>
          {/* Message content */}
          <MessageText isOwn={false} text={text || ''} />

          {/* File & Image preview */}
          {files?.length ? <FileList files={files} /> : <Fragment />}

          {/* Timestamp */}
          <Text style={[styles.timestamp, styles.otherTimestamp]}>
            {timestamp}
          </Text>
        </View>
        {urls.length ? (
          <View nativeID="link-preview-container">
            {urls.map((url, index) => (
              <LinkPreview isOwn={false} url={url} key={index} />
            ))}
          </View>
        ) : (
          <Fragment />
        )}
      </View>
    </View>
  );
};
export const MessageItem = {
  OwnMessage,
  ReceivedMessage,
};
const styles = StyleSheet.create({
  messageContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  userMessage: {
    justifyContent: 'flex-end',
  },
  otherMessage: {
    justifyContent: 'flex-start',
  },
  avatar: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
  messageWrapper: {
    maxWidth: '70%',
  },
  otherMessageWrapper: {
    marginLeft: 4,
  },
  senderName: {
    marginBottom: 4, // Add spacing between name and message text
    fontSize: 12,
    color: '#6C777C',
  },
  messageBox: {
    padding: 8,
    borderRadius: 8,
  },
  userBox: {
    backgroundColor: '#3190E8',
  },
  otherBox: {
    backgroundColor: 'white',
  },
  timestamp: {
    fontSize: 10,
    lineHeight: 18,
    textAlign: 'right',
    marginTop: 4,
  },
  userTimestamp: {
    color: 'rgba(255, 255, 255, 0.60)',
  },
  otherTimestamp: {
    color: '#6C777C',
  },
});
