import React, {
  createContext,
  PropsWithChildren,
  Ref,
  RefObject,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { FlatList } from 'react-native';
import {
  ChatComponentActionsType,
  ChatComponentType,
} from '@common/hooks/useChatComponentActions';
import { ChatComponentProps } from './type';
import moment from 'moment';
import { CHAT_DUMMY_DATA } from './hook';
import { profileSelector } from '@common/redux/selectors/auth';
import { useAppSelector } from '@common/redux/hooks';
import { UploadFileActionType } from '@common/hooks/useUploadFile';

type VoidFunctionWithData<T> = (data: T) => void;
export type ChatComponentState = {
  groupLoading: boolean;
  setGroupLoading: React.Dispatch<React.SetStateAction<boolean>>;

  messageHistoryLoading: boolean;
  setMessageHistoryLoading: React.Dispatch<React.SetStateAction<boolean>>;

  messageSending: boolean;
  setMessageSending: React.Dispatch<React.SetStateAction<boolean>>;

  selectedGroup: ChatComponentType.GroupMessageTypeWithMessageJson | null;
  setSelectedGroup: (groupId: string | null) => void;

  groupMessages: ChatComponentType.GroupMessageTypeWithMessageJson[];
  setGroupMessages: VoidFunctionWithData<
    ChatComponentType.GroupMessageTypeWithMessageJson[]
  >;

  unreadMessageStatus: Record<string, number>;
  setUnreadMessageStatus: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;

  messageHistories: ChatComponentType.ChatMessagesTypeWithMessageJson[];
  setMessageHistories: React.Dispatch<
    React.SetStateAction<ChatComponentType.ChatMessagesTypeWithMessageJson[]>
  >;

  messageHistoriesPagination: Pick<
    ChatComponentType.PaginationResponse,
    'limit' | 'skip' | 'total'
  >;
  setMessageHistoriesPagination: React.Dispatch<
    React.SetStateAction<
      Pick<ChatComponentType.PaginationResponse, 'limit' | 'skip' | 'total'>
    >
  >;

  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  filesValue: ChatComponentType.FileType[];
  setFilesValue: React.Dispatch<
    React.SetStateAction<ChatComponentType.FileType[]>
  >;

  selectedImage: ChatComponentType.FileType | null;
  setSelectedImage: React.Dispatch<
    React.SetStateAction<ChatComponentType.FileType | null>
  >;

  listRef:
    | RefObject<FlatList<ChatComponentType.ChatMessagesTypeWithMessageJson>>
    | undefined;

  isOwnMessage: (data: { senderId: string }) => boolean;

  onDownloadFile: ChatComponentActionsType['onDownloadFile'];
  uploadFile: UploadFileActionType['uploadFile'];
  selectFile: UploadFileActionType['selectFile'];
  fileUploading: UploadFileActionType['uploading'];
  fetchLinkPreview: ChatComponentActionsType['fetchLinkPreview'];

  props: ChatComponentProps;
};

export const ChatComponentContext = createContext<ChatComponentState>(
  {} as any
);
export const useChatComponentContext = () => useContext(ChatComponentContext);

export const ChatComponentProvider: React.FC<
  PropsWithChildren<ChatComponentProps>
> = ({ children, ...props }) => {
  const profile = useAppSelector(profileSelector);

  const [groupLoading, setGroupLoading] = useState(false);
  const [messageHistoryLoading, setMessageHistoryLoading] = useState(false);
  const [messageSending, setMessageSending] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [filesValue, setFilesValue] = useState<ChatComponentType.FileType[]>(
    []
  );
  const [selectedImage, setSelectedImage] =
    useState<ChatComponentType.FileType | null>(null);
  const [selectedGroup, _setSelectedGroup] =
    useState<ChatComponentType.GroupMessageTypeWithMessageJson | null>(null);
  const [groupMessages, _setGroupMessages] = useState<
    ChatComponentType.GroupMessageTypeWithMessageJson[]
  >([]);
  const [unreadMessageStatus, setUnreadMessageStatus] = useState<
    Record<string, number>
  >({});
  const [messageHistories, setMessageHistories] = useState<
    ChatComponentType.ChatMessagesTypeWithMessageJson[]
  >([]);
  const [messageHistoriesPagination, setMessageHistoriesPagination] = useState<
    Pick<ChatComponentType.PaginationResponse, 'limit' | 'skip' | 'total'>
  >({ limit: 10, skip: 0, total: 0 });
  const listRef = useRef<FlatList>(null);

  const setSelectedGroup = (groupId: string | null) => {
    if (!groupId) {
      _setSelectedGroup(null);
      return;
    }
    const groupSelect = groupMessages.find((g) => g.id === groupId);
    if (groupSelect) {
      _setSelectedGroup(groupSelect);
      return;
    }
    _setSelectedGroup(null);
  };
  const setGroupMessages = (
    data: ChatComponentType.GroupMessageTypeWithMessageJson[]
  ) => {
    const dataSorted = data.sort((a, b) =>
      moment(b.latestMessage?.createdAt).diff(a.latestMessage?.createdAt)
    );
    _setGroupMessages((old) => {
      if (!old.length) {
        return dataSorted;
      }
      for (const oldItem of old) {
        const itemMatch = dataSorted.find((e) => e.id === oldItem.id);
        if (!itemMatch) {
          return dataSorted;
        }
        // update when new user
        if (itemMatch.numberOfUsers !== oldItem.numberOfUsers) {
          return dataSorted;
        }
        // update when new message is created
        if (itemMatch.latestMessage?.id !== oldItem.latestMessage?.id) {
          return dataSorted;
        }
      }
      return old;
    });
  };
  const isOwnMessage = useCallback(
    ({ senderId }: { senderId: string }) => {
      console.log('===senderId', senderId, profile?.id);
      return senderId === profile?.id;
    },
    [profile?.id]
  );
  return (
    <ChatComponentContext.Provider
      value={{
        groupLoading,
        setGroupLoading,
        messageHistoryLoading,
        setMessageHistoryLoading,
        selectedGroup,
        setSelectedGroup,
        groupMessages,
        setGroupMessages,
        unreadMessageStatus,
        setUnreadMessageStatus,
        messageHistories,
        setMessageHistories,
        messageSending,
        setMessageSending,
        inputValue,
        setInputValue,
        filesValue,
        setFilesValue,
        selectedImage,
        setSelectedImage,
        messageHistoriesPagination,
        setMessageHistoriesPagination,
        listRef,
        isOwnMessage,
        onDownloadFile: props.chatActions.onDownloadFile,
        selectFile: props.fileActions.selectFile,
        uploadFile: props.fileActions.uploadFile,
        fetchLinkPreview: props.chatActions.fetchLinkPreview,
        fileUploading: props.fileActions.uploading,
        props,
      }}
    >
      {children}
    </ChatComponentContext.Provider>
  );
};

export const CanvasChatComponentProvider: React.FC<
  PropsWithChildren<ChatComponentProps>
> = ({ children, ...props }) => {
  const voidFunction = (...args: any[]) => {};
  const isOwnMessage = ({ senderId }: { senderId: string }) => {
    return senderId === '1';
  };
  return (
    <ChatComponentContext.Provider
      value={{
        groupLoading: false,
        setGroupLoading: voidFunction,
        messageHistoryLoading: false,
        setMessageHistoryLoading: voidFunction,
        selectedGroup: {
          id: '1',
          numberOfUsers: 2,
          groupName: 'ユーザー名',
          latestMessage: null,
        },
        setSelectedGroup: voidFunction,
        groupMessages: CHAT_DUMMY_DATA.ChatGroup,
        setGroupMessages: voidFunction,
        unreadMessageStatus: CHAT_DUMMY_DATA.UnReadGroupStatus,
        setUnreadMessageStatus: voidFunction,
        messageHistories: CHAT_DUMMY_DATA.ChatMessages,
        setMessageHistories: voidFunction,
        messageSending: false,
        setMessageSending: voidFunction,
        inputValue: '',
        setInputValue: voidFunction,
        filesValue: [],
        setFilesValue: voidFunction,
        selectedImage: null,
        setSelectedImage: voidFunction,
        messageHistoriesPagination: { limit: 0, skip: 0, total: 0 },
        setMessageHistoriesPagination: voidFunction,
        listRef: undefined,
        isOwnMessage,
        onDownloadFile: voidFunction as any,
        selectFile: voidFunction as any,
        uploadFile: voidFunction as any,
        fetchLinkPreview: voidFunction as any,
        fileUploading: false,
        props,
      }}
    >
      {children}
    </ChatComponentContext.Provider>
  );
};
