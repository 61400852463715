import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type typeProps = {
  color?: string;
  size?: number;
};
const ImageIcon: React.FC<typeProps> = ({ color = '#ADB6B9', size = 40 }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 29 28"
      preserveAspectRatio="xMidYMid meet"
      fill={color}
    >
      <G fillRule="evenodd" clipRule="evenodd" stroke="none">
        <Path d="M7.00501 6.33594C5.84913 6.33594 4.91211 7.27295 4.91211 8.42884V13.0929V19.572C4.91211 20.7279 5.84914 21.6649 7.00501 21.6649H17.5463C17.5596 21.6653 17.5729 21.6653 17.5863 21.6649H22.3269C23.4828 21.6649 24.4198 20.7279 24.4198 19.572V16.7227C24.4202 16.71 24.4202 16.6973 24.4198 16.6847V8.42884C24.4198 7.27296 23.4828 6.33594 22.3269 6.33594H7.00501ZM23.0198 15.7717V8.42884C23.0198 8.04615 22.7096 7.73594 22.3269 7.73594H7.00501C6.62233 7.73594 6.31211 8.04615 6.31211 8.42884V12.2792C6.74609 12.2294 7.18283 12.2048 7.62012 12.2056C10.1263 12.151 12.5706 12.9875 14.5178 14.5664C15.1256 15.0592 15.6727 15.6143 16.1518 16.2198C17.4782 15.7054 18.89 15.4423 20.3149 15.4452C21.2276 15.4448 22.1357 15.5547 23.0198 15.7717ZM16.9685 17.4105C17.4905 18.2953 17.8838 19.2563 18.1315 20.2649H22.3269C22.7096 20.2649 23.0198 19.9547 23.0198 19.572V17.2194C22.1404 16.9709 21.2302 16.8447 20.3148 16.8453L20.3129 16.8452C19.1725 16.8428 18.0416 17.0345 16.9685 17.4105ZM6.31211 19.572V13.6897C6.74725 13.6325 7.18593 13.6044 7.62524 13.6056L7.64292 13.6054C9.82032 13.5562 11.9444 14.2822 13.6361 15.6539L14.0724 15.1158L13.6361 15.6539C15.1063 16.8459 16.1669 18.4582 16.6832 20.2649H7.00501C6.62232 20.2649 6.31211 19.9547 6.31211 19.572ZM18.1507 8.73407C16.8063 8.73407 15.7163 9.82399 15.7163 11.1685C15.7163 12.513 16.8063 13.6029 18.1507 13.6029C19.4952 13.6029 20.5852 12.513 20.5852 11.1685C20.5852 9.82399 19.4952 8.73407 18.1507 8.73407ZM17.1163 11.1685C17.1163 10.5972 17.5794 10.1341 18.1507 10.1341C18.722 10.1341 19.1852 10.5972 19.1852 11.1685C19.1852 11.7398 18.722 12.2029 18.1507 12.2029C17.5794 12.2029 17.1163 11.7398 17.1163 11.1685Z" />
      </G>
    </Svg>
  );
};

export default ImageIcon;
