import { get } from 'lodash';
import queryString from 'query-string';
import React, { ComponentProps } from 'react';
import { Platform, Text } from 'react-native';
import { Button as RNButton } from 'react-native-paper';
import PointIcon from '../CustomIcon';
import { checkFont } from '../func';
import { usePayPay } from './hook';
import { PayPayProps } from './type';

type ButtonProps = ComponentProps<typeof RNButton> | any;
const PayPay = (props: PayPayProps) => {
  const { attributes, height, zIndex } = props;
  const {
    fontFamily,
    opacity,
    backgroundColor,
    fontSize,
    color,
    borderRadius,
  } = attributes;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const { bindingValue, createPayment, executePaymentLoading } =
    usePayPay(props);

  const textOptions = get(bindingValue, 'submitButton.text', '').toString();
  const type = get(bindingValue, 'submitButton.type', '').toString();
  const icon = get(attributes, 'submitButton.icon', '').toString();
  const upperCase = get(bindingValue, 'submitButton.upperCase', false);
  const amount = get(bindingValue, 'paymentAmount', 0);

  const buttonDisabled = !amount;
  const childProps: ButtonProps = {
    mode: type,
    children: textOptions
      ? upperCase
        ? textOptions.toUpperCase()
        : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
      : '',
    icon: icon,
    style: {
      opacity,
      ...(type === 'contained' && {
        backgroundColor: buttonDisabled ? '#bfbfbf' : backgroundColor,
      }),
      borderRadius,
      minHeight: height,
      justifyContent: 'center',
      borderColor: type === 'contained' ? backgroundColor : color,
      zIndex: zIndex || 1,
    },
    uppercase: upperCase ? upperCase : false,
    loading: executePaymentLoading,
    labelStyle: {
      // error font family______________________
      fontFamily: checkFont(fontFamily, undefined, target),
      fontSize,
      ...(fontSize && {
        lineHeight: fontSize * 1.15,
        paddingVertical: 1.5,
      }),
      color,
      fontWeight: '700',
    },
    disabled: buttonDisabled,
    // color: type === 'contained' ? backgroundColor : color ? color : '#4259AC',
  };

  const handlePress = () => {
    createPayment();
  };

  if (icon === 'point') {
    let copyChildProps = { ...childProps };
    delete copyChildProps.icon;
    delete copyChildProps.children;

    return (
      <RNButton
        {...copyChildProps}
        onPress={handlePress}
        icon={() => <PointIcon width={24} height={24} fill={color} />}
      >
        <Text
          {...(Platform.OS !== 'web' && {
            style: {
              fontFamily: checkFont(attributes.fontFamily),
              fontWeight: '700',
              fontSize,
              color,
            },
          })}
        >
          {textOptions
            ? upperCase
              ? textOptions.toUpperCase()
              : textOptions.charAt(0).toUpperCase() + textOptions.slice(1)
            : ''}
          {amount}
        </Text>
      </RNButton>
    );
  }

  return <RNButton {...childProps} onPress={handlePress} />;
};

export default PayPay;
