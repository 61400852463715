import React, { PropsWithChildren } from 'react';
import { IPage } from '@common/types/element';

type Props = PropsWithChildren<{
  screen: IPage;
}>;

export const IapWrapper: React.FC<Props> = ({ children }) => {
  return <>{children}</>;
};
