import queryString from 'query-string';
import React, { useState } from 'react';
import { Modal, Text, TouchableOpacity, View } from 'react-native';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { getActions, getItemListClick } from '../shared';
import createStyles from './style';
import QRCodeIcon from '../Icons/QRCodeIcon';

const BarcodeScanner: React.FC<any> = (props) => {
  const search = queryString.parse(window?.location?.search);
  const localePreview = search?.locale;
  const styles = createStyles(props);
  let { locale } = props;
  locale = locale || localePreview;
  const { changeInput, valueInputs, onPress } = props;
  const [openScanner, setOpenScanner] = useState(false);
  const onPressModal = () => {
    setOpenScanner(true);
  };

  const handlePressAction = (resutlt: string, id: string) => {
    onPress &&
      onPress(getActions(props, id), {
        itemListClick: getItemListClick(props.record),
        scannerCode: resutlt,
      });
  };

  return (
    <>
      <Modal visible={openScanner} onRequestClose={() => setOpenScanner(false)}>
        <View
          style={{ width: '100%', height: '100%', backgroundColor: 'black' }}
        >
          <View style={styles.containerScan}>
            <View style={styles.scan}></View>
          </View>
          <TouchableOpacity
            onPress={() => setOpenScanner(false)}
            style={styles.back}
          >
            <Text>X</Text>
          </TouchableOpacity>
          <BarcodeScannerComponent
            delay={100}
            onError={(e: any) => {
              setOpenScanner(false);
            }}
            onUpdate={(err, result: any) => {
              if (result) {
                setOpenScanner(false);
                changeInput(result?.text);
                handlePressAction(result?.text, 'onPress');
              }
            }}
          />
        </View>
      </Modal>
      {!valueInputs ? (
        openScanner ? null : (
          <TouchableOpacity style={styles.container} onPress={onPressModal}>
            <QRCodeIcon color={props.attributes?.color} />
            <Text style={styles.text}>
              {locale === 'ja' ? 'コードを読み取る' : 'Click to scan'}
            </Text>
          </TouchableOpacity>
        )
      ) : openScanner ? null : (
        <TouchableOpacity style={styles.container} onPress={onPressModal}>
          <Text style={styles.text}> {valueInputs} </Text>
        </TouchableOpacity>
      )}
    </>
  );
};

export default React.memo(BarcodeScanner);
