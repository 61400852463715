import React from 'react';
import moment from 'moment';
import { Text, TouchableOpacity, View } from 'react-native';

const getDayColor = (
  dateString: string,
  isHoliday: boolean,
  todayTextColor: string,
  dayTextColor: string
) => {
  if (moment(dateString).isSame(Date.now(), 'day')) {
    return todayTextColor;
  }
  if (isHoliday) {
    return 'rgba(247, 0, 49, 1)';
  }
  const dayOfWeek = new Date(dateString).getDay();
  if (dayOfWeek === 0) {
    return 'rgba(247, 0, 49, 1)';
  }
  if (dayOfWeek === 6) {
    return 'rgba(1, 117, 255, 1)';
  }
  return dayTextColor || 'rgba(26, 39, 55, 1)';
};

const DayComponent = ({
  date,
  state,
  marking,
  onDayPress,
  dayTextColor,
  todayTextColor,
}: {
  date: { day: number; dateString: string };
  state: string;
  marking: any;
  onDayPress: (day: { dateString: string }) => void;
  dayTextColor: string;
  todayTextColor: string;
}) => {
  return (
    <TouchableOpacity
      style={{
        height: 29,
        width: '100%',
        opacity: state === 'disabled' ? 0.3 : 1,
      }}
      onPress={() => onDayPress(date)}
    >
      <Text
        style={{
          textAlign: 'center',
          fontWeight: '700',
          fontSize: 13,
          color: getDayColor(
            date.dateString,
            marking?.holiday,
            todayTextColor,
            dayTextColor
          ),
        }}
      >
        {date.day}
      </Text>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 8,
          marginLeft: -2,
        }}
      >
        {marking?.dots && Array.isArray(marking.dots) && (
          <>
            {marking.dots.slice(0, 3).map((item: { color: string }) => (
              <View
                style={{
                  backgroundColor: item.color,
                  height: 5,
                  width: 5,
                  borderRadius: 2.5,
                  marginLeft: 2.5,
                }}
              ></View>
            ))}
          </>
        )}
        {marking?.periods && Array.isArray(marking.periods) && (
          <>
            {marking.periods.map(
              (item: { color: any }, index: React.Key | null | undefined) => (
                <View
                  key={index}
                  style={{
                    backgroundColor: item.color,
                    height: 5,
                    width: '100%',
                  }}
                ></View>
              )
            )}
          </>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default DayComponent;
