import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import { useUploadFile } from '@common/hooks/useUploadFile';
import axiosInstance from '@common/services/axiosIntance/axiosService';
import { downloadFile } from '@common/utils/handleActions/ActionItem';
import appConfig from '../../appConfig.json';

type GetChatHistoryRequest = {
  threadId: string;
  apiKey: string | number; // App variable keys
  assistantId: string;
};

type BaseMessage = {
  id: string;
  senderType: 'user' | 'ai' | 'error';
  message: string;
  createdAt: number; // unix
};
type MessageText = BaseMessage & {
  messageType: 'text';
  attachments?: undefined;
};
type MessageFile = BaseMessage & {
  messageType: 'file';
  attachments: { fileId: string };
};
type MessageUrl = BaseMessage & {
  messageType: 'url';
  attachments: { url: string };
};
type MessageImage = BaseMessage & {
  messageType: 'image';
  attachments: { url: string };
};
export type IMessage = MessageText | MessageFile | MessageUrl | MessageImage;
type GetGptMessageHistoryResponse = IMessage[];
export const useGptConversationAction = (appId: string) => {
  const fileActions = useUploadFile();

  const getMessageHistory = async (data: GetChatHistoryRequest) => {
    try {
      const { apiKey, assistantId, threadId } = data;
      const response = await axiosInstance({
        url: API_ENDPOINTS.GPT_CONVERSATION_CHAT_HISTORY,
        method: 'POST',
        data: { apiKey, assistantId, threadId, appId },
      }).then((r) => r.data as GetGptMessageHistoryResponse);
      return response;
    } catch (e: any) {
      console.error('=== getMessageHistory error===', e);
      return [];
    }
  };
  const onDownloadFile = async ({
    apiKey,
    fileId,
  }: {
    fileId: string;
    apiKey: string;
  }) => {
    try {
      const fileDetail = await axiosInstance({
        url: `${API_ENDPOINTS.GPT_CONVERSATION_FILE}/${fileId}`,
        method: 'GET',
        params: { apiKey, appId },
      }).then(
        (r) =>
          r.data as {
            filename: string;
          }
      );
      // download file
      const fileUrl = new URL(
        `${API_ENDPOINTS.GPT_CONVERSATION_FILE_CONTENT}/${fileId}`,
        appConfig.baseUrl
      );
      fileUrl.searchParams.set('apiKey', apiKey);
      fileUrl.searchParams.set('appId', appId);
      downloadFile({
        response: {
          url: fileUrl.toString(),
          fileName: fileDetail.filename,
        },
      });
    } catch (err) {
      console.error('=== downloadFile error ===', err);
      return null;
    }
  };
  return { getMessageHistory, onDownloadFile, ...fileActions };
};
export type GptConversationActionType = ReturnType<
  typeof useGptConversationAction
>;
