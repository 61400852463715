import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getNumberOverflow, isNumberOfUserDisplayed } from '../hook';

export const SpHeader = ({
  text,
  numberOfUsers,
}: {
  text: string;
  numberOfUsers?: number;
}) => {
  const numberOfUsersStr = getNumberOverflow(numberOfUsers);
  return (
    <View style={styles.container} nativeID="SpHeader">
      <Text style={styles.headerText} numberOfLines={1}>
        {text}
      </Text>
      {isNumberOfUserDisplayed({ numberOfUsers }) ? (
        <Text style={styles.headerText}>({numberOfUsersStr})</Text>
      ) : (
        <></>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    height: 57,
    paddingHorizontal: 20,
    paddingVertical: 16,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: '#E9EFF2',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '75%',
    maxWidth: 480,
    marginHorizontal: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerText: {
    color: '#1A2737',
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 25.2,
    marginHorizontal: 4,
  },
});
