import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';

import { IVideo } from '@nocode/types';
import { useVideoHook } from './hook';
import VideoPlayerIcon from '../Icons/VideoPlayerIcon';

const isCanvas = () => {
  const isWeb = Platform.OS === 'web';

  if (!isWeb) return false;

  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  return target ? false : true;
};

type Props = IVideo;
const Video: React.FC<Props> = (props) => {
  const {
    onVideoEnded,
    onVideoPlayed,
    previewImage,
    poster,
    videoOptions,
    videoRef,
    style,
  } = useVideoHook(props);

  useEffect(() => {
    if (isCanvas() || !videoOptions.videoSource) {
      return;
    }
    if (videoRef?.current) {
      if (videoOptions.autoPlay) {
        setTimeout(() => {
          videoRef.current?.play();
        }, 100);
      }
    }
  }, []);

  useEffect(() => {
    if (isCanvas() || !videoOptions.videoSource) {
      return;
    }
    if (videoRef?.current) {
      if (!videoOptions.loop) {
        videoRef.current.addEventListener(
          'ended',
          function (e) {
            onVideoEnded();
          },
          true
        );
      }
    }
  }, []);

  useEffect(() => {
    if (isCanvas() || !videoOptions.videoSource) {
      return;
    }
    if (videoRef?.current) {
      videoRef.current.addEventListener(
        'play',
        function (e) {
          onVideoPlayed();
        },
        true
      );
    }
  }, []);

  if (isCanvas() || !videoOptions.videoSource) {
    return (
      <View
        style={{
          ...style.container,
          ...{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <VideoPlayerIcon />
      </View>
    );
  }

  return (
    <View style={style.container}>
      <video
        src={videoOptions.videoSource}
        controls={videoOptions.showVideoControl}
        autoPlay={videoOptions.autoPlay}
        loop={videoOptions.loop}
        muted={videoOptions.muted}
        ref={(e) => (videoRef.current = e)}
        style={style.video}
        poster={poster}
        webkit-playsinline="true"
        playsInline
      />
    </View>
  );
};

export default Video;
