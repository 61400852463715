import queryString from 'query-string';
import React, { useState, useRef, useEffect } from 'react';
import {
  Text,
  View,
  ImageBackground,
  Animated,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { IStackCarousel } from '@nocode/types';
import LinearGradient from 'react-native-linear-gradient';
import {
  useButtonComponentMapper,
  getInitValues,
  groupByPrefix,
  addURLImages,
  getImageSize,
  convertShortsToEmbed,
  getVideoType,
} from './hook';
import { Autoplay, Pagination } from 'swiper';
import Button from '../Button';
import createStyles from './style';
import { getActions, getItemListClick, getValueBinding } from '../shared';
import { appInfoSelector } from '@common/redux/selectors/app';
import { useSelector } from 'react-redux';
import { getDynamicColor, mapPropColor } from '@common/utils/color';

const StackCarouselLayout: React.FC<IStackCarousel> = (props) => {
  const { attributes, onPress, height, zIndex, dataBinding, width, opacity } =
    props;
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  const isCanvas = !target;
  const buttonMappedProps: any = useButtonComponentMapper(props, isCanvas);
  const styles = createStyles({ ...attributes, width });
  const slides = [...Array(4).fill(<View style={styles.containerEmpty} />)];
  const [dataLists, setDataLists] = useState([]);

  const {
    autoScrollInner,
    autoScrollMain,
    footer,
    items,
    titleColor,
    descriptionColor,
    title,
    description,
    autoAnimateCropImage,
  } = attributes;

  const innerSwiperModule: any = [];
  const parentSwiperModule: any = [];
  const pan = useState(new Animated.Value(0))[0];
  const handlePress: any = (id: 'button', item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };
    onPress && onPress(getActions(props, id), options);
  };

  if (isCanvas) {
    const [item] = items ?? [];
    return (
      <View style={{ opacity, height, zIndex, width }}>
        <Swiper
          slidesPerView={1}
          height={height}
          width={width}
          direction={'vertical'}
          loop={false}
          autoplay={false}
          onSlideChange={() => {}}
          onSwiper={(swiper: any) => {}}
        >
          <SwiperSlide>
            <div
              style={{
                width: width,
                height: height,
                position: 'relative',
              }}
            >
              <Swiper
                slidesPerView={1}
                setWrapperSize={true}
                loop={false}
                width={width}
                modules={[Pagination]}
                onSlideChange={() => {}}
                onSwiper={(swiper: any) => {}}
                pagination={{
                  el: '.swiper-pagination',
                }}
                style={{ height: 'inherit' }}
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>{slide}</SwiperSlide>
                ))}
                <div
                  className="swiper-pagination"
                  style={{ marginBottom: 50 }}
                ></div>
              </Swiper>
              {footer && footer.enabled && (
                <View style={styles.positionedBottom}>
                  <LinearGradient
                    colors={['#00000000', '#62626299']}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 0, y: 1 }}
                  >
                    <View style={styles.flexContainer}>
                      <View style={styles.flexItem}>
                        {item && item.title && (
                          <Text style={[styles.title, { color: titleColor }]}>
                            {item.title.text}
                          </Text>
                        )}
                        {item && item.description && (
                          <Text style={[{ color: descriptionColor }]}>
                            {item.description.text}
                          </Text>
                        )}
                      </View>
                      <View style={styles.flexItem}>
                        <Button {...buttonMappedProps}></Button>
                      </View>
                    </View>
                  </LinearGradient>
                </View>
              )}
            </div>
          </SwiperSlide>
        </Swiper>
      </View>
    );
  } else {
    const appInfo = useSelector(appInfoSelector);
    if (autoScrollInner && autoScrollInner.enabled) {
      innerSwiperModule.push(Autoplay);
    }
    if (autoScrollMain && autoScrollMain.enabled) {
      parentSwiperModule.push(Autoplay);
    }

    useEffect(() => {
      const dataWithImageFetch = async () => {
        const result = getInitValues(dataBinding, items);
        let modifiedMappedResult = groupByPrefix(result, 'images');
        let modifiedMappedURLResult = addURLImages(modifiedMappedResult, props);
        const selectedAttributes: any | any[] = attributes;
        /** For further investigation to use the Keys instead of manually looping it. */
        const btnAttributes = [
          'buttonColor',
          'titleColor',
          'descriptionColor',
          'buttonIconAndTextColor',
        ].reduce(
          (acc, key) => ({
            ...acc,
            [key]: getDynamicColor(selectedAttributes[key], appInfo),
          }),
          {}
        );
        const updatedItems: any = await Promise.all(
          modifiedMappedURLResult.map(async (item: any, index: any) => {
            let { images } = item;
            images = images.filter((dataImage: any) => dataImage.value != null);
            const updatedImages = await Promise.all(
              images.map(async (dataImage: any) => {
                let panning = false;
                const isVideo = getVideoType(dataImage.value.url);
                if (isVideo) {
                  if (isVideo == 'youtube') {
                    const cleanUrlShorts = convertShortsToEmbed(
                      dataImage.value.url
                    );
                    dataImage.value.url = cleanUrlShorts;
                  }
                  return { ...dataImage, ...{ panning }, ...{ isVideo } };
                } else {
                  const updatedImageData: any = await getImageSize(
                    dataImage.value.url,
                    { width, height }
                  );
                  panning =
                    updatedImageData.width > width &&
                    dataImage.imageResize == 'cover' &&
                    autoAnimateCropImage &&
                    autoAnimateCropImage.enabled;
                  return {
                    ...dataImage,
                    ...updatedImageData,
                    ...{ panning },
                    ...{ isVideo },
                  };
                }
              })
            );
            item.images = updatedImages;
            const { actions } = props;
            const keys = Object.keys(actions);
            const key = keys[0];
            const actionButton = key ? { [key]: actions[key] } : null;
            const attributes = {
              ...props.attributes,
              ...btnAttributes,
            };
            const newProps = {
              ...props,
            };
            newProps['attributes'] = attributes;
            const buttonMappedProps = useButtonComponentMapper(
              newProps,
              isCanvas,
              item,
              actionButton
            );
            buttonMappedProps['onPress'] = () => {
              handlePress('button', {
                record: item,
                indexRecord: index,
              });
            };
            item['btnProps'] = buttonMappedProps;
            return item;
          })
        );
        setDataLists(updatedItems);
      };
      dataWithImageFetch();
      startAnimation();
    }, [props]);

    const startAnimation = () => {
      pan.setValue(0);
      Animated.timing(pan, {
        toValue: -(width + width) / 2,
        duration: 10000,
        useNativeDriver: true,
      }).start();
    };

    return (
      <>
        <View style={{ opacity, height, zIndex }}>
          <Swiper
            slidesPerView={1}
            height={height}
            width={width}
            direction={'vertical'}
            modules={[Pagination, ...parentSwiperModule]}
            loop={true}
            onSlideChange={() => {
              startAnimation();
            }}
            onSwiper={(swiper: any) => {}}
            autoplay={
              autoScrollMain && autoScrollMain.enabled
                ? {
                    delay: autoScrollMain.autoScrollSpeed * 1000,
                  }
                : {}
            }
          >
            {dataLists.map((data: any, index: any) => (
              <SwiperSlide key={index}>
                <div
                  style={{
                    width: width,
                    height: height,
                    position: 'relative',
                  }}
                  onClick={() => {
                    if (footer && !footer.enabled) {
                      handlePress('button', {
                        record: data,
                        indexRecord: index,
                      });
                    }
                  }}
                >
                  <Swiper
                    slidesPerView={1}
                    setWrapperSize={true}
                    loop={true}
                    width={width}
                    modules={[Pagination, ...innerSwiperModule]}
                    onSlideChange={() => {
                      startAnimation();
                    }}
                    onSwiper={(swiper: any) => {}}
                    pagination={{
                      el: '.swiper-pagination',
                    }}
                    autoplay={
                      autoScrollInner && autoScrollInner.enabled
                        ? {
                            delay: autoScrollInner.autoScrollSpeed * 1000,
                          }
                        : {}
                    }
                    style={{ height: 'inherit' }}
                  >
                    {data.images.map((item: any, innerIndex: any) => (
                      <SwiperSlide
                        key={innerIndex}
                        style={{ overflow: 'hidden', display: 'block' }}
                      >
                        {!item.panning && !item.isVideo && (
                          <ImageBackground
                            source={{ uri: item.value.url }} // Replace with your image URL or local image
                            style={styles.background}
                            resizeMode={item.imageResize ?? 'cover'}
                          ></ImageBackground>
                        )}
                        {item.panning && !item.isVideo && (
                          <>
                            <View style={styles.fullWidth}>
                              <View style={{ flex: 1 }}>
                                <Animated.View
                                  style={[
                                    styles.animatedContainer,
                                    {
                                      transform: [{ translateX: pan }],
                                    },
                                  ]}
                                >
                                  <ImageBackground
                                    source={{
                                      uri: item.value.url,
                                    }}
                                    style={[
                                      styles.background,
                                      { width: item.value.width },
                                    ]}
                                  />
                                </Animated.View>
                              </View>
                            </View>
                          </>
                        )}

                        {item.isVideo && (
                          <View style={styles.container}>
                            {(() => {
                              {
                                switch (item.isVideo) {
                                  case 'youtube':
                                    return (
                                      <View style={styles.containerEmpty}>
                                        <iframe
                                          title="YouTube video player"
                                          src={item.value.url}
                                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen"
                                          allowFullScreen
                                          width="100%"
                                          height={height}
                                          style={{
                                            border: 'none',
                                            objectFit: 'cover',
                                            pointerEvents: 'none',
                                          }}
                                        />
                                      </View>
                                    );
                                  default:
                                    return (
                                      <video
                                        muted={true}
                                        key={item.key}
                                        width={'100%'}
                                        height={height}
                                        loop
                                        autoPlay={true}
                                        playsInline
                                        src={item.value.url}
                                      >
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    );
                                }
                              }
                            })()}
                          </View>
                        )}
                      </SwiperSlide>
                    ))}
                    <div
                      className="swiper-pagination"
                      style={{ marginBottom: 50 }}
                    ></div>
                  </Swiper>
                  {footer && footer.enabled && (
                    <View style={styles.positionedBottom}>
                      <LinearGradient
                        colors={['#00000000', '#62626299']}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0, y: 1 }}
                      >
                        <View style={styles.flexContainer}>
                          <View style={styles.flexItem}>
                            <Text style={[styles.title, { color: titleColor }]}>
                              {data['title.text'] ?? title.text}
                            </Text>
                            <Text style={[{ color: descriptionColor }]}>
                              {data['description.text'] ?? description.text}
                            </Text>
                          </View>
                          <View style={styles.flexItem}>
                            <Button {...data['btnProps']}></Button>
                          </View>
                        </View>
                      </LinearGradient>
                    </View>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </View>
      </>
    );
  }
};

const StackCarousel: React.FC<IStackCarousel> = (props) => {
  return <StackCarouselLayout {...props} />;
};

export default StackCarousel;
