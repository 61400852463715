import { IAction } from '@common/types';
import { ICardList } from '@nocode/types';
import { get, isEmpty } from 'lodash';
import queryString from 'query-string';
import * as React from 'react';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Platform } from 'react-native';
import {
  Button,
  Card,
  IconButton,
  Paragraph,
  Subheading,
  Title,
} from 'react-native-paper';
import { defaultURL } from '../../../utils/common.constant';
import { checkFont } from '../../func';
import {
  getActions,
  getImageCardCover,
  getItemListClick,
  getValueBinding,
} from '../../shared';
import createStyles from '../style';

type Props = {
  attrs: ICardList;
  index: number;
  item: any;
  onPress: (qctions: IAction[], options: Record<string, any>) => void;
  isWeb?: boolean;
};
const CardItem = (props: Props) => {
  const { attrs, index, item, onPress, isWeb } = props;
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;

  const [isLoading, setIsLoading] = React.useState(false);
  const styles = createStyles(attrs, index + 1, isLoading, Platform, isWeb);

  const {
    title = { enabled: false },
    subTitle = { enabled: false },
    subTitle2 = { enabled: false },
    cardCover,
    button1,
    icon1,
    icon2,
    opacity,
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius,
    borderStyle,
    fontFamily,
    fontSize,
    color,
  } = attrs.attributes;

  const itemMargin = attrs.attributes.gap || 2;
  const columnCount = get(attrs.attributes, 'columnCount', 2);
  const cardItemOffset = (attrs.width * 100) / columnCount / 100;

  const imageType = get(cardCover, 'image.imageType');

  const placeholderImageUrl = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImage',
    null
  );

  const placeholderImageEnabled = get(
    attrs,
    'attributes.cardCover.image.binding.options.placeholderImageEnabled',
    false
  );

  const placeholderImage =
    placeholderImageEnabled && imageType !== 'uploaded'
      ? placeholderImageUrl
      : null;

  const cardUrl = getImageCardCover(item, cardCover);

  const placeholderUrl = placeholderImageEnabled
    ? placeholderImage
    : defaultURL;

  const cardCoverImage = !isEmpty(cardUrl) ? cardUrl : placeholderUrl;

  const handlePress = (id: string, item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };

    onPress && onPress(getActions(attrs, id), options);
  };

  const bindingValue = getValueBinding('', item, attrs);

  return (
    <Card
      onPress={() => {
        handlePress('onPress', {
          record: item,
          indexRecord: index,
        });
      }}
      style={[
        {
          opacity,
          backgroundColor,
          borderColor,
          borderWidth,
          borderRadius,
          borderStyle: borderStyle !== 'none' ? borderStyle : undefined,
        },
        styles.cardWrapper,
      ]}
    >
      {isLoading ? null : Platform.OS === 'ios' || Platform.OS === 'web' ? (
        <ContentLoader
          speed={3}
          // viewBox="0 0 200 110"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          // width={195 * (attrs?.originX || 1)}
          height={115}
          style={{
            borderRadius: +attrs.attributes?.cardCover?.borderRadius || 0,
          }}
        >
          <Rect
            x="0"
            y="0"
            rx="0"
            ry="0"
            width={cardItemOffset - +itemMargin * 2}
            height={115}
          />
        </ContentLoader>
      ) : null}
      <Card.Cover
        source={{
          uri: cardCoverImage,
        }}
        style={styles.cardCover}
        onLoad={() => {
          setIsLoading(true);
        }}
      />
      <Card.Content style={styles.cardContent}>
        {title.enabled && (
          <Title
            style={{
              // fix font family in the future__________________
              fontFamily: checkFont(fontFamily),
              fontSize,
              color,
            }}
            numberOfLines={title && title.titleLineNum > 1 ? 500 : 1}
          >
            {get(bindingValue, 'title.text')}
          </Title>
        )}
        {subTitle?.enabled && (
          <Subheading
            style={{
              fontFamily: checkFont(fontFamily),
              fontSize,
              color: '#6C777C',
            }}
            numberOfLines={subTitle && subTitle.subtitleLineNum > 1 ? 500 : 1}
          >
            {get(bindingValue, 'subTitle.text')}
          </Subheading>
        )}
        {subTitle2?.enabled && (
          <Paragraph
            style={{
              fontFamily: checkFont(fontFamily),
              fontSize,
              color: '#6C777C',
            }}
            numberOfLines={
              subTitle2 && subTitle2.subtitle2LineNum > 1 ? 500 : 1
            }
          >
            {get(bindingValue, 'subTitle2.text')}
          </Paragraph>
        )}
      </Card.Content>
      {(button1?.enabled || icon1?.enabled || icon2?.enabled) && (
        <Card.Actions style={styles.cardAction}>
          {button1.enabled && (
            <Button
              labelStyle={styles.buttonLabel}
              style={styles.actionsButton1}
              mode="contained"
              onPress={() => {
                handlePress('button1', {
                  record: item,
                  indexRecord: index,
                });
              }}
            >
              {get(bindingValue, 'button1.text')}
            </Button>
          )}
          {icon1?.enabled && (
            <IconButton
              icon={icon1.icon || 'camera'}
              color={icon1.color}
              size={20}
              onPress={() => {
                handlePress('icon1', {
                  record: item,
                  indexRecord: index,
                });
              }}
            />
          )}
          {icon2?.enabled && (
            <IconButton
              icon={icon2.icon || 'camera'}
              color={icon2.color}
              size={20}
              onPress={() => {
                handlePress('icon2', {
                  record: item,
                  indexRecord: index,
                });
              }}
            />
          )}
        </Card.Actions>
      )}
    </Card>
  );
};

export default CardItem;
