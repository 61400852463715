import {
  generateFile,
  ImagePicker,
  uploadFileDocument,
} from '@common/utils/upload';
import { useState } from 'react';
import { Platform } from 'react-native';
import DocumentPicker from 'react-native-document-picker';

export type FileType = {
  url: string;
  filename: string;
  type: string;
  size?: number;
};
export const useUploadFile = () => {
  const [uploading, setUploading] = useState(false);
  const selectFile = async (options?: { imageOnly?: boolean }) => {
    const { imageOnly = false } = options || {};
    try {
      setUploading?.(true);
      if (Platform.OS !== 'web') {
        const res = await DocumentPicker.pickMultiple({
          type: [
            imageOnly
              ? DocumentPicker.types.images
              : DocumentPicker.types.allFiles,
          ],
        });
        const file: any = await generateFile(res[0].uri);

        const response = await uploadFileDocument(file);

        setUploading?.(false);
        const result: FileType = {
          filename: response.filename,
          url: response.url,
          size: response.size,
          type: response.type,
        };
        return result;
      }

      const result = await new Promise<FileType>((resolve, reject) => {
        ImagePicker.showImagePicker(
          {
            quality: 0.7,
            setPending: setUploading,
            setError: (e: any) => {
              console.error('===Upload file error', e);
            },
            imageUpload: imageOnly,
            imageCompress: imageOnly,
          },
          (response: any) => {
            if (response) {
              const result = {
                filename: response.filename,
                id: response.blobId,
                url: response.url,
                size: response.size,
                type: response.type,
              };
              return resolve(result);
            }
          }
        );
      });
      if (result) {
        setUploading?.(false);
        return result;
      }
    } catch (err) {
      console.error('===error when select file', err);
      setUploading?.(false);
    }
  };

  /**
   * @param file
   * @returns
   */
  const uploadFile = async (file: { uri: string }) => {
    try {
      setUploading?.(true);
      const fileBlob: any = await generateFile(file.uri);
      const response = await uploadFileDocument(fileBlob);
      setUploading?.(false);
      const result: FileType = {
        filename: response.filename,
        url: response.url,
        size: response.size,
        type: response.type,
      };
      return result;
    } catch (err) {
      setUploading?.(false);
    }
  };

  return { selectFile, uploadFile, uploading };
};

export type UploadFileActionType = ReturnType<typeof useUploadFile>;
