import { IAppInfo, IPage } from '@common/types/element';
import { getDynamicColor } from '@common/utils/color';
import { Platform, StatusBar as StatusBarRN, StyleSheet } from 'react-native';

const createStyle = (
  screen: IPage,
  appInfo?: Pick<
    IAppInfo,
    'primaryColor' | 'secondaryColor' | 'backgroundColor' | 'textColor'
  >
) => {
  return StyleSheet.create({
    container: {
      backgroundColor: getDynamicColor(
        screen?.attributes?.backgroundColor,
        appInfo
      ),
      flex: 1,
      overflow: 'hidden',
    },
    safeViewContainer: {
      flex: 1,
      ...(Platform.OS === 'ios'
        ? { marginTop: StatusBarRN.currentHeight }
        : {}),
    },
  });
};

export default createStyle;
