import { API_ENDPOINTS } from '@common/constants/apiEndpoint';
import axiosInstance from '@common/services/axiosIntance/axiosService';
import { downloadFile } from '@common/utils/handleActions/ActionItem';
import { Field } from 'click-types';

export namespace ChatComponentType {
  export type PaginationResponse<T = any> = {
    skip: number;
    limit: number;
    total: number;
    data: T[];
  };
  export type FileType = {
    url: string;
    filename: string;
    type: string;
    size?: number;
  };
  export type MessageType = {
    text?: string;
    files?: FileType[];
  };
  type GroupMessageItem = {
    id: string;
    message: string; // JSON format
    senderAvatar: {
      url: string;
    };
    senderId: string;
    senderName: string;
    createdAt: string | Date;
  };
  export type GroupMessageType = {
    id: string;
    groupName?: string;
    groupImage?: { url: string };
    numberOfUsers: number;
    latestMessage: GroupMessageItem | null;
  };

  export type GroupMessageTypeWithMessageJson = Omit<
    GroupMessageType,
    'latestMessage'
  > & {
    latestMessage:
      | (Omit<GroupMessageItem, 'message'> & {
          message: MessageType;
        })
      | null;
  };
  export type ChatMessagesType = {
    id: string;
    message: string; // JSON format
    senderAvatar: {
      url: string;
    };
    senderId: string;
    senderName: string;
    createdAt: string | Date;
    groupId: string;
  };
  export type ChatMessagesTypeWithMessageJson = Omit<
    ChatMessagesType,
    'message'
  > & {
    message: MessageType;
  };
  export type GroupMessageUnreadStatus = {
    groupId: string;
    unreadCount: number;
  };
  export type ChatSettingValueType = {
    usernameField: Pick<Field, 'fid' | 'name'>;
  };
  export type LinkPreviewResponse = {
    title: string;
    description: string;
    image: string;
    siteName: string;
    hostname: string;
  };
}
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(() => resolve(1), ms));

const parseMessageTextToJson = (
  message: any
): ChatComponentType.MessageType => {
  try {
    if (typeof message === 'string') {
      return JSON.parse(message);
    }
    return message as ChatComponentType.MessageType;
  } catch (e) {
    return {
      text: message.toString(),
      files: [],
    };
  }
};

export const useChatComponentActions = ({ appId }: { appId: string }) => {
  const getUnreadStatus = async (groupIds: string[]) => {
    const res = await axiosInstance({
      method: 'GET',
      url: '/chat-component/unread-status',
      params: {
        appId,
        groupIds,
      },
    }).then((r) => r.data as ChatComponentType.GroupMessageUnreadStatus[]);
    return res;
  };

  const makeReadStatus = async (data: { messageIds: string[] }) => {
    await axiosInstance({
      method: 'POST',
      url: '/chat-component/unread-status',
      params: {
        appId,
      },
      data,
    });
  };

  const getGroupMessages = async ({
    usernameFieldFid,
  }: {
    usernameFieldFid: string;
  }): Promise<ChatComponentType.GroupMessageTypeWithMessageJson[]> => {
    const res = await axiosInstance({
      method: 'GET',
      url: '/chat-component/group',
      params: {
        appId,
        usernameFieldFid,
      },
    })
      .then((r) => r.data as ChatComponentType.GroupMessageType[])
      .then((r) =>
        r.map((e) => ({
          ...e,
          numberOfUsers: +e.numberOfUsers || 0,
          latestMessage: e.latestMessage
            ? {
                ...e.latestMessage,
                message: parseMessageTextToJson(e.latestMessage?.message),
              }
            : null,
        }))
      );
    return res;
  };

  const getMessageHistories = async (data: {
    pagination: { skip: number; limit: number };
    groupId: string;
    usernameFieldFid: string;
  }): Promise<
    ChatComponentType.PaginationResponse<ChatComponentType.ChatMessagesTypeWithMessageJson>
  > => {
    const res = await axiosInstance({
      method: 'GET',
      url: '/chat-component/messages',
      params: {
        appId,
        groupId: data.groupId,
        skip: data.pagination.skip,
        limit: data.pagination.limit,
        usernameFieldFid: data.usernameFieldFid,
      },
    })
      .then(
        (r) =>
          r.data as ChatComponentType.PaginationResponse<ChatComponentType.ChatMessagesType>
      )
      .then((r) => {
        return {
          ...r,
          data: r.data.map((e) => ({
            ...e,
            message: parseMessageTextToJson(e.message),
          })),
        };
      });
    return res;
  };

  const sendMessage = async (data: {
    message: ChatComponentType.MessageType;
    groupId: string;
    usernameFieldFid: string;
  }): Promise<ChatComponentType.ChatMessagesTypeWithMessageJson> => {
    const res = await axiosInstance({
      method: 'POST',
      url: '/chat-component/messages',
      params: {
        appId,
        usernameFieldFid: data.usernameFieldFid,
      },
      data,
    })
      .then((r) => r.data as ChatComponentType.ChatMessagesType)
      .then((r) => ({ ...r, message: parseMessageTextToJson(r.message) }));
    return res;
  };

  const onDownloadFile = (
    file: Pick<ChatComponentType.FileType, 'filename' | 'url'>
  ) => {
    return downloadFile({
      response: {
        url: file.url,
        fileName: file.filename,
      },
    });
  };

  const fetchLinkPreview = async (data: { url: string }) => {
    try {
      return await axiosInstance({
        method: 'get',
        url: API_ENDPOINTS.LINK_PREVIEW,
        params: data,
      }).then((r) => r.data as ChatComponentType.LinkPreviewResponse);
    } catch (err) {
      console.error('=== fetchLinkPreview error ===', err);
      return null;
    }
  };

  return {
    getGroupMessages,
    getUnreadStatus,
    makeReadStatus,
    getMessageHistories,
    sendMessage,
    onDownloadFile,
    fetchLinkPreview,
  };
};
export type ChatComponentActionsType = ReturnType<
  typeof useChatComponentActions
>;
