import React, { createContext, PropsWithChildren, useContext } from 'react';
import {
  IGptConversation,
  UseGptConversation,
  useGptConversation,
} from './hook';

export type GptConversationState = UseGptConversation & {
  props: IGptConversation;
};

export const GptConversationContext = createContext<GptConversationState>(
  {} as any
);
export const useGptConversationContext = () =>
  useContext(GptConversationContext);

export const GptConversationProvider: React.FC<
  PropsWithChildren<IGptConversation>
> = ({ children, ...props }) => {
  const hooks = useGptConversation(props);

  return (
    <GptConversationContext.Provider
      value={{
        ...hooks,
        props,
      }}
    >
      {children}
    </GptConversationContext.Provider>
  );
};

export const GptConversationCanvasProvider: React.FC<
  PropsWithChildren<IGptConversation>
> = ({ children, ...props }) => {
  const hooks = useGptConversation(props);

  return (
    <GptConversationContext.Provider
      value={{
        ...hooks,
        props: {
          ...props,
          gptActions: undefined,
        },
      }}
    >
      {children}
    </GptConversationContext.Provider>
  );
};
