import React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type typeProps = {
  color?: string;
  size?: number;
};

const VideoPlayerIcon: React.FC<typeProps> = () => {
  return (
    <Svg
      width="30"
      height="30"
      viewBox="0 0 30 30 "
      preserveAspectRatio="xMidYMid meet"
      fill="none"
    >
      <G fillRule="evenodd" clipRule="evenodd" stroke="none">
        <Path
          d="M3.57422 1.81641H26.4258C26.4258 1.81641 28.1836 1.81641 28.1836 3.57422V26.4258C28.1836 26.4258 28.1836 28.1836 26.4258 28.1836H3.57422C3.57422 28.1836 1.81641 28.1836 1.81641 26.4258V3.57422C1.81641 3.57422 1.81641 1.81641 3.57422 1.81641Z"
          stroke="#ADB6B9"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M12.9352 19.2891C12.7822 19.3652 12.6123 19.4012 12.4416 19.3934C12.2709 19.3856 12.105 19.3343 11.9597 19.2444C11.8144 19.1544 11.6944 19.0289 11.6112 18.8796C11.5281 18.7303 11.4844 18.5623 11.4844 18.3914V11.6085C11.4844 11.4376 11.5281 11.2696 11.6112 11.1203C11.6944 10.971 11.8144 10.8455 11.9597 10.7555C12.105 10.6656 12.2709 10.6143 12.4416 10.6065C12.6123 10.5987 12.7822 10.6346 12.9352 10.7109L19.7191 14.1035C19.8858 14.1866 20.0258 14.3146 20.1238 14.4729C20.2218 14.6312 20.2737 14.8138 20.2737 15C20.2737 15.1861 20.2218 15.3687 20.1238 15.527C20.0258 15.6853 19.8858 15.8133 19.7191 15.8965L12.9352 19.2891Z"
          stroke="#ADB6B9"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M1.81641 6.21094H28.1836"
          stroke="#ADB6B9"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M1.81641 23.7891H28.1836"
          stroke="#ADB6B9"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </G>
    </Svg>
  );
};

export default VideoPlayerIcon;
