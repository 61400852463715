import React, { useState } from 'react';
import {
  View,
  TextInput,
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Keyboard,
} from 'react-native';
import { iconList } from '@common/constants/icon';
import styles from './style';

let showIconPicker: (
  event: any,
  onSelectCallback: (icon: string) => void
) => void;

const IconPicker = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [position, setPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [onSelect, setOnSelect] = useState<(icon: string) => void>(
    () => () => {}
  );
  const [isTyping, setIsTyping] = useState(false);

  const filteredIcons = iconList.filter(
    (icon) =>
      icon.name.toLowerCase().includes(search.toLowerCase()) ||
      icon.aliases.some((alias) =>
        alias.toLowerCase().includes(search.toLowerCase())
      )
  );

  showIconPicker = (event: any, onSelectCallback: (icon: string) => void) => {
    setPosition({
      top: event.nativeEvent.pageY,
      left: event.nativeEvent.pageX - ( event.nativeEvent.pageX / 2 ),
    });
    setOnSelect(() => onSelectCallback);
    setIsVisible(true);
  };

  const [searchIcon] = iconList.filter((icon) => icon.name == 'magnify');

  if (!isVisible || !position) return null;

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!isTyping) {
          setIsVisible(false);
          Keyboard.dismiss();
        }
      }}
    >
      <View style={styles.overlay}>
        <View
          style={[
            styles.popupContainer,
            { top: position.top, left: position.left },
          ]}
        >
          <View></View>
          <TextInput
            placeholder="検索"
            value={search}
            onChangeText={setSearch}
            onFocus={() => setIsTyping(true)}
            onBlur={() => setIsTyping(false)}
            style={styles.searchInput}
          />
          <View style={styles.svgIcon}>
            <svg viewBox="0 0 24 26" width="30" height="30">
              <path d={searchIcon.path} fill={'#6C777C'} />
            </svg>
          </View>

          <FlatList
            data={filteredIcons}
            keyExtractor={(item) => item.id}
            numColumns={4}
            contentContainerStyle={styles.iconGrid}
            renderItem={({ item }) => (
              <TouchableOpacity
                style={styles.iconButton}
                onPress={() => {
                  onSelect(item.name);
                  setIsVisible(false);
                }}
              >
                <svg viewBox="0 0 24 26" width="30" height="30">
                  <path d={item.path} />
                </svg>
              </TouchableOpacity>
            )}
          />
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export { IconPicker, showIconPicker };
