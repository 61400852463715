import React from 'react';
import { Linking, StyleSheet, Text, View } from 'react-native';
import { getUrlRegex } from '../../hook';

export const MessageText = ({
  text,
  isOwn,
}: {
  text: string;
  isOwn: boolean;
}) => {
  const urlRegex = getUrlRegex();
  const parts = text.split(urlRegex);

  return (
    <View>
      {parts.map((part, index) =>
        urlRegex.test(part) ? (
          <Text
            key={index}
            style={[styles.linkText, isOwn ? styles.userText : {}]}
            onPress={() => Linking.openURL(part)}
          >
            {part}
          </Text>
        ) : (
          <Text
            key={index}
            style={
              isOwn
                ? [styles.messageText, styles.userText]
                : [styles.messageText, styles.otherText]
            }
          >
            {part}
          </Text>
        )
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  messageText: {
    fontSize: 13,
  },
  userText: {
    color: 'white',
  },
  otherText: {
    color: '#1A2737',
  },
  linkText: {
    color: '#1E90FF', // Blue color for links
    textDecorationLine: 'underline',
  },
});
