import React from 'react';
// import LinearGradient from 'react-native-linear-gradient';
import { Image, Platform, Text, TouchableOpacity, View } from 'react-native';
import { Card, IconButton, Paragraph } from 'react-native-paper';

import { IHorizontalCardList } from '@nocode/types';
import { get } from 'lodash';
import { defaultURL } from '../../utils/common.constant';
import { getPlaceholder, imageUrl } from '../../utils/handleCardDetail';
import {
  getActions,
  getItemListClick,
  getValueBinding,
  isCheckColor,
} from '../shared';
import { CardDetail } from './index';
import createStyles from './style';

type Props = {
  cardItem: CardDetail;
  obj: IHorizontalCardList;
  index: number;
  heightCard: number | undefined;
  onPress?: (id: string | undefined, options: Record<string, any>) => void;
  isLast: boolean;
  cardMargin?: {
    marginBottom: number;
    marginTop: number;
    marginRight: number;
    marginLeft: number;
  };
};

const CardItem = ({
  cardItem,
  obj,
  index,
  heightCard,
  onPress,
  isLast,
  cardMargin,
}: Props) => {
  const { attributes } = obj;

  const styles = createStyles(attributes, cardMargin, isLast, index);

  const cardCoverUri = React.useMemo(
    () => imageUrl(attributes?.cardCover, cardItem),
    [attributes?.cardCover, cardItem]
  );
  const avatarUri = React.useMemo(
    () => imageUrl(attributes?.avatarImage, cardItem, 'avtImge'),
    [attributes?.avatarImage, cardItem]
  );

  const designVersion = attributes.design || 'v2';

  const [cardUrl, setCardUrl] = React.useState(cardCoverUri);
  const [avatarUrl, setAvatarUrl] = React.useState(avatarUri);

  React.useLayoutEffect(() => {
    setCardUrl(cardCoverUri);
  }, [cardCoverUri]);
  React.useLayoutEffect(() => {
    setAvatarUrl(avatarUri);
  }, [avatarUri]);

  const handlePress = (id: string, item: Record<string, any>) => {
    const options = {
      itemListClick: getItemListClick(item.record),
    };

    onPress && onPress(getActions(obj, id), options);
  };

  const isElementEmpty =
    !attributes.title.enabled && attributes.avatarImage.enabled;

  const isCanvas =
    window?.location?.href &&
    window.location.href.split('/').includes('canvas');

  const bindingValue = getValueBinding('', cardItem, obj);

  return (
    <View style={Platform.OS === 'ios' ? styles.cardWrapperContent : {}}>
      <Card
        style={[
          styles.cardWrapper,
          {
            ...(heightCard &&
              !isCanvas && {
                height:
                  heightCard -
                  (cardMargin?.marginTop ?? 0) -
                  (cardMargin?.marginBottom ?? 0),
              }),
          },
        ]}
        onPress={() => {
          handlePress('onPress', {
            record: cardItem,
            indexRecord: index,
          });
        }}
      >
        {Platform.OS === 'web' ? (
          <img
            src={
              cardUrl
                ? cardUrl
                : getPlaceholder(attributes?.cardCover) || defaultURL
            }
            alt=""
            style={{
              width: '100%',
              height: attributes?.cardCoverStyle?.height,
              borderRadius: attributes?.cardCoverStyle?.rounding,
              borderTopLeftRadius: attributes?.cardCoverStyle?.rounding,
              borderTopRightRadius: attributes?.cardCoverStyle?.rounding,
              objectFit: attributes?.cardCover?.image?.imageResize || 'cover',
            }}
            onError={() => {
              setCardUrl(getPlaceholder(attributes?.cardCover) || defaultURL);
            }}
          />
        ) : (
          <Image
            style={styles.cardImage}
            source={{
              uri: cardUrl
                ? cardUrl
                : getPlaceholder(attributes?.cardCover) || defaultURL,
            }}
            onError={() => {
              setCardUrl(getPlaceholder(attributes?.cardCover) || defaultURL);
            }}
            resizeMode={attributes?.cardCover?.image?.imageResize || 'cover'}
          />
        )}

        {/* {designVersion === 'v1' ? (
          <LinearGradient
            colors={['rgba(61, 62, 62, 0)', 'rgba(18, 21, 21, 0.8)']}
            style={styles.cardOverlay}
          ></LinearGradient>
        ) : null} */}

        <Card.Content style={styles.cardContentWrapper}>
          {designVersion === 'v1' ? (
            <View style={styles.contentWrapper}>
              {attributes?.tag?.enabled && (
                <TouchableOpacity style={styles.cardWrapperTag}>
                  {!!attributes.tag.icon && (
                    <IconButton
                      size={12}
                      icon={attributes.tag.icon}
                      color={
                        isCheckColor(attributes.tagStyle.iconColor)
                          ? attributes.tagStyle.iconColor
                          : '#000000'
                      }
                      style={{ borderRadius: 2 }}
                    />
                  )}

                  <Paragraph
                    style={styles.cardTag}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {get(bindingValue, 'tag.text')}
                  </Paragraph>
                </TouchableOpacity>
              )}
              {attributes?.detail?.enabled && (
                <Text style={styles.cardDetail}>
                  {get(bindingValue, 'detail.text')}
                </Text>
              )}
            </View>
          ) : null}
          {attributes?.title?.enabled && (
            <View style={[styles.contentWrapper, styles.cardTitleWrapper]}>
              {!!attributes.title.icon && (
                <IconButton
                  size={24}
                  icon={attributes.title.icon}
                  color={attributes.titleStyle.iconColor}
                />
              )}
              <Text
                style={styles.cardTitle}
                ellipsizeMode="tail"
                numberOfLines={attributes?.title?.titleLineNum === 1 ? 1 : 2}
              >
                {get(bindingValue, 'title.text')}
              </Text>
            </View>
          )}
          {isElementEmpty && <View style={styles.emptyWrapper} />}

          {attributes?.subTitle?.enabled && designVersion === 'v2' && (
            <View style={[styles.contentWrapper, styles.cardSubTitleWrapper]}>
              {!!attributes.subTitle.icon && (
                <IconButton
                  size={12}
                  icon={attributes.subTitle.icon}
                  color={attributes.subTitleStyle.iconColor}
                />
              )}
              <Text
                style={styles.cardSubTitle}
                ellipsizeMode="tail"
                numberOfLines={
                  attributes?.subTitle?.subtitleLineNum === 1 ? 1 : 2
                }
              >
                {get(bindingValue, 'subTitle.text')}
              </Text>
            </View>
          )}

          {attributes.subTitle2?.enabled && designVersion === 'v2' && (
            <TouchableOpacity style={styles.cardWrapperSubTitle2}>
              {!!attributes.subTitle2.icon && (
                <IconButton
                  size={12}
                  icon={attributes.subTitle2.icon}
                  color={
                    isCheckColor(attributes.subTitle2Style.iconColor)
                      ? attributes.subTitle2Style.iconColor
                      : '#000000'
                  }
                />
              )}

              <Text
                style={styles.cardSubTitle2}
                ellipsizeMode="tail"
                numberOfLines={1}
              >
                {get(bindingValue, 'subTitle2.text')}
              </Text>
            </TouchableOpacity>
          )}

          {attributes?.avatarImage?.enabled && designVersion === 'v2' && (
            <View style={styles.avatarWrapper}>
              <Image
                style={styles.avatar}
                source={{
                  uri: avatarUrl
                    ? avatarUri
                    : getPlaceholder(attributes?.avatarImage) || defaultURL,
                }}
                onError={() => {
                  setAvatarUrl(
                    getPlaceholder(attributes?.avatarImage) || defaultURL
                  );
                }}
                resizeMode={
                  attributes?.avatarImage?.image?.imageResize || 'cover'
                }
              />
            </View>
          )}

          {attributes?.tag?.enabled && designVersion === 'v2' && (
            <TouchableOpacity style={styles.cardWrapperTag}>
              {!!attributes.tag.icon && (
                <IconButton
                  size={12}
                  icon={attributes.tag.icon}
                  color={
                    isCheckColor(attributes.tagStyle.iconColor)
                      ? attributes.tagStyle.iconColor
                      : '#000000'
                  }
                />
              )}

              <Paragraph
                style={styles.cardTag}
                numberOfLines={1}
                ellipsizeMode="tail"
              >
                {get(bindingValue, 'tag.text')}
              </Paragraph>
            </TouchableOpacity>
          )}

          {designVersion === 'v1' ? (
            <View style={styles.contentWrapper}>
              {attributes?.subTitle?.enabled && (
                <Text
                  style={styles.cardSubTitle}
                  ellipsizeMode="tail"
                  numberOfLines={
                    attributes?.subTitle?.subtitleLineNum === 1 ? 1 : 2
                  }
                >
                  {get(bindingValue, 'subTitle.text')}
                </Text>
              )}

              {attributes?.subTitle2?.enabled && (
                <Text
                  style={styles.cardSubTitle2}
                  ellipsizeMode="tail"
                  numberOfLines={1}
                >
                  | {get(bindingValue, 'subTitle2.text')}
                </Text>
              )}
            </View>
          ) : null}
        </Card.Content>

        {attributes?.footer?.enabled && designVersion === 'v2' && (
          <Card.Actions style={styles.cardFooterWrapper}>
            {!!attributes.footer.icon && (
              <IconButton
                style={{ marginLeft: 0 }}
                size={12}
                icon={attributes.footer.icon}
                color={attributes.footerStyle.iconColor}
              />
            )}
            <Text
              style={styles.cardFooter}
              ellipsizeMode="tail"
              numberOfLines={attributes?.footer?.footerLineNum}
            >
              {get(bindingValue, 'footer.text')}
            </Text>
          </Card.Actions>
        )}
      </Card>
    </View>
  );
};

export default CardItem;
