import React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowBackIcon = () => {
  return (
    <Svg width={8} height={16} viewBox="0 0 8 16" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66806 0.314849C6.92273 0.0347127 7.35628 0.0140675 7.63641 0.268737C7.91647 0.523332 7.93719 0.956719 7.68271 1.23688L2.01967 7.47143C1.84648 7.6621 1.84648 7.95313 2.01967 8.1438L7.68271 14.3784C7.93719 14.6585 7.91647 15.0919 7.63641 15.3465C7.35627 15.6012 6.92273 15.5805 6.66806 15.3004L0.467974 8.48029C0.121227 8.09887 0.121228 7.51636 0.467975 7.13494L6.66806 0.314849Z"
        fill="#6C777C"
      />
    </Svg>
  );
};

export default ArrowBackIcon;
