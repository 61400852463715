import { DefaultTheme } from 'react-native-paper';

export const theme = {
  ...DefaultTheme,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    primary: 'rgb(1, 117, 255)',
    secondary: 'rgba(49, 189, 255, 1)',
    tertiary: 'rgb(229, 239, 255)',
    backgroundPrimary: 'rgb(242, 245, 247)',
    backgroundSecondary: 'rgb(228, 234, 238)',
    text: 'rgba(26, 39, 55, 1)',
  },
};

export const FONT_SIZES = {
  base: 12,
};
