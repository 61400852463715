import { ChatComponentType } from '@common/hooks/useChatComponentActions';
import { imageTypeValid } from '@common/utils';
import React, { Fragment } from 'react';
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { FILE_BACKGROUND_URL } from '../hook';
import CloseCircleIcon from '@common/components/Icons/CloseCircleIcon';
import { useChatComponentContext } from '../context';

const getFilenameDisplayed = (file: { filename: string; url: string }) => {
  try {
    if (file.filename) {
      return file.filename;
    }
    const url = new URL(file.url);
    return url.pathname.split('/').pop();
  } catch (e) {
    return 'OO';
  }
};
const isImage = (fileType: string) => {
  return imageTypeValid.includes(fileType);
};
const FileItem = ({
  file,
  onRemoveFile,
  onDownloadFile,
}: {
  file: ChatComponentType.FileType;
  onRemoveFile: (() => void) | undefined;
  onDownloadFile: () => void;
}) => {
  const filename = getFilenameDisplayed(file);
  return (
    <View style={styles.fileItemContainer}>
      {onRemoveFile ? (
        <TouchableOpacity
          style={styles.closeIcon}
          onPress={() => {
            onRemoveFile();
          }}
        >
          <CloseCircleIcon size={20} />
        </TouchableOpacity>
      ) : (
        <Fragment />
      )}
      <TouchableOpacity
        onPress={async () => {
          onDownloadFile();
        }}
      >
        <View style={styles.fileGroupItem}>
          {isImage(file.type) ? (
            <Image
              source={{ uri: file.url }}
              style={styles.imageItem}
              resizeMode="contain"
            />
          ) : (
            <>
              <Image
                source={{ uri: FILE_BACKGROUND_URL }}
                style={styles.fileItemImage}
              />
              <Text style={styles.fileName} numberOfLines={2}>
                {filename}
              </Text>
            </>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};
export const FileList = ({
  files,
  showRemoveFile,
}: {
  files: ChatComponentType.FileType[];
  showRemoveFile?: boolean;
}) => {
  const { setSelectedImage, onDownloadFile, setFilesValue } =
    useChatComponentContext();
  return (
    <FlatList
      nativeID="files-flash-list"
      horizontal
      showsVerticalScrollIndicator={false}
      data={files}
      style={styles.fileGroupContainer}
      renderItem={({ item: file }) => {
        return (
          <FileItem
            key={file.url}
            file={file}
            onRemoveFile={
              showRemoveFile
                ? () => {
                    setFilesValue?.((old) =>
                      old.filter((f) => f.url !== file.url)
                    );
                  }
                : undefined
            }
            onDownloadFile={() => {
              if (isImage(file.type)) {
                setSelectedImage?.(file);
                return;
              }
              onDownloadFile?.(file);
            }}
          />
        );
      }}
    />
  );
};
const styles = StyleSheet.create({
  fileGroupContainer: {
    display: 'flex',
  },
  fileItemContainer: { position: 'relative', marginTop: 2 },
  fileGroupItem: {
    width: 60,
    height: 60,
    display: 'flex',
    borderRadius: 6,
    marginTop: 4,
    marginRight: 16,
    borderWidth: 1,
    borderColor: '#E9EFF2',
  },
  imageItem: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F2F5F7',
    borderRadius: 4,
  },
  fileItemImage: {
    width: 20,
    height: 20,
    borderRadius: 4,
    marginHorizontal: 'auto',
    marginTop: 4,
  },
  fileName: {
    marginTop: 3,
    fontSize: 10,
    fontStyle: 'italic',
    paddingHorizontal: 2,
    color: '#242424a7',
  },
  closeIcon: { position: 'absolute', top: -2, right: 10, zIndex: 1 },
});
