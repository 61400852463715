import { MarketplaceComponentConfig } from '@nocode/types';

const config: MarketplaceComponentConfig = {
  displayName: {
    en: 'Chat',
    ja: 'チャット',
  },
  name: 'ChatComponent',
  resizeX: true,
  resizeY: true,
  props: [
    {
      name: 'chatSetting',
      type: 'string',
      displayName: {
        en: 'Chat setting',
        ja: 'Chat setting',
      },
      default: {
        usernameField: {
          fid: 'username',
        },
      },
    },
    {
      name: 'value',
      type: 'string',
      role: 'formValue',
      displayName: 'New Message',
    },
    {
      name: 'action',
      type: 'action',
      displayName: {
        en: 'On Message sent',
        ja: 'メッセージ送信時',
      },
    },
    // TODO: uncomment this when we have a way to constrain layout
    // {
    //   name: 'constrainLayout',
    //   type: 'constrainLayout',
    //   displayName: {
    //     en: 'Constrain Layout',
    //     ja: 'Constrain Layout',
    //   },
    // },
  ],
  childComponents: [
    {
      displayName: {
        en: 'Push notification after send message',
        ja: 'メッセージ送信時のPush通知',
      },
      name: 'sendNotification',
      props: [
        {
          name: 'enabled',
          type: 'boolean',
          default: false,
        },
        {
          name: 'title',
          type: 'text',
          displayName: {
            en: 'Title',
            ja: 'タイトル',
          },
        },
      ],
    },
  ],
};

export default config;
