import { IAppBar } from '@nocode/types';
import React, { FC } from 'react';
import { Dimensions, Platform, View } from 'react-native';
import { Appbar } from 'react-native-paper';
import validateColor from 'validate-color';
import PointIcon from '../CustomIcon';
import { checkFont } from '../func';
import { getActions } from '../shared';

const iconAppBarWidth = 48;
const getColor = (color: string) => {
  return validateColor(color) ? color : '#4259AC';
};

const AppBar: FC<IAppBar> = (props) => {
  const { y, attributes, onPress, actions } = props;
  const {
    leftIcon,
    title,
    rightIcon1,
    rightIcon2,
    backgroundColor: _backgroundColor,
    color: _color,
    opacity,
    fontFamily,
    fontSize,
    borderColor: _borderColor,
    borderStyle,
    borderWidth,
    zIndex,
  } = attributes;

  const color = getColor(_color),
    backgroundColor = getColor(_backgroundColor),
    borderColor = getColor(_borderColor);

  const { width } = Dimensions.get('window');

  const handlePress = (id: string) => {
    const arrayAction = getActions(props, id);
    onPress && onPress(arrayAction, {});
  };

  const originX = attributes.originX < 1 ? attributes.originX : 1;
  const checkState = () => {
    return (
      <Appbar.Action
        style={{ display: 'flex', alignItems: 'center' }}
        color={color}
        icon={''}
        animated={false}
      />
    );
  };

  return (
    <View
      style={{
        opacity,
      }}
    >
      <Appbar.Header
        style={{
          paddingTop: Platform.OS === 'web' && y === 0 ? 20 * originX : 0,
          height: props.height,
          backgroundColor: backgroundColor,
          ...Platform.select({
            web: {
              borderStyle: borderStyle === 'none' ? 'solid' : borderStyle,
              borderBottomColor: borderColor,
              borderBottomWidth: borderWidth,
            },
            ios: {
              borderRadius: 1,
            },
            android: {
              borderRadius: 1,
            },
          }),
          zIndex: zIndex,
        }}
      >
        {leftIcon && leftIcon?.enabled ? (
          <Appbar.Action
            color={color}
            icon={
              leftIcon?.icon === 'point'
                ? () => <PointIcon width={24} height={24} fill={'#fff'} />
                : leftIcon?.icon
            }
            onPress={() => {
              handlePress('leftIcon');
            }}
            animated={false}
          />
        ) : (
          <View style={{ width: iconAppBarWidth }} />
        )}

        {rightIcon1?.enabled && checkState()}

        <Appbar.Content
          titleStyle={{
            fontSize: +fontSize * originX,
            fontFamily: checkFont(fontFamily),
            // textAlign: 'center',
            // flex: 1,
            alignSelf: 'center',
          }}
          title={title?.text}
          color={color}
        />

        {rightIcon1?.enabled && !rightIcon2?.enabled ? checkState() : null}

        {rightIcon1 && rightIcon1.enabled && (
          <Appbar.Action
            color={color}
            icon={
              rightIcon1.icon === 'point'
                ? () => <PointIcon width={24} height={24} fill={'#fff'} />
                : rightIcon1.icon
            }
            onPress={() => {
              handlePress('rightIcon1');
            }}
            animated={false}
          />
        )}

        {rightIcon2 && rightIcon2.enabled && (
          <Appbar.Action
            color={color}
            icon={
              rightIcon2.icon === 'point'
                ? () => <PointIcon width={24} height={24} fill={'#fff'} />
                : rightIcon2.icon
            }
            onPress={() => {
              handlePress('rightIcon2');
            }}
            animated={false}
          />
        )}
        {!rightIcon1?.enabled && !rightIcon2?.enabled ? checkState() : null}
        {Platform.OS !== 'web' && borderWidth > 0 && borderStyle !== 'none' && (
          <View
            style={{
              position: 'absolute',
              height: borderWidth,
              bottom: 0,
              overflow: 'hidden',
              width,
            }}
          >
            <View
              style={{
                ...Platform.select({
                  ios: {
                    borderRadius: 0,
                  },
                  android: {
                    borderRadius: borderStyle === 'solid' ? 0 : 0.001,
                  },
                  default: {
                    borderRadius: 0,
                  },
                }),
                borderColor: borderColor,
                borderStyle: borderStyle === 'none' ? 'solid' : borderStyle,
                borderWidth: borderWidth,
              }}
            ></View>
          </View>
        )}
      </Appbar.Header>
    </View>
  );
};

export default AppBar;
