import { ICardList } from '@nocode/types';
import { StyleSheet } from 'react-native';
import { get } from 'lodash';

const createStyles = (
  attrs: ICardList,
  index: number,
  isLoading: boolean,
  Platform?: any,
  isWeb?: boolean
) => {
  const { button1 } = attrs.attributes;

  const columnCount = get(attrs.attributes, 'columnCount', 2);

  const cardItemOffset = (attrs.width * 100) / columnCount / 100;

  const itemMargin = attrs.attributes.gap || 0;

  return StyleSheet.create({
    cardWrapper: {
      width: cardItemOffset - +itemMargin * 2 - (isWeb ? 10 : 0),
      // height: 'fit-content',
      margin: +itemMargin / 2,
      shadowColor: attrs.attributes.shadow?.color,
      shadowOffset: {
        width: attrs.attributes.shadow?.x,
        height: attrs.attributes.shadow?.y,
      },
      shadowOpacity: attrs.attributes.shadow?.blur,
      shadowRadius: attrs.attributes.shadow?.size,
      overflow: 'hidden',
      elevation: 5,
    },
    cardCover: {
      borderRadius: +attrs?.attributes?.cardCover?.borderRadius || 0,
      height: isLoading ? 115 : 0,
      display: isLoading || Platform.OS !== 'web' ? 'flex' : 'none',
    },
    cardContent: {
      padding: 16,
    },
    cardAction: {
      width: '100%',
      justifyContent: 'space-between',
    },
    actionsButton1: {
      borderWidth: button1?.border ? button1.borderSize : 0,
      borderColor: 'red',
      borderRadius: button1?.rounding,
      backgroundColor: button1?.backgroundColor || '#4259AC',
    },
    buttonLabel: {
      fontSize: 10,
    },
  });
};

export default createStyles;
