import { HOME_URL, SubDomainBlockList } from '@common/constants/shared';
import { useParams } from '@common/routes/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  SafeAreaView,
  View,
  Text,
  Platform,
  NativeModules,
  Dimensions,
} from 'react-native';
import IFrameShare from '@common/components/iframeshare';
import styles from './style';
import { WebView } from 'react-native-webview';
import { createManifest, createAppleTouchIcon } from './SetupShare';
import useAppDetail from '@common/hooks/app';
import { useSelector } from 'react-redux';
import { dimensionSelector } from '@common/redux/selectors/page';
import ShareCustomDomain from './ShareScreenCustomDomain';
import {
  checkMobileLayout,
  checkShareMobileLayout,
  copyToClipboard,
} from '@common/utils/screen';
import useSocketConnection from '@common/hooks/useSocketAuthentication';
import Loading from '@common/components/Loading';
import RenderShareAppDetail from '@common/components/RenderShareAppDetail';
import { iconList } from '@common/constants/icon';

const ShareScreenView = ({ appId }: any) => {
  const { isLoading, data } = useAppDetail({
    appId: appId,
    isShareScreen: true,
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [currentIFrameWidth, setCurrentIFrameWidth] = useState(260);
  const [chatSettings, setChatSettings] = useState<ChatSettings>({
    enabled: false,
    emoticon: 'message',
    imageSource: '',
    iconType: 'icon',
    enabledTextDisplay: false,
    textDisplay: '',
  });
  const [svgPath, setSvgPath] = useState<any>('');

  const device = 'iphonePro';
  const dimension = useSelector(dimensionSelector);
  const isMobile = checkShareMobileLayout(dimension);
  const [locale, setLocale] = useState('ja');
  const windowHeight = Dimensions.get('window').height;
  const yPad = 20;
  const screenHeight = Dimensions.get('screen').height;
  const scaleValue = (windowHeight - 2 * 30) / (screenHeight + 2 * yPad);

  useEffect(() => {
    async function setupManifest() {
      const manifestEl = document.querySelector("link[rel='manifest']");
      const appleTouchIconEl = document.querySelector(
        "link[rel='apple-touch-icon']"
      );

      const maniFestHref = createManifest(appId, data?.name, data?.icon);
      const appleTouchIcon = createAppleTouchIcon(data?.icon);
      manifestEl?.setAttribute('href', await maniFestHref);
      appleTouchIconEl?.setAttribute('href', appleTouchIcon);
      document.title = data?.name || '';
    }
    setupManifest();
  }, [appId, data]);

  useEffect(() => {
    const [searchIcon] = iconList.filter(
      (icon) => icon.name == chatSettings.emoticon
    );
    setSvgPath(searchIcon.path);
  }, [chatSettings]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('locale')) {
      const locale = urlSearchParams.get('locale') ?? 'ja';
      setLocale(locale);
    }
  }, []);

  if (isMobile) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let newUri = `/preview/${appId}?hiddenStatusBar=true`;
    if (urlSearchParams.has('locale')) {
      const locale = urlSearchParams.get('locale');
      newUri = newUri + `&locale=${locale}` + `&pwa=true`;
    }
    if (urlSearchParams.has('code') && urlSearchParams.has('type')) {
      const code = urlSearchParams.get('code');
      const type = urlSearchParams.get('type');
      newUri = newUri + `&code=${code}&type=${type}`;
    }
    if (urlSearchParams.has('masterId')) {
      const masterId = urlSearchParams.get('masterId');
      newUri = newUri + `&masterId=${masterId}`;
    }
    if (urlSearchParams.has('login_url')) {
      const login_url = urlSearchParams.get('login_url') ?? '';
      newUri = newUri + `&login_url=${encodeURIComponent(login_url)}`;
    }

    return <WebView source={{ uri: newUri }} />;
  }
  const scale = (dimension.height - 2 * 30) / (812 + 2 * 20);

  let newUri = `/preview/${appId}`;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const isTutorial = urlSearchParams.get('tutorial');

  if (urlSearchParams.has('locale')) {
    const locale = urlSearchParams.get('locale');
    newUri = `/preview/${appId}?locale=${locale}`;
  }

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleIframeWidth = (data: any) => {
    setCurrentIFrameWidth(data);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  const updateChatSetting = (key: any, value: any) => {
    setChatSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  return !data ? null : data.platform === 'web' ? (
    <View>
      <WebView
        source={{ uri: newUri }}
        style={{
          width: '100vw',
          height: '100vh',
        }}
        scalesPageToFit={true}
      />
    </View>
  ) : (
    <SafeAreaView style={styles.container}>
      {!isTutorial ? (
        <>
          <View style={[styles.child, styles.overflow]}>
            <View style={styles.space}></View>
            <RenderShareAppDetail
              appId={appId}
              data={data}
              isLoading={isLoading}
              onNext={handleNextStep}
              onBack={handlePreviousStep}
              currentStep={currentStep}
              chatSettings={chatSettings}
              locale={locale}
              updateChatSetting={updateChatSetting}
            />
          </View>
          <View style={styles.rightChild}>
            <View>
              <View
                style={{
                  width: 375,
                  height: 812,
                  transform: [{ scale: scaleValue }],
                  alignSelf: 'center', // Keep it centered
                  marginTop: '-80px',
                }}
              >
                <IFrameShare
                  device={device}
                  appId={appId}
                  handleIframeWidth={handleIframeWidth}
                />
              </View>
            </View>
            {chatSettings && chatSettings.enabled && (
              <View
                style={{
                  width: currentIFrameWidth,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    width: 'auto',
                    height: 56,
                    backgroundColor: '#0175FF',
                    borderRadius: 100,
                    bottom: 0,
                    padding: 14,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    {svgPath && chatSettings.iconType == 'icon' && (
                      <svg viewBox="0 0 24 26" width="30" height="30">
                        <path d={svgPath} fill={'white'} />
                      </svg>
                    )}
                    {chatSettings.iconType == 'image' && (
                      <View
                        style={{
                          width: 30,
                          height: 30,
                        }}
                      ></View>
                    )}
                    {chatSettings.enabledTextDisplay &&
                      chatSettings.textDisplay != '' && (
                        <Text
                          style={{
                            color: 'white',
                            marginLeft: 10,
                            fontWeight: '700',
                            fontSize: 18,
                            lineHeight: 32.4,
                            letterSpacing: 0,
                          }}
                        >
                          {chatSettings.textDisplay}
                        </Text>
                      )}
                  </View>
                </View>
              </View>
            )}
          </View>
        </>
      ) : (
        <View></View>
      )}
    </SafeAreaView>
  );
};

const ShareScreenModal = () => {
  const currentHostName = window.location.hostname;
  const regexHost = /(\.click\.dev|\.gotravel\.city)$/;
  let subDomain =
    regexHost.test(currentHostName) && currentHostName.replace(regexHost, '');
  // browser mobile
  const isMobile = () => {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      return false;
    }
  };
  const params = useParams();

  if (params.appId) {
    const { loading: reAuthLoading } = useSocketConnection();
    if (reAuthLoading) {
      return <Loading />;
    }
  }

  if (Platform.OS == 'web') {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const urlScheme = urlSearchParams.get('app');

    if (urlScheme) {
      // redirect to app for share SNS function
      const iosAppId = urlSearchParams.get('iosAppId');
      const iosAppName = urlSearchParams.get('iosAppName');
      const searchParams = window.location.search;

      const locale =
        NativeModules?.SettingsManager?.settings?.AppleLocale ||
        NativeModules?.SettingsManager?.settings?.AppleLanguages?.[0];

      const localeArr = typeof locale === 'string' ? locale.split('_') : [];

      const country = localeArr.length
        ? localeArr[localeArr.length - 1].toLowerCase()
        : null;

      var tryClose = function () {
        location.replace('about:blank');
      };
      var start = Date.now();
      var fallbackToStore = function () {
        var now = Date.now();
        if (now - start > 3000) {
          tryClose();
        } else {
          if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            window.location.replace(`market://details?id=${urlScheme}`);
          }
          if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
            if (iosAppId && iosAppName) {
              const urlAppStore = `https://apps.apple.com/${
                country ? country : `jp`
              }/app/${iosAppName}/${iosAppId}`;

              window.location.replace(urlAppStore);
            }
          }
          setTimeout(tryClose, 5000);
        }
      };

      var openApp = function () {
        window.location.replace(`${urlScheme}://${searchParams}`);
      };
      var triggerAppOpen = function () {
        openApp();
        setTimeout(fallbackToStore, 1000);
      };

      triggerAppOpen();
      return null;
    }

    const customDomain = urlSearchParams.get('domain');

    const isState = urlSearchParams.has('state');
    const state = urlSearchParams.get('state');
    if (isState) {
      const codeAuth = urlSearchParams.get('code');

      // web browser on native-app
      if (isMobile()) {
        const launchApp = () => {
          // redirect to app
          window.location.replace(`${state}://?code=${codeAuth}`);
        };
        if (state && codeAuth) launchApp();
        return null;
      }
    }

    if (!params?.appId) {
      if (customDomain) {
        return <ShareCustomDomain domain={customDomain} />;
      }
      if (subDomain && !SubDomainBlockList.includes(subDomain)) {
        return <ShareCustomDomain domain={subDomain} />;
      }
      return (
        <View>
          <Text>Router not found</Text>
        </View>
      );
    }
    return <ShareScreenView appId={params.appId} />;
  } else {
    return (
      <View>
        <Text>Cannot support for OS version</Text>
      </View>
    );
  }
};

export interface ChatSettings {
  enabled: boolean;
  emoticon: string;
  imageSource: string;
  iconType: string;
  enabledTextDisplay: boolean;
  textDisplay: string;
}

export default ShareScreenModal;
