import { StyleSheet } from 'react-native';

export const ICON_SIZE = 14;

const createStyles = (attributes: any) => {
  const {
    width,
    opacity,
    borderColor,
    borderStyle,
    borderWidth,
    borderRadius,
  } = attributes;

  return StyleSheet.create({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      opacity,
      borderColor,
      ...(borderStyle !== 'none' ? { borderStyle: borderStyle } : undefined),
      borderWidth,
      borderRadius,
      width,
    },
    dots: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      alignContent: 'center',
      height: 20,
      maxWidth: width,
    },
    insideDots: {
      position: 'absolute',
      bottom: 0,
    },
    dot: {
      width: 10,
      height: 10,
      backgroundColor: '#595959',
      marginHorizontal: 4,
      borderRadius: 20,
    },
    arrow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginHorizontal: 8,
      top: 0,
      borderRadius: 50,
      backgroundColor: '#bbb',
      width: 24,
      height: 24,
    },
    arrowWrapper: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
    },
    container: {
      alignItems: 'center',
      flex: 1,
      backgroundColor: '#000000',
    },
    keyImage: {
      justifyContent: 'center',
      flex: 1,
      paddingRight: 8,
    },
  });
};

export default createStyles;
