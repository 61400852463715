import React, { useState, useRef, useEffect } from 'react';
import { View, Text, Animated, Dimensions } from 'react-native';

const { width } = Dimensions.get('window');

let toastRef: { show: any } | null = null;

const Toast = () => {
  const [toast, setToast] = useState({
    visible: false,
    message: '',
    position: { x: 0, y: 0, width: 0 },
  });
  const opacity = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    toastRef = {
      show: (message: any, position: any) => {
        setToast({ visible: true, message, position });

        Animated.timing(opacity, {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
        }).start();

        setTimeout(() => {
          Animated.timing(opacity, {
            toValue: 0,
            duration: 200,
            useNativeDriver: true,
          }).start(() =>
            setToast({
              visible: false,
              message: '',
              position: { x: 0, y: 0, width: 0 },
            })
          );
        }, 2000);
      },
    };
  }, []);

  if (!toast.visible) return null;

  return (
    <Animated.View
      style={{
        position: 'absolute',
        left: Math.max(toast.position.x - 100, 10),
        top: Math.max(toast.position.y - 90, 10),
        width: 86,
        height: 22,
        borderRadius: 100,
        borderWidth: 1,
        paddingTop: 2,
        paddingRight: 8,
        paddingBottom: 2,
        paddingLeft: 8,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        opacity,
        borderColor: '#E9EFF2',
        shadowColor: '#172F79',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 30,
        elevation: 5,
      }}
    >
      <Text style={{ color: '#1A2737', fontSize: 10 }}>{toast.message}</Text>
    </Animated.View>
  );
};

Toast.show = (
  message: any,
  event: { nativeEvent: { pageX: any; pageY: any; locationX: any } }
) => {
  if (toastRef) {
    const { pageX, pageY, locationX } = event.nativeEvent;
    toastRef.show(message, {
      x: pageX,
      y: pageY,
    });
  }
};

export default Toast;
