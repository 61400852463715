import { View, Text, ScrollView, TouchableOpacity } from 'react-native';
import { populateEvents } from './Packer';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { map, min } from 'lodash';

const LEFT_MARGIN = 60 - 1;
const TEXT_LINE_HEIGHT = 17;

function range(from: number, to: number) {
  return Array.from(Array(to), (_, i) => from + i);
}

const DayView: React.FC<any> = (props) => {
  const {
    format24h,
    scrollToFirst,
    start,
    end,
    eventTapped,
    styles,
    events,
    renderEvent,
    width: _width,
  } = props;
  const scrollRef: any = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [packedEvents, setPackedEvents] = useState([]);

  const calendarHeight = (end - start) * 100;
  const width = _width - LEFT_MARGIN;

  useEffect(() => {
    const packedEvent = populateEvents(events, width, start);
    const result: any = map(packedEvent, 'top');
    if (Array.isArray(result)) {
      setPackedEvents(packedEvent);
      let initPosition = min(result) - calendarHeight / (end - start);
      initPosition = initPosition < 0 ? 0 : initPosition;
      setScrollY(initPosition);
    }
  }, [events, width, start]);

  useEffect(() => {
    if (scrollToFirst) {
      setTimeout(() => {
        try {
          if (scrollY && scrollRef) {
            scrollRef?.current?.scrollTo({
              x: 0,
              y: scrollY,
              animated: true,
            });
          }
        } catch (e) {}
      }, 1000);
    }
  }, [scrollToFirst]);

  const renderRedLine = () => {
    const offset = 100;
    const timeNowHour = moment().hour();
    const timeNowMin = moment().minutes();
    return (
      <View
        key={`timeNow`}
        style={[
          styles.lineNow,
          {
            top: offset * (timeNowHour - start) + (offset * timeNowMin) / 60,
            width: _width - 20,
          },
        ]}
      />
    );
  };

  const renderLines = () => {
    const offset = calendarHeight / (end - start);

    return range(start, end + 1).map((i, index) => {
      let timeText;
      if (i === start) {
        timeText = ``;
      } else if (i < 12) {
        timeText = !format24h ? `${i} AM` : i;
      } else if (i === 12) {
        timeText = !format24h ? `${i} PM` : i;
      } else if (i === 24) {
        timeText = !format24h ? `12 AM` : 0;
      } else {
        timeText = !format24h ? `${i - 12} PM` : i;
      }
      const { width, styles } = props;
      return [
        <Text
          key={`timeLabel${i}`}
          style={[styles.timeLabel, { top: offset * index - 6 }]}
        >
          {timeText}
        </Text>,
        i === start ? null : (
          <View
            key={`line${i}`}
            style={[styles.line, { top: offset * index, width: width - 20 }]}
          />
        ),
        <View
          key={`lineHalf${i}`}
          style={[
            styles.line,
            { top: offset * (index + 0.5), width: width - 20 },
          ]}
        />,
      ];
    });
  };

  const renderTimeLabels = () => {
    const offset = calendarHeight / (end - start);
    return range(start, end).map((item, i) => {
      return (
        <View key={`line${i}`} style={[styles.line, { top: offset * i }]} />
      );
    });
  };

  const onEventTapped = (index: number) => {
    eventTapped(events[index]);
  };

  const renderEvents = () => {
    const events = packedEvents.map((event: any) => {
      const style = {
        left: event.left,
        height: event.height,
        width: event.width,
        top: event.top,
      };

      const eventColor = {
        backgroundColor: event.color,
      };

      // Fixing the number of lines for the event title makes this calculation easier.
      // However it would make sense to overflow the title to a new line if needed
      const numberOfLines = Math.floor(event.height / TEXT_LINE_HEIGHT);
      const formatTime = format24h ? 'HH:mm' : 'hh:mm A';
      return (
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={() => onEventTapped(event.index)}
          key={`${event.id}${event.top}`}
          style={[styles.event, style, event.color && eventColor]}
        >
          {renderEvent ? (
            renderEvent(event)
          ) : (
            <View>
              <Text numberOfLines={numberOfLines - 1} style={styles.eventTitle}>
                {event.title || 'Event'}
              </Text>
              {numberOfLines > 1 && event.summary ? (
                <Text
                  numberOfLines={numberOfLines - 1}
                  style={[styles.eventSummary]}
                >
                  {event.summary || ' '}
                </Text>
              ) : null}
              {numberOfLines > 2 ? (
                <Text
                  style={styles.eventTimes}
                  numberOfLines={numberOfLines - 1}
                >
                  {moment(event.start).format(formatTime)} -{' '}
                  {moment(event.end).format(formatTime)}
                </Text>
              ) : null}
            </View>
          )}
        </TouchableOpacity>
      );
    });

    return (
      <View>
        <View style={{ marginLeft: LEFT_MARGIN }}>{events}</View>
      </View>
    );
  };

  return (
    <ScrollView
      ref={scrollRef}
      contentContainerStyle={[styles.contentStyle, { width: _width }]}
      showsHorizontalScrollIndicator={false}
    >
      {renderLines()}
      {renderEvents()}
    </ScrollView>
  );
};

export default DayView;
