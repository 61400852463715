import {
  ConstrainPositionName,
  ConstrainPositionType,
  ElementType,
  IPage,
} from '@common/types/element';
import React from 'react';
import { KeyboardAvoidingView, View } from 'react-native';
import ObjectRender from '../ObjectRender';
import { cloneDeep, get } from 'lodash';
import { dimensionSelector } from '@common/redux/selectors/page';
import { useSelector } from 'react-redux';

type Props = { items: ElementType[]; screen: IPage };
function ConstrainObject({ items, screen }: Props) {
  const dimension = useSelector(dimensionSelector);
  const getElementById = (id: string) => {
    return screen.metadata.find((item: ElementType) => item.id === id);
  };

  // TODO: move this function to share-folder with canvas
  const getNewPositionByElement = (
    element: Pick<
      ElementType,
      'x' | 'y' | 'width' | 'height' | 'constrainPosition'
    >,
    dependency: Pick<ElementType, 'x' | 'y' | 'width' | 'height'>,
    constrainKey: ConstrainPositionName
  ): Partial<Pick<ElementType, 'x' | 'y' | 'width' | 'height'>> | null => {
    const { constrainPosition } = element;
    if (!constrainPosition) return null;
    const constrainValue = constrainPosition[constrainKey]?.constrainValue || 0;
    switch (constrainKey) {
      case ConstrainPositionName.top: {
        const oldValuesYEnd = element.y + element.height;
        const newValuesYStart =
          dependency.y + dependency.height + constrainValue;
        const newHeight = oldValuesYEnd - newValuesYStart;
        return {
          y: newValuesYStart,
          height: newHeight > 0 ? newHeight : undefined,
        };
      }
      case ConstrainPositionName.bottom: {
        const newHeight = dependency.y - element.y - constrainValue;
        return {
          height: newHeight > 0 ? newHeight : undefined,
        };
      }
      case ConstrainPositionName.left: {
        const newValuesXStart =
          dependency.x + dependency.width + constrainValue;
        const oldValuesXEnd = element.x + element.width;
        const newWidth = oldValuesXEnd - newValuesXStart;
        return {
          x: newValuesXStart,
          width: newWidth > 0 ? newWidth : undefined,
        };
      }
      case ConstrainPositionName.right:
        const newWidth = dependency.x - element.x - constrainValue;
        return {
          width: newWidth > 0 ? newWidth : undefined,
        };
      default:
        return null;
    }
  };

  const getScreenConstrainPosition = (
    constrainKey: ConstrainPositionName
  ): Pick<ElementType, 'x' | 'y' | 'width' | 'height'> | null => {
    switch (constrainKey) {
      case ConstrainPositionName.top:
        return {
          height: 0,
          width: dimension.width,
          x: 0,
          y: 0,
        };
      case ConstrainPositionName.bottom:
        return {
          height: 0,
          width: dimension.width,
          x: 0,
          y: dimension.height,
        };
      case ConstrainPositionName.left:
        return {
          height: dimension.height,
          width: 0,
          x: 0,
          y: 0,
        };
      case ConstrainPositionName.right:
        return {
          height: dimension.height,
          width: 0,
          x: dimension.width,
          y: 0,
        };
      default:
        return null;
    }
  };

  const getNewPosition = (
    element: ElementType,
    dependencyElementId: ConstrainPositionType['elementId'],
    constrainKey: ConstrainPositionName
  ) => {
    switch (dependencyElementId) {
      case 'none':
        // nothing
        return null;
      case 'screen': {
        const screenConstrain = getScreenConstrainPosition(constrainKey);
        if (screenConstrain) {
          return getNewPositionByElement(
            element,
            screenConstrain,
            constrainKey
          );
        }
        return null;
      }
      default: {
        const dependencyElement = getElementById(dependencyElementId);
        if (dependencyElement) {
          return getNewPositionByElement(
            element,
            dependencyElement,
            constrainKey
          );
        }
      }
    }
    return null;
  };

  const getItemUpdatedLayout = () => {
    const res: ElementType[] = [];
    items.forEach((item) => {
      const { constrainPosition } = item;
      if (!constrainPosition) {
        res.push(item);
        return;
      }
      const updatedItem = {
        ...cloneDeep(item),
        isFixed: true,
      };
      for (const key in constrainPosition) {
        const constrainOption = get(
          constrainPosition,
          key
        ) as ConstrainPositionType;
        if (constrainOption.elementId) {
          const newPosition = getNewPosition(
            item,
            constrainOption.elementId,
            key as ConstrainPositionName
          );
          if (newPosition) {
            if (newPosition?.x || newPosition?.x === 0) {
              updatedItem['x'] = newPosition?.x;
            }
            if (newPosition?.y || newPosition?.y === 0) {
              updatedItem['y'] = newPosition?.y;
            }
            if (newPosition?.width || newPosition?.width === 0) {
              updatedItem['width'] = newPosition?.width;
            }
            if (newPosition?.height || newPosition?.height === 0) {
              updatedItem['height'] = newPosition?.height;
            }
          }
        }
      }
      res.push(updatedItem);
    });
    return res;
  };

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: '100%',
      }}
      pointerEvents="box-none"
      nativeID="constrain-objects"
    >
      <KeyboardAvoidingView
        behavior="height"
        style={{ flex: 1 }}
        pointerEvents="box-none"
        enabled={true}
        keyboardVerticalOffset={0}
      >
        {items.length > 0 && (
          <ObjectRender
            arrComp={getItemUpdatedLayout()}
            isScreen={true}
            screen={screen}
          />
        )}
      </KeyboardAvoidingView>
    </View>
  );
}

export default ConstrainObject;
