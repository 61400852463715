import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import ShareScreen from '@common/screens/ShareScreen';
import RenderScreen from '@common/screens/RenderScreen';
import history from './history';
import ShareLink from '@common/screens/ShareLink';
import ShareScreenModal from '@common/screens/ShareScreenModal';
import ShareScreenUrl from '@common/screens/ShareScreenUrl';

export default function AppRoutes() {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/share/:shortUrl" component={ShareLink} />
        <Route exact path="/" component={ShareScreen} />
        <Route path="/preview/:appId" component={RenderScreen} />
        <Route path="/share-modal/:appId" component={ShareScreenModal} />
        <Route path="/share-app/:appId" component={ShareScreenUrl} />
        <Route path="/:appId" component={ShareScreen} />
      </Switch>
    </Router>
  );
}
