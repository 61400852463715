import { Platform } from 'react-native';
import queryString from 'query-string';
import { getListItems } from '../func';
import { useMemo } from 'react';
import { Image } from 'react-native';

export const getInitValues = (dataBinding: any, items: any) => {
  const search = queryString.parse(window?.location?.search);
  const target = search?.target;
  let len = 5;

  if (Platform.OS !== 'web') {
    return dataBinding;
  } else {
    if (target) {
      return dataBinding;
    } else {
      return getListItems(items, len);
    }
  }
};

export const groupByPrefix = (data: any, prefix: string) => {
  return data.map((item: any) => {
    let result = { ...item };
    let grouped: any = {};
    Object.keys(item).forEach((key) => {
      if (key.startsWith(prefix)) {
        const group = prefix;
        if (!grouped[group]) {
          grouped[group] = [];
        }
        grouped[group].push({ key, value: item[key] });
        delete result[key];
      }
    });
    result[prefix] = grouped[prefix] || [];

    return result;
  });
};

export const addURLImages = (data: any, props: any) => {
  const { attributes } = props;
  const { images } = attributes;
  const newDataSetWithImagesUrl = data.map((item: any) => {
    const existingKeys = new Set(item.images.map((img: any) => img.key));
    Object.entries(images).forEach(([key, value]: any) => {
      if (
        !existingKeys.has(`images.${key}`) &&
        value.imageUrl &&
        value.imageUrl != ''
      ) {
        item.images.push({
          key: `images.${key}`,
          value: {
            url: Array.isArray(value.imageUrl)
              ? value.imageUrl[0]
              : value.imageUrl || '',
            type: value.imageType || 'unknown',
          },
          imageResize: value.imageResize || 'cover',
        });
      }
    });
    if (item && item.images?.length) {
      item.images = item.images.sort((a: any, b: any) => {
        const keyA = parseInt(a.key.match(/\d+/)?.[0] || '0', 10);
        const keyB = parseInt(b.key.match(/\d+/)?.[0] || '0', 10);
        return keyA - keyB;
      });
      item.images = item.images.map((data: any, index: any) => {
        if (!(typeof data.value === 'object')) {
          data.value = {
            url: data.value,
          };
        }
        const { imageResize } = images[`image${index + 1}`];
        data['imageResize'] = data['imageResize'] ?? imageResize;
        return data;
      });
    }
    return item;
  });
  return newDataSetWithImagesUrl;
};

export const getImageSize = (imageUrl: any, defaultSize?: any) => {
  return new Promise((resolve, reject) => {
    Image.getSize(
      imageUrl,
      (width, height) => {
        resolve({ width, height });
      },
      (error) => {
        const { width, height } = defaultSize;
        reject({ width: width ?? 0, height: height ?? 0 });
      }
    );
  });
};


export const isVideoUrl = (url: string) => {
  try {
    const urlObj = new URL(url);
    const pathname = urlObj.pathname.toLowerCase();
    const videoExtensions = [".mp4", ".webm", ".mov"];
    return videoExtensions.some((ext) => pathname.endsWith(ext));
  } catch (error) {
    return false;
  }
};

export const getVideoType = (url: any) => {
  if (/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)/.test(url)) {
    return "youtube";
  } else if (/^(https?:\/\/)?(www\.)?vimeo\.com/.test(url)) {
    return "vimeo";
  } else {
    if (url) {
      return isVideoUrl(url) ? 'other' : false;
    }
    return null;
  }
};

export const convertShortsToEmbed = (url: any) => {
  try {
    let urlObj = new URL(url);
    if (urlObj.pathname.includes("/shorts/")) {
      const videoId = urlObj.pathname.split("/shorts/")[1].split("?")[0];
      urlObj.pathname = `/embed/${videoId}`;
      urlObj.searchParams.delete("si");
      return urlObj.toString() + `?playlist=${videoId}&autoplay=1&mute=1&controls=0&loop=1&showInfo=0`;
    }
    return url + `?autoplay=1&mute=1&controls=0&loop=1&showInfo=0`;
  } catch (error) {
    return url + `?autoplay=1&mute=1&controls=0&loop=1&showInfo=0`;
  }
}

export const buttonMapper = (props?: any) => {
  const defaultProps = {
    name: 'Button 1',
    x: 125,
    y: 482,
    width: 155,
    height: 44,
    positioning: null,
    hidden: false,
    attributes: {
      text: 'Button',
      type: 'contained',
      icon: 'plus',
      iconEnableOutline: false,
      backgroundColor: '#34A0DD',
      contrastColor: '@contrast:primaryColor',
      borderRadius: 100,
      upperCase: true,
      opacity: 1,
      fontFamily: 'Noto Sans JP, sans-serif',
      fontSize: 16,
      color: '#F9F9F9',
      borderColor: '#000',
      borderWidth: 0,
      borderStyle: 'none',
      styles: {
        text: {
          fontFamily: '-apple-system, "SF Pro Text", sans-serif',
          fontWeight: '600',
        },
      },
    },
    libraryName: '@nocode/components',
    componentName: 'Button',
    minWidth: 100,
  };
  return defaultProps;
};

export const useButtonComponentMapper = (
  componentData: any,
  isCanvas: boolean,
  item?: any,
  actions?: any,
  onPress?: any,
) => {
  if (isCanvas) {
    return useMemo(() => {
      if (!componentData) return null;
      const {
        attributes: {
          items: [{ button }],
        },
      } = componentData;
      const { attributes } = componentData;
      const { buttonColor, buttonIconAndTextColor } = attributes;
      const baseProps = {
        name: 'Button',
        position: null,
        dimensions: null,
        hidden: !button?.enabled ?? false,
        libraryName: '@nocode/components',
        componentName: 'Button',
        width: 155,
        height: 44,
        attributes: {
          text: button?.text ?? 'Button',
          type: button?.type ?? 'contained',
          icon: button?.icon ?? 'plus',
          iconEnableOutline: false,
          backgroundColor: buttonColor ?? '#34A0DD',
          contrastColor: buttonIconAndTextColor ?? '#FFFFF',
          borderRadius: 100,
          upperCase: true,
          opacity: 1,
          fontFamily: 'Noto Sans JP, sans-serif',
          fontSize: 16,
          color: buttonIconAndTextColor ?? '#FFFFFF',
          borderColor: '#000',
          borderWidth: 0,
          borderStyle: 'none',
          styles: {
            text: {
              fontFamily: '-apple-system, "SF Pro Text", sans-serif',
              fontWeight: '600',
            },
          },
        },
      };

      return baseProps;
    }, [componentData]);
  } else {
    let btnMapper: any = buttonMapper();
    const { attributes: defaultAttributes } = componentData;
    if (item) {
      const textFinal = defaultAttributes.button?.text && typeof defaultAttributes.button?.text === 'string' ? defaultAttributes.button?.text : defaultAttributes.button?.text[0];
      btnMapper.attributes.text = textFinal;
      btnMapper.attributes.icon =
        item['button.icon'] ?? defaultAttributes.button?.icon;
      btnMapper.attributes.backgroundColor = defaultAttributes.buttonColor;
      btnMapper.attributes.color = defaultAttributes.buttonIconAndTextColor;
      if (actions) {
        const keyName = Object.keys(actions)[0];
        btnMapper.attributes['action'] = {
          type: 'actionRef',
          actionId: keyName,
        };
      }
      btnMapper['onPress'] = onPress;
    }
    if (actions) {
      btnMapper['actions'] = actions;
    }
    return btnMapper;
  }
};
