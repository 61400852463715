import Loading from '@common/components/Loading';
import { authenticationRouting } from '@common/hooks/authenticationRoute';
import { dimensionSelector } from '@common/redux/selectors/page';
import { IAppInfo } from '@common/types/element';
import React, { Fragment } from 'react';
import { Platform, View } from 'react-native';
import { useSelector } from 'react-redux';
import ForgotPasswordWrap from './ForgotPassword';
import HelmetCustom from './Helmet';
import ModalScreen from './ModalScreen';
import ResetPasswordWrap from './ResetPassword';
import BaseScreen from './BaseScreen';
import { IapWrapper } from '@common/utils/IapWrapper';

let SplashScreen: any;

(async () => {
  if (Platform.OS !== 'web') {
    SplashScreen = await (await import('react-native-splash-screen')).default;
  }
})();

type Props = {
  appInfo: IAppInfo;
};

function RenderScreen({ appInfo }: Props) {
  const dimension = useSelector(dimensionSelector);

  const { screen, animation, modalScreen, modalTransition, loading }: any =
    authenticationRouting({ appInfo });

  if (Platform.OS !== 'web') {
    SplashScreen.hide();
  }

  if (loading) {
    return <Loading />;
  }
  if (!screen) {
    return (
      <Fragment>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: dimension.width,
            height: dimension.height,
            backgroundColor: 'white',
          }}
        >
          {/* <Text>Not found screen on App</Text> */}
        </View>
      </Fragment>
    );
  }

  const render = (
    <Fragment>
      <HelmetCustom screen={screen} />

      <BaseScreen screen={screen} animation={animation} />

      <ModalScreen
        modalScreen={modalScreen}
        modalTransition={modalTransition}
      />

      <ResetPasswordWrap />

      <ForgotPasswordWrap />
    </Fragment>
  );
  return <IapWrapper screen={screen}>{render}</IapWrapper>;
}

export default RenderScreen;
