import Toast from '../Toast';

export const copyToClipboard = (value: string, event: any) => {
  if (value) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator?.clipboard
        ?.writeText(value)
        .then(() => {
          Toast.show('コピーする', event);
        })
        .catch((err) => {
          fallBackCopy(value, event);
        });
    } else {
      fallBackCopy(value, event);
    }
  }
};

export const fallBackCopy = (value: any, event: any) => {
  const linkCopy = document.createElement('textarea');
  linkCopy.value = value;
  document.body.appendChild(linkCopy);
  linkCopy.focus();
  linkCopy.select();
  document.execCommand('copy');
  linkCopy.remove();
  Toast.show('コピーする', event);
};
