import { pick } from 'lodash';
import { StyleSheet, Dimensions } from 'react-native';
import { checkFont } from '../func';
const windowWidth = Dimensions.get('window').width;
const createStyles = (attributes: any) => {
  return StyleSheet.create({
    container: {
      ...pick(attributes, [
        'width',
        'height',
        'zIndex',
        'marginTop',
        'borderRadius',
      ]),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: attributes?.attributes?.backgroundColor || '#0175FF',
      overflow: 'hidden',
    },
    text: {
      color: attributes?.attributes?.color || '#FFFFFF',
      fontFamily: checkFont(attributes?.attributes?.fontFamily),
      fontSize: attributes?.attributes?.fontSize,
      fontWeight: '700',
      marginLeft: 8,
    },
    scannerContainer: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    camera: {
      ...pick(attributes, ['width', 'height']),
    },
    containerScan: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      flex: 1,
      top: 0,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    scan: {
      width: 250,
      height: 130,
      borderLeftWidth: 5,
      borderRightWidth: 5,
      borderTopWidth: 5,
      borderBottomWidth: 5,
      borderTopColor: '#4259AC',
      borderLeftColor: '#4259AC',
      borderRightColor: '#4259AC',
      borderBottomColor: '#4259AC',
    },
    back: {
      width: 29,
      height: 29,
      backgroundColor: 'white',
      position: 'absolute',
      top: windowWidth / 10,
      right: 23,
      zIndex: 99,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 23,
    },
  });
};

export default createStyles;
